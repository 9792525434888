import { Routes } from '@angular/router';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { AlertsComponent } from './pages/alerts/alerts.component';
import { CampaignsComponent } from './pages/campaigns/campaigns.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { StoresComponent } from './pages/stores/stores.component';
import { StoreDetailsComponent } from './pages/stores/store-details/store-details.component';
import { authGuard } from './guards/auth.guard';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { AuthComponent } from './pages/auth/auth.component';
import { NavigationRoutes } from './models/navigation.model';
import { WindowDetailsComponent } from './pages/stores/window-details/window-details.component';
import { CampaignsManagerComponent } from './pages/campaigns/campaigns-manager/campaigns-manager.component';
import { AlertsDetailComponent } from './pages/alerts/alerts-detail/alerts-detail.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { CampaingCartComponent } from './pages/campaigns/campaing-cart/campaing-cart.component';
import { CartComponent } from './pages/campaigns/cart/cart.component';
import { PasswordRecoverComponent } from './pages/password-recover/password-recover.component';

export const routes: Routes = [
  {
    path: '',
    redirectTo: NavigationRoutes.HomePage,
    pathMatch: 'full'
  },
  {
    path: NavigationRoutes.Login,
    component: AuthComponent,
  },
  {
    path: NavigationRoutes.RecoverPassword,
    component: PasswordRecoverComponent,
  },
  {
    path: '',
    component: PageLayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: NavigationRoutes.HomePage,
        component: DashboardComponent,
      },
      {
        path: NavigationRoutes.Stores,
        component: StoresComponent,
      },
      {
        path: NavigationRoutes.Window,
        component: WindowDetailsComponent,
      },
      {
        path: NavigationRoutes.Store,
        component: StoreDetailsComponent
      },
      {
        path: NavigationRoutes.Campaigns,
        component: CampaignsComponent
      },
      {
        path: NavigationRoutes.CampaignsWindows,
        component: CampaignsManagerComponent
      },
      {
        path: NavigationRoutes.CampaignsCart,
        component: CampaingCartComponent
      },
      {
        path: NavigationRoutes.Resume,
        component: CartComponent
      },
      {
        path: NavigationRoutes.Scheduler,
        component: CalendarComponent
      },
      {
        path: NavigationRoutes.Alert,
        component: AlertsComponent
      },
      {
        path: NavigationRoutes.SingleAlert,
        component: AlertsDetailComponent
      },
      {
        path: NavigationRoutes.Profile,
        component: ProfileComponent
      },
    ]
  },
];
