

<div class="h-100 w-100 bg">
  <div class="row pt-5 justify-content-center auth">

    <h1 class="my-5 text-center">Asset Manager</h1>
    <div class="col-12 col-md-auto lf-card p-5 w-50">


      <div class="d-flex flex-column">
        <form [formGroup]="recoverForm" (ngSubmit)="submit()">
          <h2 class="text-center">Modifica Password</h2>
          <div class="d-flex flex-column">

            <!-- <label for="first-name">First Name: </label>
            <input id="first-name" class="form-control" type="text" formControlName="user"> -->

          </div>
          <div class="d-flex flex-column mt-4 position-relative">
            <mat-form-field class="">
              <!-- <mat-label>Password </mat-label> -->
              <input name="password" [type]="inputType" placeholder="Nuova Password" formControlName="password" title="input" matInput >
              <!-- @if (recoverForm.get('password')?.hasError('required')) {
              <mat-error>{{'STORES.required_field' | translate}}</mat-error>
              } -->
            </mat-form-field>
            <button title="password" clear class="p-0 password-reveal btn" (click)="changeVisibility()" mat-button type="button">
              <mat-icon class="m-0" [fontIcon]="inputType == 'text' ? 'visibility_off': 'visibility'"></mat-icon>
            </button>
            <!-- <label for="last-name">Last Name: </label>
            <input id="last-name" class="form-control" type="password" formControlName="password"> -->

          </div>
          <div class="d-flex flex-column mt-4 position-relative">
            <mat-form-field class="">
              <!-- <mat-label>Password </mat-label> -->
              <input name="password_confirmation" [type]="inputType" placeholder="Ripeti password" formControlName="password_confirmation" title="input" matInput >
              @if (recoverForm.get('password_confirmation')?.hasError('required') || recoverForm.get('password_confirmation')?.hasError('invalid')  ) {
              <mat-error>{{'STORES.required_field' | translate}}</mat-error>
              }
            </mat-form-field>

            <!-- <label for="last-name">Last Name: </label>
            <input id="last-name" class="form-control" type="password" formControlName="password"> -->

          </div>


          <div class="col-12 text-center">
            <button type="submit" class="mt-4 w-100 btn btn-new" [disabled]="!recoverForm.valid || recoverForm.get('password_confirmation')?.value !== recoverForm.get('password')?.value">Conferma</button>
          </div>
        </form>


    </div>
  </div>

</div>
