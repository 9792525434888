export enum NavigationRoutes {
  HomePage ='dashboard',
  RecoverPassword ='password-recover',
  Stores ='pv',
  NewStore ='pv/nuovo',
  Window ='pv/:id/:uuid',
  Store ='pv/:id',
  Alert = 'avvisi',
  Profile = 'profilo',
  SingleAlert = 'avvisi/:id',
  Campaigns = 'campagne',
  CampaignsWindows = 'campagne/:id',
  CampaignsCart = 'campagne/:id/riepilogo',
  Resume = 'riepilogo',
  Scheduler = 'calendario',
  Login = 'auth'
}
export enum NavigationPagesName {
  HomePage ='Home',
  RecoverPassword ='Recupero Password',
  Profile = 'Profilo',
  NewStore ='Nuovo',
  Window ='Vetrine',
  Stores ='PV',
  Alert = 'Avvisi',
  CampaignsWindows = 'Vetrine',
  Campaigns = 'Campagne',
  Scheduler = 'Calendario',
  Login = 'Login'
}
export enum NavigationBreadcrumb {
  dashboard = '',
  pv ='Punti Vendita',
  RecoverPassword ='Recupero Password',
  NewStore ='Nuovo',
  Profile = 'Profilo',
  avvisi = 'Avvisi',
  window ='Vetrine',
  campaignsWindows = 'Vetrine',
  campagne = 'Campagne',
  calendario = 'Calendario',
  Login = 'Login'
}
