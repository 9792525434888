import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-insert-data-table',
  standalone: true,
  imports: [MaterialModule, SharedModule],
  templateUrl: './insert-data-table.component.html',
  styleUrl: './insert-data-table.component.scss'
})
export class InsertDataTableComponent implements OnInit {

  constructor(private apiSvc: ApiService) {

  }
  @Input() savedData: any = {}
  @Input() onMassiveChange: boolean = false
  @Input() availableDisplayWindows: any[] = []
  @Input() isBrand: boolean = true;
  @Input() bound: string = "bound"
  @Input() ids: number = 0;
  @Output() values = new EventEmitter<any>()
  @Output() dataFilter = new EventEmitter<any>()
  data: any[] = [];
  companyControl = new FormControl();
  brandControl = new FormControl();
  productControl = new FormControl();
  displayWindowControl = new FormControl();
  internalControl = new FormControl("in");

  companies: any[] = [];
  brands: any[] = [];
  products: any[] = [];
  displayWindows: any[] = [];

  filteredCompanies!: Observable<any[]>;
  filteredBrands!: Observable<any[]>;
  filteredProducts!: Observable<any[]>;
  filteredDisplayWindows!: Observable<any[]>;
  index = 0;
  companySelected: boolean = false;
  brandSelected: boolean = false;
  productSelected: boolean = false;

  formValid = false;

  ngOnInit(): void {
    // console.log(this.savedData);


    this.fetchData();
    this.displayWindows = this.availableDisplayWindows;

    this.internalControl.disable()
    //console.log(this.ids);

    this.filteredCompanies = this.companyControl.valueChanges.pipe(
      startWith(''),
      tap((i) => {
        if (i && i.name) {
          this.brandControl.enable()
        } else {
          // console.log(i);

          this.companyControl.setErrors({ 'invalid': true });
          this.brandControl.disable()
        }
        // this.autoFill()
      }),
      map(value => this._filterCompanies(value)),
      tap(()=> {
        if (!this.isBrand) {
          this.autoFill()

        }
      })

    )

    this.filteredBrands = this.brandControl.valueChanges.pipe(
      startWith(''),
      tap((i) => {
        if (i && i.name) {
          // console.log(i);

          if (this.isBrand) {
            this.internalControl.enable();
            this.formValid = true;
          }
          this.productControl.enable()
        } else {
          this.brandControl.setErrors({ 'invalid': true });
          if (!this.savedData?.full) {
            this.productControl.disable();
          }

        }
      }),
      map(value => this._filterBrands(value)),
      tap(()=> {
        if (!this.isBrand) {
          this.autoFill()

        }
      })
    );

    this.filteredProducts = this.productControl.valueChanges.pipe(
      startWith(''),
      tap((i) => {
        if (i && i.name) {
          this.displayWindowControl.enable()

        } else {
          if (this.isBrand) {
            this.productControl.setErrors({ 'invalid': true });

          }
          this.displayWindowControl.disable();

        }

        // this.autoFill()
      }),
      map(value => this._filterProducts(value)),

    );
    // if (this.ids.length > 1) {
      // this.filteredDisplayWindows = this.displayWindowControl.valueChanges.pipe(
      //   startWith(''),
      //   map(value => this._filterDisplayWindows(value))
      // );

      // this.filteredDisplayWindows.subscribe(() => {
      // });
    // }


    this.filteredProducts.subscribe(() => {
      this.displayWindowControl.reset();
      const data = {
        name: "product_id",
        value: null
      }
      this.dataFilter.emit(data);
    });

    this.filteredBrands.subscribe(() => {
      this.productControl.reset();
      this.displayWindowControl.reset();
      const data = {
        name: "product_id",
        value: null
      }
      this.dataFilter.emit(data);
      data.name = "brand_id",
      this.dataFilter.emit(data);
    });

    this.filteredCompanies.subscribe(() => {
      this.brandControl.reset();
      this.productControl.reset();
      this.displayWindowControl.reset();
      const data = {
        name: "product_id",
        value: null
      }
      this.dataFilter.emit(data);
      data.name = "brand_id",
      this.dataFilter.emit(data);

    });

  }

  autoFill() {
    if (this.savedData?.full && this.index == 0 ) {
      this.companyControl.setValue({name:this.savedData.company, company_id: this.savedData.company_id})
      this.brandControl.enable();
      this.productControl.enable();
      this.brands = this.getBrandsByCompany({name:this.savedData.company, company_id: this.savedData.company_id});
      const b = this.brands.filter(el => el.brand_id === this.savedData.brand_id );
      this.products = this.getProductsByBrand({brand_id:this.savedData.brand_id});
      const p = this.products.filter(el => el.brand_id === this.savedData.brand_id );
      this.brandControl.setValue(b[0])
      if (this.savedData?.keepProduct) {

        this.productControl.setValue(p[0]);
      }

      const data = {
        name: "company",
        value: this.companyControl.value
      }
      this.dataFilter.emit(data);
      data.name =  "brand_id",
      data.value =  b[0]
      this.dataFilter.emit(data);
      if (this.savedData?.keepProduct) {
        data.name =  "product_id",
        data.value =  p[0]
        this.dataFilter.emit(data);
        this.productSelected = true;
      }

      this.brandSelected = true;

    }
  }

  fetchData() {
    const data = JSON.parse(localStorage.getItem('lf-products') as string);

    this.companies = this.getUniqueCompanies(data);

    this.data = data;
  }

  onCompanySelected(company: any) {
    const data = {
      name: "company",
      value: this.companyControl.value
    }
    this.dataFilter.emit(data);

    this.companySelected = true;
    this.brandControl.reset();
    this.productControl.reset();
    this.displayWindowControl.reset();
    this.brands = this.getBrandsByCompany(company);
    this.filteredBrands = this.brandControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filterBrands(value))
    );
  }

  onBrandSelected(brand: any) {
    const data = {
      name: "brand_id",
      value: this.brandControl.value
    }
    this.dataFilter.emit(data);
    // console.log(this.brandControl);


    this.brandSelected = true;
    this.productControl.reset();
    this.displayWindowControl.reset();
    this.products = this.getProductsByBrand(brand);
    // console.log(this.products);
    if (this.products.length > 0) {
      this.productControl.enable()
    }

    this.filteredProducts = this.productControl.valueChanges.pipe(
      tap((i) => {
      }),
      startWith(''),
      map(value => this._filterProducts(value))
    );
  }

  onProductSelected(product: any) {
    const data = {
      name: "product_id",
      value: this.productControl.value
    }
    this.dataFilter.emit(data);

    this.productSelected = true;
    this.displayWindowControl.reset();
    // if (this.displayWindows.length == 0) {
    //   this.displayWindowControl.disable();
    // }
    // if (this.ids.length == 1) {
      // this.internalControl.enable();
      this.internalControl.enable();
      this.formValid = true;
      // this.displayWindowControl.value.display_window_id = this.ids[0]
    // }
    this.filteredDisplayWindows = this.displayWindowControl.valueChanges.pipe(
      tap((i) => {
        if (i && i.name) {
          this.internalControl.enable()
          this.formValid = true
        } else {
          this.displayWindowControl.setErrors({ 'invalid': true });
          this.formValid = false
        }
      }),
      startWith(''),
      map(value => this._filterDisplayWindows(value))
    );
  }

  private _filterCompanies(value:any): any[] {
    value += ""
    const filterValue = value.toLowerCase();
    return this.companies.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterBrands(value: string): any[] {
    value += ""
    const filterValue = value.toLowerCase();
    return this.brands.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterProducts(value: string): any[] {
    value += ""
    const filterValue = value.toLowerCase();
    return this.products.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private _filterDisplayWindows(value: string): any[] {
    value += ""
    const filterValue = value.toLowerCase();
    return this.displayWindows.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  private getUniqueCompanies(data: any): any[] {
    const companies = data.map((item: any) => item.brandonly.company);
    return Array.from(new Set(companies.map((c: any) => c.company_id)))
      .map(id => companies.find((c: any) => c.company_id === id));
  }

  private getBrandsByCompany(company: any): any[] {
    return this.data
      .filter(item => item.brandonly.company_id === company.company_id)
      .map(item => item.brandonly)
      .filter((value, index, self) => self.findIndex(brand => brand.brand_id === value.brand_id) === index);
  }

  private getProductsByBrand(brand: any): any[] {
    return this.data.filter(item => item.brandonly.brand_id === brand.brand_id);
  }
  displayFn(option: any): string  {
    return option ? option.name : "";
  }

  submit() {

    const res: any = {
      brand_id: this.brandControl.value.brand_id+"",
      store_id: this.ids+""
    }
    // console.log(res);
    if (this.onMassiveChange) {
      res.product_id = this.productControl.value.product_id +""
      res.product_new_name = this.productControl.value.name +""
    }

    this.values.emit(res)

  }

  close() {
    this.values.emit('discard')
  }
}
