import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { tap } from 'rxjs';
import { environment as ENV } from '../../environments/environment';
import { LoginRequest, RecoverPasswordReq, RegisterFields } from '../models/api.models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient,
  ) { }

  isLoggedIn: boolean = false;
  token: string = "";

  get getAuth(): boolean {
    return !!localStorage.getItem('token-lf');
  }
  set setAuth(value: boolean) {
    this.isLoggedIn = value;
  }


  get getToken() {
    return this.token
  }
  set setToken(value: string) {
    this.token = value
  }



  login(req: LoginRequest) {
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.login, req).pipe(
      tap((res: any) => {
        //console.log(res);

        this.setAuth = res.status;
        this.setToken = res.token;
        localStorage.setItem('token-lf', res.token);
      })
    )
  }

  logout() {
    return this.http.get(ENV.api.BASE_URL + ENV.api.endpoints.logout, {}).pipe(
      tap((res: any) => {
        //console.log(res);
        this.setAuth = false;
      })
    )

  }

  getProfile() {
    return this.http.get(ENV.api.BASE_URL + ENV.api.endpoints.profile);
  }

  recoverPassword(param: RecoverPasswordReq  ) {
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.reset_psw, param);
  }
  forgotPassword(param: any  ) {
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.forgot_password, param);
  }

  register(param:RegisterFields ) {
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.register, param);
  }

}
