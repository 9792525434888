
<header class="dialog-header d-flex justify-content-between">
  {{(data.name ? 'STORES.edit_details' :'STORES.new_details') | translate}}
  <button class="btn border-0 text-white" mat-dialog-close>
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
  </button>
</header>
<mat-dialog-content class="window-fields">
  <form [formGroup]="formFields" (ngSubmit)="formSubmit()">
    <div class="container-fluid">
      <!-- <div class="row">
        <mat-form-field class="">
          <mat-label>{{'STORES.site' | translate}}:</mat-label>
          <input formControlName="name" title="site" matInput >

          @if (formFields.get('name')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
        </mat-form-field>

      </div> -->
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.name_w' | translate}}:</mat-label>
          <mat-form-field class=" ">
            <input formControlName="name" title="input" matInput >
            @if (formFields.get('name')?.hasError('required')) {
              <mat-error>{{'STORES.required_field' | translate}}</mat-error>
            }
          </mat-form-field>
        </div>
        <!-- <div class="col-4 d-flex align-items-center">
          <mat-form-field class="">
            <mat-label>{{'TABLES.id' | translate}}:</mat-label>
            <input formControlName="store_id" title="input" matInput >
            @if (formFields.get('store_id')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div> -->
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.unique_code' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="unique_code" title="input" matInput >
            @if (formFields.get('unique_code')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>

      </div>
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.type' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="type" title="input" matInput >
            @if (formFields.get('type')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.dimensions' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="dimensions"  title="input" matInput >
            @if (formFields.get('dimensions')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.restrictions' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="restrictions" title="input" matInput >
            @if (formFields.get('restrictions')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.warning' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="warning"  title="input" matInput >
            @if (formFields.get('warning')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>


      </div>

    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">


  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button class="btn link me-4" mat-button mat-dialog-close>{{'ACTIONS.cancel' | translate}}</button>
  <button  [disabled]="!formFields.valid" class="btn-new px-5 m-0" (click)="formSubmit()">{{'ACTIONS.save' | translate}}</button>
</mat-dialog-actions>
