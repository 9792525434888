import { Injectable } from "@angular/core";
import { environment as ENV } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  getCssVar(color: string) {
    const rootStyles = getComputedStyle(document.documentElement);
    return rootStyles.getPropertyValue('--lf-' + color)
  }

  getUrl(evnt:any) {
    let x

    if(evnt.url.includes(ENV.api.BASE_URL)) {
     x = evnt.url.toString().replace(ENV.api.BASE_URL+"/", '')
    }
    if(evnt.url.includes('/assets/i18n')) {
     x = evnt.url.toString().replace('/assets/i18n', 'i18n')
    }
    if(evnt.url.includes('/assets/icons')) {
     x = evnt.url.toString().replace('/assets/icons', 'icons')
    }
    if(evnt.url.includes('/assets/datatest')) {
     x = evnt.url.toString().replace('/assets/datatest', 'datatest')
    }
    return x

  }

  logger(source: any, req: any, color: any, primary: any, secondary: any , v?: any) {
    console.log("%c"+source+" Req: %c" + this.getUrl(req) +" %o",
  'background:' + this.getCssVar(primary) +'; border: 1px solid '+ this.getCssVar(secondary) +'; border-radius:5px 0 0 5px ; padding:2px;color: '+color+';margin: 10px 0;',
  'background:'+ this.getCssVar(secondary) +'; border: 1px solid '+ this.getCssVar(secondary) +'; border-radius:0 5px 5px 0; padding:2px;color:' + this.getCssVar(primary) +';margin: 10px 0;',
    v ? v : req.body);
  }


}
