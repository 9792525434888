import { Component, OnInit } from '@angular/core';
import { PageLayoutComponent } from '../../components/page-layout/page-layout.component';
import { ApiService } from '../../services/api.service';
import { CampaignsService } from '../../services/campaigns/campaigns.service';
import { SharedModule } from '../../modules/shared.module';
import { CalendarEditData } from '../../models/tables.model';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder } from '@angular/forms';
import { debounceTime, map, Observable, startWith, tap } from 'rxjs';
import { MaterialModule } from '../../modules/material.module';
import { StoresService } from '../../services/stores/stores.service';
import { StoreSearch } from '../../models/store.model';
import { MatFormField, MatFormFieldControl, MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'app-calendario',
  standalone: true,
  imports: [PageLayoutComponent, SharedModule, MatFormField,MatInputModule, MatAutocomplete, MatFormFieldModule, MatAutocompleteModule],
  templateUrl: './calendar.component.html',
  styleUrl: './calendar.component.scss'
})
export class CalendarComponent implements OnInit {
  currentMonth: number | string = 0;
  currentYear: number | string = 0;
  nextMonth:  number | string = 0;
  nextYear:  number | string = 0;

  tableData: CalendarEditData[] = [] ;
  isReady = false;
  newClassesCss: any = {};

  lastParams = "";
  // optionSelected = false;
  citySelected = false;
  stores: any[] = [];
  cities: any[] = [];
  filteredOptions!: Observable<any[]>;
  filteredCity!: Observable<any[]>;
  optionSelected = false
  timeGapOptions: any[] = [
    {
      label: "Annuale",
      value: 11
    },
    {
      label: "Semestre",
      value: 5
    },
    {
      label: "Trimestre",
      value: 2
    },
    {
      label: "Mensile",
      value: 0
    },
]
formField = {
  store: "Coni Zugna",
  display_window: "",
  company: "",
  brand: "",
  product: "",
  city: "Milano"



}

  timeGap:number = 5
  isVeryReady: boolean = false;
  formControl: any;
  constructor(private campSvc: CampaignsService, private translate: TranslateService, private formBuilder: FormBuilder, private storeSvc: StoresService) {

  }

  ngOnInit() {
    this.translate.get('CALENDAR').subscribe((res: any) => {
      this.timeGapOptions = [
        {
          label: res.annual,
          value: 11
        },
        {
          label: res.semester,
          value: 5
        },
        {
          label: res.quarter,
          value: 2
        },
        {
          label: res.monthly,
          value: 0
        }
    ]
    })

      this.goToday();

      this.formControl = this.formBuilder.group(this.formField);
      this.isReady = true;
      this.formControl.get('store')?.valueChanges.pipe(
        debounceTime(500)
      )
      .subscribe((value: any) => {
        if (this.optionSelected) {
          const selectedStore = this.stores.filter(({name}) => name == value);
          //console.log(selectedStore);
          if (Array.isArray(selectedStore)) {
            if (selectedStore.length > 0) {
              // console.log(selectedStore);



            }
          }
          // Resetta il flag e ritorna
          this.optionSelected = false;
          return;
        }
        //console.log(value);
        const param: StoreSearch = {
          param: {
            name: value
          }
        }
        this.storeSvc.storeSearch(param).subscribe((res:any) => {
          //console.log(res);
          this.stores = res.data.map((el:any) => {
            return {
              name: el.name,
              type: 'store'
            }
          })
          //console.log(this.stores);
          this.optionSelected = true;
          this.filteredOptions = this.formControl.valueChanges.pipe(
            startWith(''),
            map((value: any) => this._filter(value || '')),
            tap((i: any) => {
              //console.log(i);

            })
          )

        })

        // const filter = {...value} as string;
        // this.filters.emit(filter);
      });



      this.storeSvc.getCities().subscribe((res:any) => {
        //console.log(res);
        this.cities = res.map((el: any) => {
          return {
            type : 'city',
            name : el.city,
          }
        })

        //console.log(this.stores);

        this.filteredCity = this.formControl.get('city')?.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterCity(value || '')),

        )

      })
      // this.formControl.valueChanges.pipe(
      //   debounceTime(500)
      // )
      // .subscribe((value: any) => {
      //   const filter = {...value} as string;
      //   this.filterData(filter);


      // });

  }
  private _filter(value: string): string[] {
    value += ""
    const filterValue = value.toLowerCase();

    return this.stores.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  private _filterCity(value: string): string[] {
    value += ""
    const filterValue = value.toLowerCase();


    return this.cities.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  filterData(ev: any) {
    //console.log(ev);
    const data: any = {};
    for (const key in ev) {
        const element = ev[key];
        if (element !== "") {
          data[key] = element
        }
    }


    this.formField = {...this.formField, ...ev},
    this.isReady = false;
    this.retrieveData(this.lastParams, data);
    //console.log(this.lastParams, data);
  }
  retrieveData(param: string , data: any = this.formField) {
    this.lastParams = param;
    this.isReady = false;
    this.newClassesCss = {...{}}
    this.tableData = [];
    this.campSvc.getCalendar(param , data).subscribe((res: any) => {
      //console.log(res);

      this.tableData = this.transformData(res);
      // //console.log( this.tableData);
      this.getColors()
      this.isReady = true
    })
  }
  sendFilters() {

    this.filterData(this.formControl.value);
  }
  goToday() {
    const today = new Date();
    this.currentMonth = +(today.getMonth() + 1).toString().padStart(2, '0');
    this.currentYear = +today.getFullYear().toString();

    const futureDate = new Date(today);
    if ((this.currentMonth+"").length == 1 ) this.currentMonth = "0" + this.currentMonth

    futureDate.setMonth(futureDate.getMonth() + this.timeGap);

    this.nextMonth = +(futureDate.getMonth() + 1).toString().padStart(2, '0');
    this.nextYear = +futureDate.getFullYear().toString();
    const param =  this.currentMonth + '-' + this.currentYear + "/"+  this.nextMonth + '-'+ this.nextYear ;
    //console.log(param);

    this.retrieveData(param)

  }

  changeData(n: number) {

    this.timeGap = n;
    const x = new Date(this.currentMonth + '-01-'+  this.currentYear);
    //console.log(x);

    this.currentMonth = +(x.getMonth() + 1).toString().padStart(2, '0');
    this.currentYear = +x.getFullYear().toString();


    // const today = new Date();
    const futureDate = new Date(x);

    futureDate.setMonth(futureDate.getMonth() + this.timeGap);

    this.nextMonth = +(futureDate.getMonth() + 1).toString().padStart(2, '0');
    this.nextYear = +futureDate.getFullYear().toString();

    const param =  this.currentMonth + '-' + this.currentYear + "/"+  this.nextMonth + '-'+ this.nextYear ;
    //console.log(param , this.timeGap);

    this.retrieveData(param)
  }

  //#region Utils
  transformDate(dateStr: any): string {

    const [month, year] = dateStr.split('-');
    let months = this.translate.instant('MAIN.MONTHS');

    const monthIndex = parseInt(month, 10);
    const monthName = months[monthIndex];
    const yearShort = year.slice(-2);

    return `${monthName} ${yearShort}`;
  }

  transformData(data: any): any[] {
    const result = [];

    for (const key in data) {
        if (data.hasOwnProperty(key)) {
            const storeInfo = data[key].store;
            let periods = data[key].period;
            // //console.log(periods);//TODO -
            // const x: any = JSON.stringify(periods)
            // → '{"b":"foo","c":"bar","a":"baz"}'
            const sortedKeys: any[] = Object.keys(periods).sort((a: any, b: any) => (+new Date(b)) - (+new Date(a)));
            // //console.log(sortedKeys); //TODO -
            const sortedPeriod: any[] = [];
            sortedKeys.forEach((el) => {
              const p = periods[el]
              sortedPeriod.push(p)
            })

// // Crea un nuovo oggetto con le chiavi ordinate
            let nuovoOggetto : {keys: any[] , values: any[]} = {keys: [] , values: []};
            sortedKeys.forEach((key: any) => {
              const date = this.transformDate(key);
              nuovoOggetto.keys.push(date)
              nuovoOggetto.values.push(periods[key])




              // nuovoOggetto[key] = periods[key];
            });


//               periods = nuovoOggetto;

            // Find or create the store in the result array
            let store: any = result.find(s => s.store.store_id === storeInfo.store_id);
            if (!store) {
                store = {
                    store: {
                        store_id: storeInfo.store_id,
                        store: storeInfo.store,
                        display_window_id: [],
                        display_window: storeInfo.display_window,
                        unique_code: storeInfo.unique_code
                    }
                };
                result.push(store);
            }

            // Add the display window information to the store
            store.store.display_window_id.push({
                id: storeInfo.display_window,
                period: nuovoOggetto
            });
        }
    }
    this.isVeryReady = true
    return result;
  }


  getColors() {
    let prodctsColor: any = [];
    this.tableData.forEach((el) => {
      el.store.display_window_id.forEach((i: any) => {
        i.period.values.forEach((element: any) => {
          if (element.length > 0) {
            prodctsColor.push(element[0].product)
          }
        });
        // for (const key in i.period) {
        //   if (Object.prototype.hasOwnProperty.call(i.period, key)) {
        //     const element = i.period[key];

        //   }
        // }

      })
    })

    prodctsColor = [...new Set(prodctsColor)]


    const hexToRgb = (hex: any) => {
      let r = 0, g = 0, b = 0;
      if (hex.length == 4) {
        r = parseInt(hex[1] + hex[1], 16);
        g = parseInt(hex[2] + hex[2], 16);
        b = parseInt(hex[3] + hex[3], 16);
      } else if (hex.length == 7) {
        r = parseInt(hex[1] + hex[2], 16);
        g = parseInt(hex[3] + hex[4], 16);
        b = parseInt(hex[5] + hex[6], 16);
      }
      return {r, g, b};
    }

    const rgbToHex =(r: any, g: any, b: any) => {
      return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase();
    }
    // Funzione per creare varianti di colore più chiare o più scure
    const shadeColor =(color: any, percent: any) => {
      let {r, g, b} = hexToRgb(color);
      r = Math.min(255, Math.max(0, Math.round(r * (1 + percent / 100))));
      g = Math.min(255, Math.max(0, Math.round(g * (1 + percent / 100))));
      b = Math.min(255, Math.max(0, Math.round(b * (1 + percent / 100))));
      return rgbToHex(r, g, b);
    }

    const generateUniqueColorArray =(baseColors: any, length: any) => {

      const colorArray : any[] = [];
      const step = 40 / (length - 1); // Maggiore intervallo per evitare duplicati

      for (let index = 0; colorArray.length < length; index++) {

        const color = baseColors[index % baseColors.length];
        const shade = shadeColor(color, -20 + (index % (length / baseColors.length)) * step);
        if (!colorArray.includes(shade)) {
          colorArray.push(shade);
        }
        if (length <= index) {
          break
        }
      }
      if (Array.from(colorArray).length == 1) {
        colorArray[0] = rootStyles.getPropertyValue('--lf-blue').trim()
      }
      return Array.from(colorArray);
    }

    // Otteniamo le variabili CSS
    const rootStyles = getComputedStyle(document.documentElement);
    const baseColors = [
      rootStyles.getPropertyValue('--lf-light-gray').trim(),
      rootStyles.getPropertyValue('--lf-light-blue').trim(),
      rootStyles.getPropertyValue('--lf-blue').trim(),
      // rootStyles.getPropertyValue('--lf-mid-gray').trim(),
      // rootStyles.getPropertyValue('--lf-dark-blue').trim(),
      // rootStyles.getPropertyValue('--lf-red').trim(),
      // rootStyles.getPropertyValue('--lf-green').trim(),
      // rootStyles.getPropertyValue('--lf-warn').trim(),
    ];

    // Esempio di array di input

    // Generiamo il nuovo array di colori
    const colorArray: any[] = generateUniqueColorArray(baseColors, prodctsColor.length);

    prodctsColor.forEach((el: any, i: number) => {
      this.newClassesCss[el] = colorArray[i]
    })



  }
  changeView(param: "next" | "prev") {
    // Calculate the increment or decrement based on the param
    const step = param === "next" ? this.timeGap + 1 : (-1 * this.timeGap) -1;
    // Update the currentMonth and currentYear
    let cm: (string | number) = +this.currentMonth + step;
    let cy: (string | number) = +this.currentYear
    let nm: (string | number) = +this.nextMonth + step;
    let ny: (string | number) = +this.nextYear ;

    if (cm >= 13) {
      cm = cm - 12;
      cy += 1;
    } else if (cm <= 0) {
      cm = 12 + cm;
      cy -= 1;
    }

    if (nm >= 13) {
      nm = nm - 12;
      ny += 1;
    } else if (nm <= 0) {
      nm = 12 + nm;
      ny -= 1;
    }
    // if (cm == 13) {
    //   cm = 1;
    //   cy += 1;
    // } else if (cm == 0) {
    //   cm = 12;
    //   cy -= 1;
    // }

    // if (nm == 13) {
    //   nm = 1;
    //   ny += 1;
    // } else if (nm == 0) {
    //   nm = 12;
    //   ny -= 1;
    // }
      if ((cm+"").length == 1 ) cm = "0" + cm
      if ((nm+"").length == 1 ) nm = "0" + nm;
      //console.log(`${cm}-${cy}/${nm}-${ny}`);


    //console.log(`${cm}-${cy}/${nm}-${ny}`);
    // this.isReady = false;
    // this.retrieveData(`${cm}-${cy}`);
    // return `${this.currentMonth}-${this.currentYear}`;
    this.currentMonth = cm
    this.currentYear = cy
    this.nextMonth = nm;
    this.nextYear = ny;
    this.isReady = false;
    this.retrieveData(`${cm}-${cy}/${nm}-${ny}`);

  }
  get getCurrentMonthName() {
    let month: any[] = this.translate.instant('MAIN.MONTHS');
    //console.log(month);

    if (month.length == 12  ){ month.unshift("")}
    //console.log(month[+this.currentMonth] , month[+this.nextMonth]);
    if (this.currentMonth == undefined || !this.nextMonth == undefined ) {
      this.currentMonth = +this.currentMonth -1
      this.nextMonth = +this.nextMonth -1;
    }
    //console.log(month, this.currentMonth,this.nextMonth, month[+this.currentMonth] , month[+this.nextMonth]);
    if (this.currentMonth === this.nextMonth ) {
      return month[+this.currentMonth] + " " + this.currentYear;
    }  else {
      return month[+this.currentMonth] + " " + (this.currentYear == this.nextYear? '' : this.currentYear) + " - "+ month[+this.nextMonth] + " " + (this.currentYear !== this.nextYear? this.nextYear : this.currentYear)

    }
  }
    //#endregion {{currentYear}} {{currentYear == nextYear? null : "/" + nextYear  }}


}
