import { Component, OnDestroy, OnInit } from '@angular/core';
import { CampaignsTableComponent } from '../../../components/campaigns-table/campaigns-table.component';
import { CampaignsService } from '../../../services/campaigns/campaigns.service';
import { BasicPaginatorComponent } from '../../../components/basic-paginator/basic-paginator.component';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { StoreSearch } from '../../../models/store.model';
import { NavigationRoutes } from '../../../models/navigation.model';
import { MaterialModule } from '../../../modules/material.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedModule } from '../../../modules/shared.module';
import { InsertDataTableComponent } from '../../../components/insert-data-table/insert-data-table.component';
import { ApiService } from '../../../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../../../components/dialogs/confirm/confirm.component';
import {ChangeDetectorRef } from '@angular/core';
@Component({
  selector: 'app-campaigns-manager',
  standalone: true,
  imports: [CampaignsTableComponent,InsertDataTableComponent, BasicPaginatorComponent, MaterialModule, SharedModule],
  templateUrl: './campaigns-manager.component.html',
  styleUrl: './campaigns-manager.component.scss'
})
export class CampaignsManagerComponent implements OnInit, OnDestroy {
  mode: "readonly" | "editable" = "readonly";
  id: any;
  isReady = false
  detailsData : any[] = []
  fields: {[key: string ]:any} = {store: "" , city: "" , availability : "" , unique_code: "" , display_window_type: ""}
  configData: any;
  formFields!: FormGroup;
  name: string | undefined;
  dataNormalized: string = "";
  StaticProducts: any[] = [];
  activeFilters: any[] = [];
  savedData!: any ;
  filterConcession: {
    company: string | null,
    brand_id: string | null,
    product_id: any,
    company_id: any,
    product: any,
    brand: string | null
  } = {
    company : null,
    company_id : null,
    brand_id : null,
    product_id: null,
    brand: null,
    product: null
  }
  brandReady = false;

constructor(private apiSvc: ApiService,
  private campSvc: CampaignsService,
  private ar: ActivatedRoute,
  private router: Router,
  private cdref: ChangeDetectorRef ,
  private dialog: MatDialog) {
    this.id = this.ar.snapshot.paramMap.get('id')?.split('_')[1] ;
    this.name = this.ar.snapshot.paramMap.get('id')?.split('_')[0] ;

  }
  ngAfterContentChecked() {

    this.cdref.detectChanges();

     }
  ngOnInit(): void {
    this.campSvc.getCampaignStaus(this.id).subscribe((res: any) => {

      if (res.campaign_details.length > 0) {

        this.filterConcession.product_id = {name:res.campaign_details[0].products[0].name, id: res.campaign_details[0].products[0].product_id}
        this.filterConcession.company = res.campaign_details[0].products[0].brand.company.name
        const data = {
          brand_id: res.campaign_details[0].products[0].brand_id,
          city : res.campaign_details[0].displaywindows[0].store.city
        }
         this.activeFilters.push({type:'city', name: res.campaign_details[0].displaywindows[0].store.city})
         this.savedData = {
          full:true,
          keepProduct: true,
          brand_id: res.campaign_details[0].products[0].brand_id,
          brand: res.campaign_details[0].products[0].brand.name,
          company_id: res.campaign_details[0].products[0].brand.company.company_id,
          company: res.campaign_details[0].products[0].brand.company.name,
        }
        this.apiSvc.getProductsByBrandID(this.savedData.brand_id).subscribe((res: any) => {

          this.StaticProducts = res;
        })
        const req: StoreSearch = {
          param: this.id,
          search: data
        }
        this.retrieveData(req);
        this.inputControl(res);
        this.brandReady = true;
      } else {
        this.brandReady = true;
        this.inputControl(res);
        this.isReady = true;
      }
    })
    this.campSvc.cartId = this.name?.toLowerCase() + "_" + this.id;
  }
  setChips(ev: any) {

    const hasKey = this.activeFilters.filter(((el: any) => el.type == ev.type));
      if (hasKey.length > 0) {
        this.activeFilters = this.activeFilters.map((el:any) => {

          if (el.type == ev.type && ev.type != 'city' && ev.type != 'store' && ev.type != 'availability') {
            el.name = ev.name
          }
          return el
        })
        if (ev.type == 'city' || ev.type == 'store' || ev.type == 'availability') {
          this.activeFilters.push({type: ev.type, name: ev.name});

        }

      } else {
        this.activeFilters.push({type: ev.type, name: ev.name});

      }
    // this.activeFilters = ev;


  }
  setValues(ev: any) {
    const name : "company" | "brand_id" | "product_id" = ev.name;
     ev.value == null || ev.value == undefined? null : this.filterConcession[name] = ev.value[name]
    if (name == "product_id" &&  ev.value !== null &&  ev.value !== undefined) {
      this.filterConcession[name] = { name: ev.value.name, id: ev.value[name] }
      this.filterConcession.product = ev.value.name
    }
    if (name == "brand_id" &&  ev.value !== null && ev.value !== undefined) {
      this.filterConcession.brand = ev.value.name
    }
    if (name == "company" ) {
      this.filterConcession.company = ev.value.name
      this.filterConcession.company_id = ev.value.company_id
        this.resetBrand();
        return
    }


  }
  ngOnDestroy(): void {
    this.campSvc.cartId = null;
  }
  editMode() {
    this.mode = "editable";
    this.formFields.enable();
  }
  inputControl(data: any) {
    this.formFields = new FormGroup({
      name: new FormControl(data.name, Validators.required),
      internal_status: new FormControl(data.internal_status, Validators.required),

      status: new FormControl(data.status, Validators.compose([
        Validators.required
      ])),

    });
    this.formFields.disable();

  }
  saveData() {
    const req: any = {
      name: this.formFields.get('name')?.value,
      status: this.formFields.get('status')?.value,
      internal_status: this.formFields.get('internal_status')?.value,
    }
    if (req.status.toLowerCase() == 'chiusa' ) {
      req.internal_status = 'OK'
    } else {
      req.internal_status = 'DRAFT'
    }

    this.campSvc.updateCampaignStaus(req, this.id).subscribe((res) => {
    this.mode = "readonly";

    this.router.navigate([`${NavigationRoutes.Campaigns}/${req.name.replaceAll(" " , "-").toLowerCase()}_${this.id}`])
    window.location.reload();
    })
  }
  formSubmit() {
    const req = {
      name: this.formFields.get('name')?.value,
      status: this.formFields.get('status')?.value,
      internal_status: this.formFields.get('internal_status')?.value,
    }
    this.campSvc.updateCampaignStaus(req, this.id).subscribe((res) => {
      //console.log(res);

    })
  }
  resetBrand() {
    this.brandReady = false
    this.filterConcession.brand = null;
    this.filterConcession.brand_id = null;
    this.savedData = {full:false}

      this.brandReady = true


  }
  resetCompany() {
    this.brandReady = false
    this.filterConcession.product_id = null;
    this.filterConcession.product = null;
    this.filterConcession.company = null;
    this.filterConcession.brand = null;
    this.filterConcession.brand_id = null;

    this.savedData = {full:false,keepProduct: false,}
    setTimeout(() => {
      this.brandReady = true
    }, 100);

  }
  resetProduct() {

    this.filterConcession.product_id = null;
    this.filterConcession.product = null;
    this.brandReady = false
    this.savedData = {
      full:true,
      keepProduct: false,
      company_id: this.filterConcession.company_id,
      company: this.filterConcession.company,
      brand_id: this.filterConcession.brand_id,
      brand: this.filterConcession.brand
    }

    setTimeout(() => {
      this.brandReady = true;
    }, 100);

  }
  forceResetBrand() {
    this.savedData = {keepProduct: false,full:true,company_id: this.filterConcession.company_id, company: this.filterConcession.company}
    this.brandReady = false
    this.filterConcession.brand = null;
    this.filterConcession.brand_id = null;
    this.filterConcession.product_id = null;
    this.filterConcession.product= null;

    setTimeout(() => {
      this.brandReady = true;
    }, 100);


  }

  removeFilters() {

    this.brandReady = false
    this.activeFilters = [];
    this.filterConcession.brand = null;
    this.filterConcession.brand_id = null;
    this.filterConcession.company = null;
    this.filterConcession.company_id = null;
    this.filterConcession.product = null;
    this.filterConcession.product_id = null;
    this.savedData = {keepProduct: false,full:false}
    setTimeout(() => {
      this.brandReady = true
    }, 1);
  }
  searchParam(ev: any) {

    const data: any = {};
    // data['city'] = [];
    this.activeFilters.forEach((element: any) => {
      if (element.type == "city" || element.type == "store" || element.type == "availability") {
        const param = element.type;
        if (!Array.isArray(data[param])) {
          data[param] = [];

        }
        data[param].push(element.name)
      }  else {
        data[element.type] = element.name
      }
    });

    // for (const key in ev) {
    //     const element = ev[key];
    //     if (element !== "") {
    //       data[key] = element
    //     }
    // }
    const req = {
      param: this.id,
      search: data
    }
    // data.city = ["Milano", "roma"]
    if (this.filterConcession.brand_id != null) {
      this.apiSvc.getProductsByBrandID(this.filterConcession.brand_id).subscribe((res: any) => {

        this.StaticProducts = res;
      })
      // req.search.realAvailability = req.search.availability;
      req.search.brand_id = this.filterConcession.brand_id;
      this.retrieveData(req , true)
    } else {
      const deltedialogRef = this.dialog.open(ConfirmComponent, {
        data: {
          message: "Inserisci Compagnia e Brand"
        },


        restoreFocus: false}
      );


      //  alert('iserisci company e brand');
       this.isReady = true
      //  return
    }

    // this.fields = {...ev}
    //console.log(ev);
    // this.dataNormalized = ev.availability.replace("-" , "/")
  }

  retrieveData(req: StoreSearch , saveparam = false) {
    const ds = req.search;
    this.isReady = false

    this.campSvc.editCampaign(req).subscribe((res: any ) => {
      res.data = this.transformToObjectArray(res.data);
      // console.log(res);
      this.configData = {
        prevUrl : res.prev_page_url,
        nextUrl : res.next_page_url,
        activePage: res.current_page,
        totalPage: res.last_page,
        totalResults: res.total,
        links: res.links
      }
      res = res.data.filter((el: any) => {

        return el.availability !== null});

      this.detailsData = res.map((el: any) => {
        const copyEl = {...el};

        copyEl.availability = JSON.parse(copyEl.availability).months
        copyEl.realAvailability = JSON.parse(copyEl.realAvailability).months
        copyEl.realAvailability = this.transformToObjectArray(copyEl.realAvailability);

        if (copyEl.used_at !== "" ) {
          copyEl.used_at = JSON.parse(copyEl.used_at)?.months
          copyEl.used_at = this.transformToObjectArray(copyEl?.used_at);
          copyEl.realAvailability = [...copyEl.realAvailability, ...copyEl.used_at];
        } else {
          copyEl.used_at = [];
        }
        if (req.search && req.search.availability != "" ) {


          //console.log(copyEl);

          if (copyEl.internal_status == "") {

            if (req.search.availability) {
              copyEl.used_at.push(req.search.availability)

            }

          }


        }





        return copyEl;
      })
      //TODO -
      // if (req.search && req.search.availability != "") {
      //   this.detailsData = this.detailsData.filter((el: any) => {

      //     return  el.realAvailability.includes(req.search.availability)});
      // }

      if (saveparam) {
        ds.availability = this.dataNormalized
        // this.fields = ds;
        // //console.log(this.fields);


      }
      // this.dataSource.availability = JSON.parse(this.dataSource.availability).months

      this.isReady = true;

    })

  }
  removeOption(option: any) {

    this.activeFilters = this.activeFilters.filter((el: any) => {
      return el.name !== option.name
    })

    // this.selectedStore = this.selectedStore.filter(item => item.name !== option);
    // this.formControl.get('store').setValue(this.selectedStore);
  }
  pageChanger(ev: any) {
    console.log(this.filterConcession.brand_id);
    const data: any = {};
    // data['city'] = [];
    this.activeFilters.forEach((element: any) => {
      if (element.type == "city" || element.type == "store" || element.type == "availability") {
        const param = element.type;
        if (!Array.isArray(data[param])) {
          data[param] = [];

        }
        data[param].push(element.name)
      }  else {
        data[element.type] = element.name
      }
    });

    const req: StoreSearch = {
      url: ev == 'next'?  this.configData.nextUrl : ( ev == 'prev' ? this.configData.prevUrl : ev ),
      search: data
    }
    //  data.city = ["Milano", "roma"]
    if (this.filterConcession.brand_id != null) {
      this.apiSvc.getProductsByBrandID(this.filterConcession.brand_id).subscribe((res: any) => {

        this.StaticProducts = res;
      })
    } else {
      const deltedialogRef = this.dialog.open(ConfirmComponent, {
        data: {
          message: "Inserisci Compagnia e Brand"
        },


        restoreFocus: false}
      );
      return
    }
    req.search.brand_id = this.filterConcession.brand_id;

      // req.search.realAvailability = req.search.availability;
    this.retrieveData(req)

  }
  transformToObjectArray(data: any): any[] {
    if (Array.isArray(data)) {
      // Se è già un array, lo restituiamo così com'è
      return data;
    } else if (typeof data === 'object' && data !== null) {
      // Se è un oggetto, lo trasformiamo in un array dei suoi valori
      return Object.values(data);
    } else {
      // Se non è né un array né un oggetto, restituiamo un array vuoto o possiamo lanciare un errore
      return [];
    }
  }
  formatClass() {
    return this.formFields.get('status')?.value.toLowerCase().replaceAll(' ' , '-')
  }
}
