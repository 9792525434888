<header class="dialog-header d-flex justify-content-between">
<span flex></span>
  <button class="btn border-0 text-white" mat-dialog-close>
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
  </button>
</header>
<mat-dialog-content class="save-campaign">
  <div class="col-12">
    <div class="lf-card resume mx-3 mx-xxl-0">
      <h2>{{'CAMPAIGNS.resume' | translate}}</h2>
      <div class="container-fluid px-0 band-off">
        @for (item of params.windowType |keyvalue; track item.key) {
          @if(item.value.length > 0) {
            <div class="row pb-2">
              <div class="col-auto"># {{item.key}}</div>
              <div class="col-auto">{{item.value.length}}</div>
            </div>
          }
        }
        <div class="row">
          <div class="col-12">

            <hr>
            <p class="py-3">
              {{'TABLES.campaign' | translate}}: {{params['campName']}}
            </p>
          </div>

        </div>
        <div class="row">
          <div class="col-12">

            <p class="ps-2 pb-3">{{'TABLES.status' | translate}}:</p>
          </div>
          <div class="col-12" *ngIf="params['status'] !== ''">

              <mat-form-field class="status" [class]="formatClass(statusClosed)">
                <mat-select  name="status" id="" class="status" [(ngModel)]="statusClosed"  matInput title="status" >
                  <mat-option [value]="'STATUS.ok' | translate">{{'STATUS.ok' | translate | titlecase}}</mat-option>
                  </mat-select>
              </mat-form-field>

          </div>
        </div>

      </div>
      <div class="row">
        <div class="col-12">
          <button class="btn-new w-100" (click)="close('confirm')">
            {{'ACTIONS.confirm' | translate}}
          </button>

        </div>

      </div>

    </div>

  </div>

</mat-dialog-content>
