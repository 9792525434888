import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SharedModule } from '../../modules/shared.module';
import { MaterialModule } from '../../modules/material.module';

@Component({
  selector: 'app-carousel',
  standalone: true,
  imports: [SharedModule, MaterialModule],
  templateUrl: './carousel.component.html',
  styleUrl: './carousel.component.scss'
})
export class CarouselComponent {
  currentIndex: number = 0;
  @Input() size: "full" | "modal" = "modal"
  @Input() imageData: any[] = [];
  @Input() mode: "readonly" | "editable" = "readonly";
  @Output() actions = new EventEmitter<any>()
  selectImage(index: number): void {
    this.currentIndex = index;
  }
  placeImg = '/assets/images/logo.png'

  emitAction(ev: string, imageUrl: string = "") {

    this.actions.emit({
      action: ev,
      value: imageUrl
    })

  }


}
