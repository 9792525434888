import { HttpErrorResponse, HttpInterceptorFn, HttpResponse } from '@angular/common/http';
import { inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { catchError, tap, throwError } from 'rxjs';
import { UtilitiesService } from './utilities.service';
import { ApiService } from '../services/api.service';
import { NavigationRoutes } from '../models/navigation.model';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

export const mainInterceptor: HttpInterceptorFn = (req, next) => {
  const authToken = localStorage.getItem('token-lf');
  const utils = inject(UtilitiesService);
  const auth = inject(AuthService)
  const api = inject(ApiService)
  const router = inject(Router) ;

  const snack = inject(MatSnackBar)
  // Clone the request and add the authorization header
  let authReq: any;
  // if (authToken) {
    authReq = req.clone({
      setHeaders: {
        Authorization: `Bearer ${authToken}`
      }
    });

  // }

  api.loading = true;


  let source = req.url.includes('/assets/') ? "[Assets]" : " [HTTP] ";

  let color = source.includes('Assets') ? utils.getCssVar('dark-blue') : utils.getCssVar('warn');

  utils.logger(source, req, color, 'white', 'dark-blue');
  // //console.log("%c"+source+" Req: %c" + utils.getUrl(req) +" %o",
  // 'background:#FFF; border: 1px solid #3598ba; border-radius:5px 0 0 5px ; padding:2px;color: '+color+';margin: 10px 0;',
  // 'background:#3598ba; border: 1px solid #3598ba; border-radius:0 5px 5px 0; padding:2px;color: #FFF;margin: 10px 0;',
  //   req);
  // Pass the cloned request with the updated header to the next handler
  return next(authReq).pipe(
    tap((v: any) => {

      if (v instanceof HttpResponse) {
        utils.logger(source, authReq, color, 'white', 'light-blue', v.body);
        api.loading = false;
      }
    }),
    catchError((error: HttpErrorResponse) => {
      if (error.error.message == "Validation error") {
        error.error.message = 'Credenziali non valide'

      }
      snack.open( error.error.message || error.message , "ok", {
        duration: 3000
      })
      utils.logger(source, authReq, color, 'white', 'red', error);
      api.loading = false;
      //console.log(error.error.message);
      if (error.error.message == "Unauthenticated.") {
        auth.setAuth = false;
        localStorage.removeItem('token-lf');
        router.navigateByUrl(NavigationRoutes.Login);

      }


      return throwError(() => new Error('error'));
    }
    ));
};
