@if(isReady) {

  <header class="dialog-header d-flex justify-content-between">
    {{'ALERTS.new' | translate}}
    <button class="btn border-0 text-white" mat-dialog-close>
      <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
    </button>
  </header>
  <mat-dialog-content class="alert">
    <form [formGroup]="formFields" (ngSubmit)="formSubmit()">
      <div class="container-fluid">

        <div class="row">
          <div class="col-12 d-flex align-items-center">

            <mat-form-field >
              <input placeholder="{{'GENERAL.name' | translate}}" formControlName="name"  title="input" matInput >
              @if (formFields.get('name')?.hasError('required')) {
                <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
              }
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <mat-label>{{'TABLES.pharmacy' | translate}}:</mat-label>
            <mat-form-field >

    <input type="text"
           aria-label="Number"
           matInput
           formControlName="store"
           [matAutocomplete]="auto">
    <mat-autocomplete #auto="matAutocomplete">
      @for (option of filteredOptions | async; track option) {
        <mat-option [value]="option.name">{{option.name}}</mat-option>
      }
    </mat-autocomplete>
              <!-- <input formControlName="store" title="input" matInput >
              @if (formFields.get('store')?.hasError('required')) {
                <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
              } -->
            </mat-form-field>
          </div>
          <div class="col-6 d-flex align-items-center">
            <mat-label>{{'TABLES.display_window_id' | translate}}:</mat-label>
            <mat-form-field class="">
              <mat-select formControlName="display_window_id">

                @for (window of displayWindows; track window) {
                  <mat-option [value]="window.id">{{window.name}}</mat-option>
                }
                <mat-option [value]="'null'">{{'GENERAL.no_one' | translate}}</mat-option>
              </mat-select>

              <!-- <input formControlName="display_window_id" type="number" title="input" matInput > -->

            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <mat-label>{{'TABLES.city' | translate}}:</mat-label>
            <mat-form-field class="">
              <input formControlName="city" title="input" matInput >
              @if (formFields.get('city')?.hasError('required')) {
              <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
            }
            </mat-form-field>
          </div>
          <div class="col-6 d-flex align-items-center">
            <mat-label>{{'GENERAL.person_in_charge' | translate}}:</mat-label>
            <mat-form-field class="">
              <input formControlName="person_in_charge" title="input" matInput >
              @if (formFields.get('person_in_charge')?.hasError('required')) {
              <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
            }
            </mat-form-field>
          </div>
        </div>
        <div class="row">
          <div class="col-6 d-flex align-items-center">
            <mat-label>{{'TABLES.status' | translate}}:</mat-label>
            <mat-form-field class="">
              <mat-select name="s" id="" class="status"  matInput title="title" formControlName="status">

                <mat-option  [value]="'STATUS.high' | translate">{{'STATUS.high' | translate}}</mat-option>
                <mat-option  [value]="'STATUS.standard' | translate">{{'STATUS.standard' | translate}}</mat-option>
                <mat-option  [value]="'STATUS.low' | translate">{{'STATUS.low' | translate}}</mat-option>

            </mat-select>
            @if (formFields.get('store_status')?.hasError('required')) {
                <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
              }
            </mat-form-field>
          </div>
          <div class="col-6 d-flex justify-content-end align-items-center">

              <button (click)="upload.click()" class="btn link d-flex flex-column align-items-center" type="button">
               {{'ALERTS.insert_image' | translate}}

              </button>
              <input title="input"  #upload class="d-none" type="file" accept=".jpg,.jpeg,.png"
              (change)="onFileSelected($event )"
              >


          </div>



        </div>
        <div class="row">
          <div class="col-12 d-flex align-items-center">
            <mat-label>{{'GENERAL.description' | translate}}:</mat-label>
            <mat-form-field class="">
              <textarea rows="7" formControlName="description" title="input" matInput ></textarea>
              @if (formFields.get('description')?.hasError('required')) {
              <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
            }
            </mat-form-field>
          </div>
        </div>


      </div>
    </form>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-end">


    <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
    <button class="btn link me-4" mat-button mat-dialog-close>{{'ACTIONS.cancel' | translate}}</button>
    <button  [disabled]="!formFields.valid" class="btn-new px-5 m-0" (click)="formSubmit()">{{'ACTIONS.save' | translate}}</button>
  </mat-dialog-actions>
}

