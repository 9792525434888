import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  getMinDate(data: any[]): string {
    return data.reduce((min, current) => {
      const [monthMin, yearMin] = min.split('-').map(Number);
      const [monthCurrent, yearCurrent] = current.split('-').map(Number);

      if (yearCurrent < yearMin || (yearCurrent === yearMin && monthCurrent < monthMin)) {
        return current;
      }
      return min;
    });
  }

  downloadFile(data: any, filename = 'data') {
    let csvData = this.ConvertToCSV(data,
        [ 'availability',
          'brand',
          'brand_id',
          'campaign',
          'campaign_detail_id',
          'campaign_id',
          'category',
          'city',
          'company',
          'company_id',
          'created_at',
          'dimensions',
          'display_window',
          'display_window_id',
          'internal_status',
          'materials',
          'product',
          'product_id',
          'status',
          'store',
          'type',
          'unique_code',
          'updated_at',
          'used_at']);
    let blob = new Blob(['\ufeff' + csvData],
        { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') !=
        -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {

        // If Safari open in new window to
        // save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray: any, headerList: any) {
    let array = typeof objArray !=
        'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'Id;';

    for (let index in headerList) {
        row += headerList[index] + ';';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = (i + 1) + '';
        for (let index in headerList) {
            let head = headerList[index];
            line += ';' + array[i][head];
        }
        str += line + '\r\n';
    }
    return str;
}

}
