import { Component, Input } from '@angular/core';
import { SharedModule } from '../../modules/shared.module';
import { Router } from '@angular/router';
import { NavigationRoutes } from '../../models/navigation.model';

@Component({
  selector: 'app-alerts-list',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './alerts-list.component.html',
  styleUrl: './alerts-list.component.scss'
})
export class AlertsListComponent {
  constructor(private router: Router) {}
  // @Input() title = "Avvisi"
  // @Input() text = "Ultimi avvisi";
  @Input() apiAlerts: {store:string,description: string, alert: string, display_window?: string }[] = [];

  goToMainPage() {
    this.router.navigate([NavigationRoutes.Alert]);
  }
}
