import { Component, OnInit } from '@angular/core';
import { CommonHeaderComponent } from '../common-header/common-header.component';
import { SideMenuComponent } from '../side-menu/side-menu.component';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { LoaderComponent } from '../loader/loader.component';
import { ApiService } from '../../services/api.service';
import { NavigationBreadcrumb, NavigationRoutes } from '../../models/navigation.model';
import { SharedModule } from '../../modules/shared.module';

@Component({
  selector: 'app-page-layout',
  standalone: true,
  imports: [CommonHeaderComponent,RouterLink, SideMenuComponent, RouterOutlet, LoaderComponent, SharedModule],
  templateUrl: './page-layout.component.html',
  styleUrl: './page-layout.component.scss'
})
export class PageLayoutComponent implements OnInit {

  constructor(private ac: ActivatedRoute , private router: Router , public api: ApiService) {

  }
  nav = NavigationRoutes
  urls: any[] = this.createBreadCrumb();

  ngOnInit() {

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.urls = this.createBreadCrumb()
      }
    });
  }


  createBreadCrumb() {

    const dataInputs = this.router.url.split('/');
    dataInputs.shift();
    let path = ""
    const urls = dataInputs.map((page: string) => {
      if (page == "") return
      page = page.replaceAll("-" , " ")
      path += `${page}/`;

      if (Object.keys(NavigationBreadcrumb).includes(page)) {
        const p = page as keyof typeof NavigationBreadcrumb
        page = NavigationBreadcrumb[p]
      }

      const data = {
        link: path,
        text: (page.charAt(0).toUpperCase() + page.slice(1).toLowerCase()).split('_')[0]
      }
      return data
    });
    return urls
  }





}
