@if (isReady) {
  <div class="content container-fluid alert-detail">
    <form [formGroup]="formFields" (ngSubmit)="formSubmit()" class="pb-2">
      <div class="container-fluid lf-card pt-0">

        <div class="row  band-off">
          <div class="col-auto ps-0">
            <div class="d-flex align-items-center">
              <mat-form-field >
                <!-- <mat-label>{{'GENERAL.name' | translate}}:</mat-label> -->

                <input id="alert-name" placeholder="{{'GENERAL.name' | translate}}" formControlName="name"  title="input" matInput >
                @if (formFields.get('name')?.hasError('required')) {
                  <mat-error>{{'STORES.required_field' | translate}}</mat-error>
                }
              </mat-form-field>
              <button [hidden]="mode == 'editable'" type="button" title="edit" mat-button (click)="editMode()" >
                <i class="icon-lf-edit icon-small"></i>
              </button>

            </div>

          </div>
          <div class="col-auto d-flex align-items-center">
            <mat-label>{{'TABLES.status' | translate}}</mat-label>
            <mat-form-field class="status" [class]=" formatClass()">
              <mat-select  name="status" id="" class="status" formControlName="status"  matInput title="status" >
                <mat-option  [value]="'STATUS.high' | translate">{{'STATUS.high' | translate}}</mat-option>
                <mat-option  [value]="'STATUS.standard' | translate">{{'STATUS.standard' | translate}}</mat-option>
                <mat-option  [value]="'STATUS.low' | translate">{{'STATUS.low' | translate}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-7 ps-4">
            <div class="d-flex align-items-center pb-4">
              <mat-label>{{'TABLES.pharmacy' | translate}}:</mat-label>
              <mat-form-field >
                <input formControlName="store" title="input" matInput >
                @if (formFields.get('store')?.hasError('required')) {
                  <mat-error>{{'STORES.required_field' | translate}}</mat-error>
                }
              </mat-form-field>

            </div>
            <div class="d-flex align-items-center pb-4">
              <mat-label>{{'TABLES.city' | translate}}:</mat-label>
              <mat-form-field class="">
                <input formControlName="city" title="input" matInput >
                @if (formFields.get('city')?.hasError('required')) {
                <mat-error>{{'STORES.required_field' | translate}}</mat-error>
              }
              </mat-form-field>

            </div>
            <div class="d-flex align-items-center pb-4">
              <mat-label>{{'TABLES.display_window' | translate}}:</mat-label>
              <mat-form-field class="">
                <input formControlName="display_window" type="TEXT" title="input" matInput >
                @if (formFields.get('display_window')?.hasError('required')) {
                <mat-error>{{'STORES.required_field' | translate}}</mat-error>
              }
              </mat-form-field>

            </div>
            <div class="d-flex align-items-center pb-4">
              <mat-label>{{'GENERAL.person_in_charge' | translate}}:</mat-label>
              <mat-form-field class="">
                <input formControlName="person_in_charge" title="input" matInput >
                @if (formFields.get('person_in_charge')?.hasError('required')) {
                <mat-error>{{'STORES.required_field' | translate}}</mat-error>
              }
              </mat-form-field>

            </div>
            <div class="d-flex align-items-top">
              <mat-label>{{'GENERAL.description' | translate}}:</mat-label>
              <mat-form-field class="">
                <textarea rows="7" formControlName="description" title="description" matInput ></textarea>
                @if (formFields.get('description')?.hasError('required')) {
                <mat-error>{{'STORES.required_field' | translate}}</mat-error>
              }
              </mat-form-field>

            </div>
          </div>
          <div class="col-12 col-lg-5">
            <app-carousel
             [imageData]="data.images"
             (actions)="actionsReceiver($event)"
             [mode]="mode"
             [size]="'full'"
             ></app-carousel>
             <input title="input" #upload class="d-none" type="file" accept=".jpg,.jpeg,.png"
          (change)="onFileSelected($event)"
          >
          </div>
        </div>
        <div class="row justify-content-end">
          <div class="col-auto">
            <button type="button" [hidden]="mode == 'editable'" (click)="editMode()" class="btn-new">
              {{'ACTIONS.edit' | translate}}
            </button>
            <button type="button" [hidden]="mode !== 'editable'" (click)="updateAlert()" class="btn-new">
              {{'ACTIONS.save' | translate}}
            </button>

          </div>
        </div>



      </div>
    </form>
  </div>

}
