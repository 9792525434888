<header class="dialog-header d-flex justify-content-between">
  Uploads
  <button class="btn border-0 text-white" mat-dialog-close>
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
  </button>

</header>
<mat-dialog-content class="uploads">
<mat-label for="type"><b>Scegli il tipo di upload</b></mat-label>

  <mat-select placeholder="scegli il tipo di upload" name="type" id="type" class="status" matInput title="title"
    [(ngModel)]="type" class="border-bottom">

    <mat-option [value]="'R'">Concessioni</mat-option>
    <mat-option [value]="'S'">Farmacie</mat-option>
    <mat-option [value]="'C'">Prodotti</mat-option>
    <mat-option [value]="'D'">Vetrine</mat-option>
  </mat-select>
  <div class="text-end pt-2">
    <a href="./assets/docs/{{fileType[type]}}.xls" download class="btn-link" >Scarica file esempio {{fileType[type]}} </a>

  </div>
  <div class="col-auto" draggable="true">
    <button (click)="upload.click()" class="border mt-3 btn xls d-flex flex-column align-items-center" type="button">
      <span>
        <i class="icon-lf-uploadxls icon-lg pe-1"></i>
        {{'BUTTONS.upload_excel' | translate}}
      </span>
    </button>
    <input title="input" #upload class="d-none" type="file" accept=".xls" (change)="onFileSelected($event)">
    <span class="d-block text-center">{{filename}}</span>
  </div>


</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center px-4 pb-4">
  <div class="col-12 text-center">
    <button (click)="confirmUpload()" [disabled]="!fileRequest" class="mx-auto mt-3 btn-new xls d-flex flex-column align-items-center" type="button">
      Carica
    </button>

  </div>


  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <!-- <button  [disabled]="!formFields.valid" class="w-100 btn-new px-5 m-0" (click)="formSubmit()">{{'BUTTONS.create' | translate}}</button> -->
</mat-dialog-actions>
