import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { concatMap, delay, from, map, of } from 'rxjs';
import { environment as ENV } from '../../../../environments/environment';
import { ApiService } from '../../../services/api.service';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { CarouselComponent } from '../../../components/carousel/carousel.component';

@Component({
  selector: 'app-alerts-detail',
  standalone: true,
  imports: [MaterialModule, SharedModule, CarouselComponent],
  templateUrl: './alerts-detail.component.html',
  styleUrl: './alerts-detail.component.scss'
})
export class AlertsDetailComponent implements OnInit {
  id: any;
  mode: "readonly" | "editable" = "readonly";
  formFields!: FormGroup;
  localImages: any;
  isReady = false;
  filesToUpload: any[] = [];
  data: any;
  extra: any;
  @ViewChild('upload') upload!: ElementRef;
  constructor(private ar: ActivatedRoute,private router: Router, private apiSvc: ApiService, private alertSvc: AlertsService) {
    this.id = this.ar.snapshot.paramMap.get('id')?.split('_')[1] ;
    this.extra = this.router.getCurrentNavigation()?.extras.state;

  }
  editMode() {
    this.mode = "editable";
    this.formFields.get('person_in_charge')?.enable();
    this.formFields.get('description')?.enable();
    this.formFields.get('name')?.enable();
    this.formFields.get('status')?.enable();
  }
  ngOnInit(): void {
    this.retrieveData();

  }
  retrieveData() {
    this.alertSvc.getAlert(this.id).subscribe((res) => {
      this.data = res;
      this.data.images = JSON.parse(this.data.images);
      this.inputControl();
      this.formFields.disable()
      //console.log(this.data);
      this.isReady = true;
      this.localImages = this.data.images;
      this.imageJoin()

    if(this.extra) this.editMode()
    })
  }
  actionsReceiver(event: any) {
    //console.log(event);
    if (event.action == 'add') {
      this.upload.nativeElement.click();
    }
    if (event.action == 'delete') {
      // console.log(event);
      this.deleteImage(event.value.url)
    }

  }
  updateAlert() {
    // const images =
    let req: any = {...this.formFields.value, ...{images: JSON.stringify(this.data.images)}  }
    if (req.display_window_id == "" || req.display_window_id == null) {
      let {
        display_window_id, ...res } = req;
        req = {...res};
    }
    req.store_id = this.data.store_id
    this.alertSvc.updateAlert(req, this.id).subscribe((res) => {
      this.retrieveData();
      this.mode = "readonly";
    })
  }

  imageJoin() {
    const tagMap: any = {};
    this.localImages.forEach((item:any) => {
      const tag = item.tags[0]; // Prendi il primo elemento dell'array tags
      const url = item.url;

      if (!tagMap[tag]) {
          tagMap[tag] = [];
      }

      tagMap[tag].push(url);
    });

    const outputArray = Object.keys(tagMap).map(tag => ({
        tag: tag,
        urls: tagMap[tag]
    }));

    //console.log(outputArray);

    this.localImages = outputArray;
    this.isReady = true
  }

  deleteImage(url: string) {
    this.localImages = this.localImages.map((el: any) => {

        el.urls = el.urls.filter( (f:any) => f != url);

      return el;
    })// called once readAsDataURL is completed
    this.data.images = this.data.images.filter((el:any) => el.url != url)


  }
  onFileSelected(event: any): void {
    //console.log(event);

    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    this.filesToUpload.push({file:formData , tag: 'alert'});
    // var reader = new FileReader();
    this.saveImage();
    // reader.readAsDataURL(event.target.files[0]); // read file as data url

    // reader.onload = (ev) => {
    //       // if (el.tag === tag) {
    //         this.localImages = ev.target?.result
    //       // }
    //     }
    //     // called once readAsDataURL is completed



  }
  inputControl() {
    this.formFields = new FormGroup({
      name: new FormControl(this.data.name, Validators.required),
      store: new FormControl(this.data.store.name, Validators.required),
      store_id: new FormControl(this.data.store_id),
      description: new FormControl(this.data.description, Validators.compose([
        Validators.required,
      ])),
      city: new FormControl(this.data.store.city, Validators.required),
      status: new FormControl(this.data.status, Validators.compose([
        Validators.required,
      ])),
      person_in_charge: new FormControl(this.data.person_in_charge, Validators.compose([
        Validators.required,
      ])),
      display_window_id: new FormControl(this.data.display_window_id),
      display_window: new FormControl(this.data.displaywindow?.name),

    });

  }
  saveImage() {
    // this.data.images = []
    from(this.filesToUpload).pipe(
      concatMap(value =>
        of(value).pipe(
          delay(500),
          concatMap(val => this.apiSvc.uploadFile(val.file).pipe(
            map((response: any) => ({ value: val, response }))
          ))
        )
      )
    ).subscribe({
      next: ({ value, response }) => {
        const url = ENV.api.BASE_URL + "/uploads/images/" + (response.file);
        this.data.images.push(
          {
            url: url,
            tags: [value.tag]
          }
        )
        //console.log(this.data);

      },

    })

  }
  formSubmit() {
    //console.log(this.formFields.value);

    if (this.formFields.valid) {
      if (!this.data.images) {
        this.data.images = []
      }
      const {
        created_at,
        updated_at, displaywindows, ...req } = this.data;
        const data = {...req, ...this.formFields.value}
        data.images = JSON.stringify(data.images);
        //console.log(data);
        this.isReady = false
        // this.alertSvc.InsertAlert(data).subscribe((el: any)=> {
        //   this.isReady = false;

        // })
        // req.display_windows = JSON.stringify(req.display_windows);
        // this.dialogRef.close(data);
    }
  }

  formatClass() {
    return this.formFields.get('status')?.value.toLowerCase().replaceAll(" " , "-")
  }

}
