<header class="dialog-header d-flex justify-content-between">
  {{(data.name ? 'STORES.edit_details' :'STORES.new_details') | translate}}
  <button class="btn border-0 text-white" mat-dialog-close>
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
  </button>
</header>
<mat-dialog-content class="store-fields">
  <form [formGroup]="formFields" (ngSubmit)="formSubmit()">
    <div class="container-fluid">
      <!-- <div class="row">
        <mat-form-field class="">
          <mat-label>{{'STORES.site' | translate}}:</mat-label>
          <input formControlName="name" title="site" matInput >

          @if (formFields.get('name')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
        </mat-form-field>

      </div> -->
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.pharmacy' | translate}}:</mat-label>
          <mat-form-field class=" ">
            <input formControlName="name" title="input" matInput >
            @if (formFields.get('name')?.hasError('required')) {
              <mat-error>{{'STORES.required_field' | translate}}</mat-error>
            }
          </mat-form-field>
        </div>
        <!-- <div class="col-4 d-flex align-items-center">
          <mat-form-field class="">
            <mat-label>{{'TABLES.id' | translate}}:</mat-label>
            <input formControlName="store_id" title="input" matInput >
            @if (formFields.get('store_id')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div> -->
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.city' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="city" title="input" matInput >
            @if (formFields.get('city')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>

        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.status' | translate}}:</mat-label>
          <mat-form-field class="w-100">
            <mat-select name="s" id="" class="status" matInput title="title" formControlName="status">
              <mat-option [value]="'STATUS.closed' | translate">{{'STATUS.closed' | translate}}</mat-option>
              <mat-option [value]="'STATUS.active' | translate">{{'STATUS.active' | translate}}</mat-option>
              <mat-option [value]="'STATUS.inactive' | translate">{{'STATUS.inactive' | translate}}</mat-option>
            </mat-select>
            @if (formFields.get('status')?.hasError('required')) {
              <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
            }
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.address' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="address" title="input" matInput >
            @if (formFields.get('address')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.postal_code' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="postal_code"  title="input" matInput >
            @if (formFields.get('postal_code')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.province' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="province" title="input" matInput >
            @if (formFields.get('province')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.piva' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="piva"  title="input" matInput >
            @if (formFields.get('piva')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.email' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="email" title="input" matInput >
            @if (formFields.get('email')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
            @if (formFields.get('email')?.hasError('pattern') && !formFields.hasError('required')) {
              <mat-error>{{'STORES.email_error' | translate}}</mat-error>
            }
          </mat-form-field>
        </div>
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.ranking' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="ranking" title="input" matInput >
            @if (formFields.get('ranking')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          @if ((formFields.get('ranking')?.hasError('min') ||formFields.get('ranking')?.hasError('max') ) && !formFields.hasError('required')) {
            <mat-error>{{'STORES.ranking_error' | translate}}</mat-error>
          }

          </mat-form-field>
        </div>
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.unique_code' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="unique_code" title="input" matInput >
            @if (formFields.get('unique_code')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>
        <div class="col-4 d-flex align-items-center">
          <mat-label>{{'TABLES.region' | translate}}:</mat-label>
          <mat-form-field class="">
            <input formControlName="region" title="input" matInput >
            @if (formFields.get('region')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
          }
          </mat-form-field>
        </div>
        <div class="col-4 d-flex align-items-center mt-4">
          <mat-label>{{'GENERAL.pdf' | translate}}</mat-label>


              <button (click)="upload.click()" class="btn upload-btn d-flex flex-column align-items-center" type="button">
               <span>{{ this.toUpload?.name || data.pdf_file?.split('/pdf/')[1]  || 'BUTTONS.upload_pdf' | translate}}</span>

              </button>
              <input title="input"  #upload class="d-none" type="file" accept=".pdf"
              (change)="onFileSelected($event)"
              >






        </div>

      </div>

    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">


  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button class="btn link me-4" mat-button mat-dialog-close>cancel</button>
  <button  [disabled]="!formFields.valid" class="btn-new px-5 m-0" (click)="formSubmit()">{{'ACTIONS.save' | translate}}</button>
</mat-dialog-actions>
