import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { SharedModule } from '../../modules/shared.module';
import { TranslateService } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
@Component({
  selector: 'app-chart',
  standalone: true,
  imports: [CommonModule, SharedModule],
  templateUrl: './chart.component.html',
  styleUrl: './chart.component.scss'
})
export class ChartComponent implements OnInit, AfterViewInit, OnDestroy {
  statsApiData: any;

  constructor(private translate: TranslateService) {

  }

  @Input() apiData: any = {};

  @ViewChild('chart') private chart!: ElementRef;
  chartInstance: any = [];
  statistic: any
  selectedButton: string = "all";
  palette = [
    [ "mid-gray" , "dark-blue"],
    ["gray","light-blue"],
    ["green","warn" ],
    ["blue" ,"red"]
  ]

  colors: any = {all: []}
  dataLabels: any;
  percentage: any;
  dataset: any = {all: []}

  ngOnInit(): void {
    console.log(this.apiData);

    // nascosti i monitor
    this.apiData.free = this.apiData.free.filter((el: any) => el.type.toLowerCase() != 'monitor')
    this.apiData.used = this.apiData.used.filter((el: any) => el.type.toLowerCase() != 'monitor')

    this.statsApiData = this.transformData(this.apiData);

    this.statistic = this.getStatistics(this.statsApiData)

    this.percentage = (100 / this.statistic.tot) * (this.statistic.occ)
  }

  getStatistics(data: any) {
    const resp = {
      occ: 0,
      tot: 0
    }
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[key];
        resp.occ += element.used
        resp.tot += (element.free + element.used)
      }
    }
    return resp
  }
  transformData(data: any) {
    const result: any = {};

    data.used.forEach((item: any )=> {
      if (!result[item.type.toLowerCase()]) {
        result[item.type.toLowerCase()] = { used: 0, free: 0 };
      }
      result[item.type.toLowerCase()].used = item.tot;
    });

    data.free.forEach((item: any) => {
      if (!result[item.type.toLowerCase()]) {
        result[item.type.toLowerCase()] = { used: 0, free: 0 };
      }
      result[item.type.toLowerCase()].free = item.tot;
    });

    return result;
  }
  async ngAfterViewInit() {
    const av$ = this.translate.get("TERMS.available");
    const av = await lastValueFrom(av$);
    const busy$ = this.translate.get("TERMS.busy");
    const busy = await lastValueFrom(busy$);

    this.dataLabels  = {
      all: []
    }
    let index = 0
    for (const key in this.statsApiData) {
      if (Object.prototype.hasOwnProperty.call(this.statsApiData, key)) {
        const element = this.statsApiData[key];
        this.dataLabels[key] = [];
        this.colors[key] = [];
        this.dataset[key] = []

        this.dataLabels[key].push(key+" " + busy)
        this.dataLabels.all.push( key+" " + busy )

        this.dataset[key].push(element.used);
        this.dataset.all.push(element.used)

        if (index <= (this.palette.length - 1))   {
          this.colors[key].push(this.getCssVar(this.palette[index][1]) , this.getCssVar(this.palette[index][0]) )
          this.colors.all.push(this.getCssVar(this.palette[index][1]))
          index++

        } else {
          index = index - (this.palette.length - 1)
          this.colors[key].push(this.getCssVar(this.palette[index][1]) , this.getCssVar(this.palette[index][0]) )
          this.colors.all.push(this.getCssVar(this.palette[index][1]))

        }

      }
    }
    index = 0

    for (const key in this.statsApiData) {
      if (Object.prototype.hasOwnProperty.call(this.statsApiData, key)) {
        const element = this.statsApiData[key];
        this.dataLabels[key].push(key+" " + av)
        this.dataLabels.all.push(key+" "+ av)

        this.dataset.all.push(element.free)
        this.dataset[key].push(element.free);
        if (index <= (this.palette.length - 1))   {
          this.colors.all.push(this.getCssVar(this.palette[index][0]))
          index++
        } else {
          index = index - (this.palette.length - 1)
          this.colors.all.push(this.getCssVar(this.palette[index][0]))
        }
      }
    }


    const label$ = this.translate.get("MAIN.BUSY");
    const lbl = await lastValueFrom(label$);
    const _this = this;

    const doughnutLabel = {
      id: 'doughnutLabel',
      beforeDatasetsDraw(chart: any, args: any, pluginOptions: any) {
        const { ctx, data } = chart;
        ctx.save();
        const xCoor = chart.getDatasetMeta(0).data[0]?.x
        const yCoor = chart.getDatasetMeta(0).data[0]?.y
        ctx.font = '500 18px ' + _this.getCssVar('font');
        ctx.textAlign = 'center';
        ctx.fillStyle = _this.getCssVar('dark-blue');
        ctx.fillText(( isNaN(_this.percentage)?  0 :  _this.percentage.toFixed(0))  + '%', xCoor, yCoor - 25);
        ctx.font = '12px ' + _this.getCssVar('font');
        ctx.fillStyle = _this.getCssVar('mid-gray');
        ctx.fillText(lbl, xCoor, yCoor - 5);
      }
    }
    const htmlLegendPlugin = {
      id: 'htmlLegend',
      afterUpdate(chart: any, args: any, options: any) {

        const ul = _this.getOrCreateLegendList(chart, 'legend-container');
        while (ul.firstChild) {
          ul.firstChild.remove();
        }

        const items = chart.options.plugins.legend.labels.generateLabels(chart);

        items.forEach((item: any) => {
          const li = document.createElement('li');
          li.classList.add('chart-legend-list')
          // Color box
          const boxSpan = document.createElement('span');
          boxSpan.style.background = item.fillStyle;
          boxSpan.classList.add('chart-legend-bullet')
          // Text
          const textContainer = document.createElement('p');
          textContainer.style.color = item.fontColor;
          textContainer.classList.add('label-legend')
          // textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
          const text = document.createTextNode(item.text);
          textContainer.appendChild(text);

          li.appendChild(boxSpan);
          li.appendChild(textContainer);
          ul.appendChild(li);
        });
      }
    };
    this.chartInstance = new Chart(this.chart.nativeElement, {
      type: 'doughnut',
      data: {
        labels: this.dataLabels.all,
        datasets: [{
          label: '',
          data: this.dataset.all,
          borderWidth: 0,
          backgroundColor: this.colors.all,
          spacing: 1,
          rotation: -90,
          circumference: 180,
          datalabels: {
            display: true,
            color: 'white',
            align: 'start',
            offset: 6,
            font: {
              size: 11,
              weight: "lighter"
            },
          },
        },
        {
          data: [1],
          backgroundColor: this.getCssVar('white'),
          hoverBackgroundColor: this.getCssVar('white'),
          weight: 0.1,
          borderWidth: 0,
          rotation: -90,
          circumference: 180,
        },
        {
          data: [1],
          borderColor: this.getCssVar('red'),
          weight: 0,
          borderWidth: 3,
          borderDash: [5, 5],
          borderDashOffset: 5,
          rotation: -90,
          circumference: 180,
        }]
      },
      options: {
        responsive: true,
        scales: {
          y: {
            beginAtZero: true,
            display: false,
            type: 'linear',
            position: 'right',
            grid: {
              color: 'red',
              display: false
            }
          },
          x: {
            display: false,
            grid: {
              // borderWidth: 0,
              color: '#FF0000',
              display: false
            },
          },
        },
        layout: {
          padding: {
            bottom: 0
          },
        },
        //cutout: 65,
        plugins: {
          datalabels: {
            display: false,
          },
          legend: {
            display: false,
          },
          tooltip: {
            enabled: false,
            backgroundColor: this.getCssVar('dark-blue'),
            displayColors: false,
            callbacks: {
              title: function (context) {
                return ''
              },
              labelTextColor: function (context) {
                return '#FFF';
              }
            }
          },
          title: {
            display: false,
          },
        },
      },
      plugins: [doughnutLabel, htmlLegendPlugin, ChartDataLabels],
    });

    this.changeData('all');

  }
  getCssVar(color: string) {
    const rootStyles = getComputedStyle(document.documentElement);
    return rootStyles.getPropertyValue('--lf-' + color)
  }
  changeData(type: (any | string) = 'all') {
    this.selectedButton = type;

    if (type !== 'all') {
      const free = this.statsApiData[type].free
      const used = this.statsApiData[type].used
      this.percentage = (100 / (free + used)) * used

      this.statistic =  {
        occ: used,
        tot: (used + free),
      }

    } else {
      this.statistic = this.getStatistics(this.statsApiData);

      this.percentage = (100 / this.statistic.tot) * (this.statistic.occ)
    }



    this.chartInstance.data.labels = this.dataLabels[type];
    this.chartInstance.data.datasets[0].data = this.dataset[type];
    this.chartInstance.data.datasets[0].backgroundColor = this.colors[type];
    this.chartInstance.data.datasets[0].cutout = 65;
    this.chartInstance.data.datasets[1].cutout = 65;
    this.chartInstance.data.datasets[2].cutout = 65;

    this.chartInstance.update();
  }
  getOrCreateLegendList(chart: any, id: any) {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector('ul');

    if (!listContainer) {
      listContainer = document.createElement('ul');
      listContainer.classList.add('chart-legend-wrap')

      legendContainer?.appendChild(listContainer);
    }
    return listContainer;
  }

  ngOnDestroy(): void {

  }
}
