import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { CarouselComponent } from '../../carousel/carousel.component';
declare var bootstrap: any;
@Component({
  selector: 'app-image-carousel',
  standalone: true,
  imports: [MaterialModule, SharedModule, CarouselComponent],
  templateUrl: './image-carousel.component.html',
  styleUrl: './image-carousel.component.scss'
})
export class ImageCarouselComponent{
  constructor(@Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  currentIndex: number = 0;


  selectImage(index: number): void {
    this.currentIndex = index;
  }
}
