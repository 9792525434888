
  <div class="chart-card">
    <div class="top-chart">
      <div class="row justify-content-between mx-auto">
        <div class="col lines-data">
          <h3>{{'MAIN.COUNTER'| translate}}</h3>
          <p>{{'MAIN.SPACES_AVAILAVBLE'| translate}}</p>
        </div>
        <div class="col-auto lines-data">
          <div class="btn-group" role="group">
            <button class="btn btn-switch" (click)="changeData('all')" [ngClass]="{'active': selectedButton === 'all'}">{{'ACTIONS.see_all' | translate}}</button>
            @for(data of statsApiData|keyvalue;track data.key) {
              @if (data.key != 'monitor') {
                <button class="btn btn-switch" (click)="changeData(data.key)" [ngClass]="{'active': selectedButton === data.key}">{{data.key}}</button>

              }
            }
            <!-- <button class="btn btn-switch" (click)="changeData('vetrofania')" [ngClass]="{'active': selectedButton === 'v'}">Vetrofanie</button>
            <button class="btn btn-switch" (click)="changeData('monitor')" [ngClass]="{'active': selectedButton === 's'}">Stampe</button> -->

          </div>

        </div>
      </div>
      <div class="row justify-content-center align-items-center">
        <div class="col-auto px-4 text-center">
          <span class="tot-busy">{{statistic.occ}}</span>
          <p class="tot-label">{{'MAIN.BUSY'| translate}}</p>
        </div>
        <div class="col-auto tot-label px-4"> {{'MAIN.OF'| translate}} </div>
        <div class="col-auto text-center">
          <span class="tot-available px-4">{{statistic.tot}}</span>
          <p class="tot-label">{{'MAIN.TOTAL'| translate}}</p>
        </div>
      </div>

    </div>
    <canvas #chart id="chart" style="position: relative; max-height:300px; max-width:300px;"></canvas>
    <div class="" id="legend-container"></div>
  </div>

