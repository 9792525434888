<div class="content container-fluid campaign-cart">
  <div class="row justify-content-between">
    <div class="col col-xxl-9">
      <div class="content container-fluid">
        <div class="row pb-2">
          <div class="lf-card p-0  ">
            <div class="col text-end py-3">
              <button type="button" class="btn my-0" title="edit" (click)="openMassiveChange()">
                <i class="icon-lg icon-lf-options"></i> Cambio massivo
              </button>
            </div>
            @if (isReady) {
              <mat-chip-set class="ps-4 pt-2">
                @if (activeFilters.length > 0) {
                  <mat-label class="ps-3 d-flex w-auto align-items-center">Filtri:</mat-label>
                  <button class="btn link" (click)="removeFilters()">clear</button>
                }
                <!-- @if (filterConcession.brand) {
                <mat-chip >
                  {{filterConcession.brand}}

                </mat-chip>
              } -->

                @for (item of activeFilters; track $index) {
                  @if (item.name != "") {
                    <mat-chip >
                      {{item.name}}
                      <button mat-button class="btn cancel-btn p-0 d-inline-flex" (click)="removeOption(item)">

                        <mat-icon matChipRemove>cancel</mat-icon>
                      </button>
                    </mat-chip>

                  }

                }
              </mat-chip-set>
              <app-campaigns-table [selectedProduct]="filterConcession.product_id" [availableProducts]="StaticProducts" (chip)="setChips($event)" [isMultipleFilter]="true" (filters)="searchParam($event)" [formField]="fields" (actions)="actionReceiver($event)" [displayedColumns]="columns" [campaigns]="detailsData">
              </app-campaigns-table>
              <div class="mt-4">
                @if (detailsData.length == 0) {
                  <div class="p-4 w-100 text-center">{{'GENERAL.no_data' | translate}}</div>
                } @else {
                  <div class="mt-4 px-2">
                    <basic-paginator [config]="configData" (paginator)="pageChanger($event)"></basic-paginator>

                  </div>

                }

              </div>
            }
          </div>
        </div>
      </div>

    </div>
    <div class="col-12 px-4 p-xxl-0 col-xxl-3">
      <div class="lf-card resume mx-3 mx-xxl-0">
        <h2>{{'CAMPAIGNS.resume' | translate}}</h2>
        <div class="container-fluid px-0 band-off">
          @for (item of windowType|keyvalue; track item.key) {
            @if(item.value.length > 0) {
              <div class="row pb-2">
                <div class="col-auto"># {{item.key}}</div>
                <div class="col-auto">{{item.value.length}}</div>
              </div>
            }
          }
          <div class="row">
            <div class="col-12">

              <hr>
              <p class="py-3">
                {{'TABLES.campaign' | translate}}: {{campName}}
              </p>
            </div>

          </div>
          <div class="row">
            <div class="col-12">

              <p class="ps-2 pb-3">{{'TABLES.status' | translate}}:</p>
            </div>
            <div class="col-12" *ngIf="status !== ''">
              <mat-form-field class="status" [class]="formatClass(status)">
                <mat-select  name="status" id="" class="status" [(ngModel)]="status"  matInput title="status" >
                  <mat-option [value]="'STATUS.pending' | translate">{{'STATUS.pending' | translate | titlecase}}</mat-option>
                  <mat-option [value]="'STATUS.closed' | translate">{{'STATUS.closed' | translate | titlecase}}</mat-option>
                  <mat-option [value]="'STATUS.in_progress' | translate">{{'STATUS.in_progress' | translate | titlecase}}</mat-option>
                  </mat-select>
              </mat-form-field>
            </div>
          </div>

        </div>
        <div class="row">
          <div class="col-12">
            <button class="btn-new w-100" (click)="saveAs('new')">
              {{'ACTIONS.save' | translate}}
            </button>

          </div>
          <div class="col-12">

            <button class="btn-ol w-100" (click)="saveAs('draft')">
              {{'ACTIONS.save_draft' | translate}}
            </button>
          </div>
        </div>

      </div>

    </div>
  </div>
</div>

