<div class="row" [class]="bound">
  @if (companies.length > 0) {
    <div class="col-2">
      @if(!isBrand) {
        <label >{{'GENERAL.company' | translate}}</label>
      }
      <mat-form-field>
        @if (isBrand) {
          <mat-label>{{'GENERAL.company' | translate}}</mat-label>
        }
        <input title="title" matInput [matAutocomplete]="autoCompany" [formControl]="companyControl">
        <mat-autocomplete  #autoCompany="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onCompanySelected($event.option.value)">
          <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
            {{ company.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div>
    <div class="col-2">
      @if(!isBrand) {
        <label >{{'GENERAL.brand' | translate}}</label>
      }
      <mat-form-field>
        @if(isBrand) {
          <mat-label>{{'GENERAL.brand' | translate}}</mat-label>
        }

        <input title="title" matInput [matAutocomplete]="autoBrand" [formControl]="brandControl" >
        <mat-autocomplete #autoBrand="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onBrandSelected($event.option.value)">
          <mat-option *ngFor="let brand of filteredBrands | async" [value]="brand">
            {{ brand.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </div>
    @if(!isBrand) {
    <div class="col-2">
        <label >{{'GENERAL.product' | translate}}</label>
        <mat-form-field>

          <input title="title" matInput [matAutocomplete]="autoProduct" [formControl]="productControl" >
          <mat-autocomplete #autoProduct="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onProductSelected($event.option.value)">
            <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
              {{ product.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

      </div>
    }
    <!-- @if (ids.length > 1) {
      <div class="col-2" >
        <mat-form-field>
          <mat-label>Display Window</mat-label>
          <input title="title" matInput [matAutocomplete]="autoDisplayWindow"  [formControl]="displayWindowControl">
          <mat-autocomplete #autoDisplayWindow="matAutocomplete" [displayWith]="displayFn">
            <mat-option *ngFor="let displayWindow of filteredDisplayWindows | async" [value]="displayWindow">
              {{ displayWindow.name }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

      </div>

    } -->

    @if(isBrand || onMassiveChange) {

      <div class="col-2 d-none">
        <mat-form-field>
          <mat-select [formControl]="internalControl"  name="s" id=""  matInput title="title">

            <mat-option [value]="'in'">Interno</mat-option>
            <mat-option [value]="'out'">Esterno</mat-option>
          </mat-select>

        </mat-form-field>
      </div>
      <div class="col text-end">
        <button class="circle btn bg-confirm btn-circle" (click)="submit()" [disabled]="!formValid">
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="check"></mat-icon>
        </button>
        @if(!onMassiveChange) {
          <button class="circle btn bg-discard btn-circle" (click)="close()"  >
            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
          </button>

        }

      </div>
    }

  } @else {
    <div class="col-12">
      Nessun prodotto associabile
    </div>

  }

</div>




