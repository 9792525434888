export interface StoreSearch {
  url?: string;
  param?:string | any;
  page?: string;
  search?: any
}
export interface PaginatorConfig {
  prevUrl : string;
  nextUrl : string;
  activePage:number
  totalPage: number;
  totalResults:number ;
  links?: any[];
}

export enum StoreEditFields {
  details = "details",
  images = "images",
  brands = "brands"
}
