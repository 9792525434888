import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { ApiService } from '../../../services/api.service';
import { environment as ENV } from '../../../../environments/environment.staging';
import { concatMap, delay, from, map, of } from 'rxjs';

@Component({
  selector: 'app-store-images',
  standalone: true,
  imports: [MaterialModule, SharedModule],
  templateUrl: './store-images.component.html',
  styleUrl: './store-images.component.scss'
})
export class StoreImagesComponent implements OnInit {

  localImages: any[] = [];
  isReady = false;
  filesToUpload: any[] = [];
  tag: any = "new";
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private apiSvc: ApiService,public dialogRef: MatDialogRef<StoreImagesComponent>) {

  }

  ngOnInit(): void {
    console.log(this.data);
    if (this.data.length == 1 && this.data[0]?.length == 0) {
      this.data.shift();
    }
    this.localImages = this.data;
      this.imageJoin()
  }

  imageJoin() {

    const tagMap: any = {};
    this.localImages.forEach((item:any) => {

      const tag = item.tags[0]; // Prendi il primo elemento dell'array tags
      const url = item.url;

      if (!tagMap[tag]) {
          tagMap[tag] = [];
      }

      tagMap[tag].push(url);
    });

    const outputArray = Object.keys(tagMap).map(tag => ({
        tag: tag,
        urls: tagMap[tag]
    }));

    //console.log(outputArray);

    this.localImages = outputArray;
    this.isReady = true
  }

  onFileSelected(event: any, tag: string): void {

    const file = event.target.files[0];
    //console.log(file);

    const formData = new FormData();
    formData.append('file', file);
    this.filesToUpload.push({file:formData , tag: tag});
    //console.log(formData);
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]); // read file as data url

    reader.onload = (ev) => {
    this.localImages = this.localImages.map((el: any) => {
          if (el.tag === tag) {
            el.urls.push(ev.target?.result);
          }
          return el;
        })
        // called once readAsDataURL is completed
        if (tag == "new") {
          //console.log(this.tag);

          this.localImages.push(
           { urls: [ev.target?.result],
            tag: this.tag
           }
          );

        }
      }


    }
  saveImage() {
    from(this.filesToUpload).pipe(
      concatMap(value =>
        of(value).pipe(
          delay(500),
          concatMap(val => this.apiSvc.uploadFile(val.file).pipe(
            map((response: any) => ({ value: val, response }))
          ))
        )
      )
    ).subscribe({
      next: ({ value, response }) => {
        const url = ENV.api.BASE_URL + "/uploads/images/" + (response.file);
        this.data.push(
          {
            url: url,
            tags: [value.tag]
          }
        )
      },
      complete: () => {
        this.dialogRef.close(this.data);
      }
    })

  }

  uploadFiles(file: any) {
    return this.apiSvc.uploadFile(file)
  }

  deleteImage(image: any, url: string) {
    this.localImages = this.localImages.map((el: any) => {
      if (el.tag === image.tag) {
        el.urls = el.urls.filter( (f:any) => f != url);
      }
      return el;
    })// called once readAsDataURL is completed
    this.data = this.data.filter((el:any) => el.url != url)


  }

}
