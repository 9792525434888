export const environment = {
  production: false,
  mode: "staging",
  api: {
    BASE_URL: "https://lafarmacia.ideadocet.com",
    endpoints: {
      product_display_windows: "/api/productsdisplaywindows",
      campaigns: "/api/campaigns",
      company: "/company",
      companies: "/api/companies",
      windows: "/api/display-windows",
      campaign_detail: "/api/campaign-details",
      mainCampaign: "/main-campaign.json",
      login: "/api/login",
      register: "/api/register",
      cities: "/api/stores-cities",
      stores: "/api/stores",
      edit: "/edit",
      reset_psw: "/api/reset-password",
      profile: "/api/profile",
      logout: "/api/logout",
      alerts: "/api/alerts",
      brands: "/api/brands",
      brandstores: "/api/brandstores",
      products: "/api/products",
      search: "/search",
      deep_search: "/deep-search",
      summary: "/summary",
      bulk_update: "/api/campaign/bulk-update",
      dashboard: "/dashboard/",
      graph: "graph/",
      calendar: "/calendar/",
      upload_image: "/api/upload/image",
      upload_xls: "/api/upload/xls",
      upload_pdf: "/api/upload/pdf",
      forgot_password: "/api/forgot-password"
    }


  }
};
