import { Component, Inject } from '@angular/core';
import { SharedModule } from '../../../modules/shared.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '../../../modules/material.module';

@Component({
  selector: 'app-save-campaign',
  standalone: true,
  imports: [SharedModule, MaterialModule],
  templateUrl: './save-campaign.component.html',
  styleUrl: './save-campaign.component.scss'
})
export class SaveCampaignComponent {
  statusClosed= 'OK'
  constructor(

    @Inject(MAT_DIALOG_DATA) public params: {
      [key: string]: any,
      windowType?: {[key: string]: any[]}
    } = {"": []},
    public dialogRef: MatDialogRef<SaveCampaignComponent>,
    ) {

  }
  close(action: string) {
    this.dialogRef.close(action)
  }
  formatClass(el: string) {
    return el.toLowerCase().replaceAll(" " , "-")
  }
}
