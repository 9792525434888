<aside [ngClass]="{'mini' : isMini}">
  <div>

    <button class="expand w-100 btn text-white" title="menu" (click)="expand()">
      <mat-icon fontIcon="menu"></mat-icon>
    </button>
    <a class="profile" (click)="goToItems(profileUrl)" >
      <img src="./assets/images/logo.png" alt="">
      <span class="label pt-2">{{'MAIN.PROFILE' | translate}}</span>

    </a>
  </div>
  <nav>
    <ul>
      @for (item of menuItems; track $index) {
        <li>
          <a (click)="goToItems(item.url)" [ngClass]="{active: path.startsWith('/' + item.url) }">
            <i class="icon-lg icon-lf-{{item.icon}}"></i>
            <span class="label">{{'PAGES.' + item.name | translate}}</span>

          </a>
        </li>
      }
    </ul>
  </nav>
  <a (click)="logout()" class="logout">
   <span class="label">{{'MAIN.EXIT' | translate}}</span>  <i class="icon-lg icon-lf-logout"></i>
  </a>
</aside>
