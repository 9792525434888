import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
 imports:      [ CommonModule, TranslateModule, ReactiveFormsModule ],
 declarations: [  ],
 exports:      [ CommonModule, FormsModule, TranslateModule, ReactiveFormsModule ]
})
export class SharedModule { }
