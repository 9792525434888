/**
 * Used in: HomePage,PV detail window,
 */
export interface BasicTable {
  campaign: string;
  company: string;
  brand: string;
  product: string;
  start: string;
  display_window_id: string;
  status: string;
  details: string;
  internal_status: string;
}
/**
 * Used in: Campaigns,
*/
export interface CampaignsTable {
  company: string;
  brand: string;
  product: string;
  campaign: string;
  availability: string;
  display_window_id: string;
  city: string;
  province: string;
  internal_status: string;
  status: string;
  store: string
}
/**
 * Used in: Campaigns window,
*/
export interface CampaignsDetailTable {
  window: string;
  availability: string;
  company: string;
  brand: string;
  product: string;
  uni_code: string;
  type: string;
  dimensions: string;
  pharmacy: string;
  city: string;
  month: string;
  status: string;
  pdf: string;
}

/**
 * Used in: Campaigns resume,
*/
export interface CampaignsResumeTable {
  window: string;
  uni_code: string;
  type: string;
  dimensions: string;
  pharmacy: string;
  city: string;
  brand: string;
  month: string;
  status: string;
}
/**
 * Used in: PV list,
*/
export interface StoresTable {
  store_id: string;
  name: string;
  city: string;
  province: string;
  piva: string;
  address: string;
  postal_code: string;
  region: string;
  email: string;
  ranking: string;
  status: string;
}
/**
 * Used in: PV detail,
*/
export interface StoresDetailTable {
  display_window_id: string;
  unique_code: string;
  type: string;
  category: string;
  dimensions: string;
  warning: string;
  restrictions: string;
  status: string;
  actions: any;
  availability: string;
  crud: any
  details: string;
}
/**
 * Used in: PV Aletrts,
*/
export interface AlertsDetailTable {
  alert_id: string;
  store: string;
  alert: string;
  unique_code: string;
  city: string;
  person_in_charge: string;
  created_at: string;
  status: string;
  crud: string;
  details: string;
}
export interface StoreWindowDetailTable {
  company: string;
  brand: string;
  product: string;
  restrictions: string;
  status: string;
  availability: string,
  created_at: string
}


export interface TableOptions {
  mese?: string;
  company?: string;
  brand?: string;
  product?: string;
  inizio?: string;
  windows?: string;
  status?: string;
  details?: string;
  internal_status?: string;

  campaign?: string;
  month?: string;
  display_window_id?: string;
  city?: string;
  province?: string;

  window?: string;
  availability?: string;
  uni_code?: string;
  type?: string;
  dimensions?: string;
  pharmacy?: string;
  pdf?: string;
  unique_code?: any;
  store_id?: string;
  piva?: string;
  address?: string;
  postal_code?: string;
  region?: string;
  email?: string;
  ranking?: string;

  id_window?: string;
  category?: string;
  dimension?: string;
  alert?: string;
  restrizione?: string;

  notify?: string;
  notify_name?: string;
  is_loading?: string;
  creation_date?: string;

}

export interface CalendarTableOptionsDetail {
  period: any;
  id: number;
}

export interface StoreDetails {
  display_window: string;
  display_window_id: CalendarTableOptionsDetail[];
  store: string;
  store_id: string;
  unique_code: string;
}
export interface PeriodObj {
  [key: string]: PeriodObjDetail[]
}
export interface PeriodObjDetail {
  brand: string;
  company: string;
  created_at: string;
  product: string;
  updated_at: string;
  used_at: string;
}

export interface CalendarEditData {
  store: StoreDetails;
}


export const AlertsDetailTableColumns = ['alert_id','store','alert','unique_code','city','person_in_charge','created_at','status', 'crud','details']
export const StoreWindowDetail = ['company','brand','product','restrictions','status', 'created_at','availability']
export const StoresDetailTableColumns = ['name_w','unique_code','type','category','dimensions','materials','warning','restrictions','status','availability', 'actions', 'crud', 'details']
export const StoresTableColumns = ['unique_code','name','city','province','piva','address','postal_code','region','email','ranking', 'status', 'num_used', 'anomaly','details']
export const CampaignsResumeTableColumns = ['display_window','unique_code','type','dimensions','store','city','company','realAvailability','crud']
export const CampaignsTableColumns = [ 'campaign','company','brand','product','availability','display_window', 'store','city','draft','status' , 'crud']
export const BasicTableColumns = [ 'campaign',  'company',  'brand',  'product',  'start',  'display_window',  'status',  'details']
export const CampaignsDetailTableColumns = ['display_window','disp','company','unique_code','type','dimensions','store', 'city','realAvailability','actions']
