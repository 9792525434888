import { Component, Inject, Input } from '@angular/core';
import { MaterialModule } from '../../../modules/material.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SharedModule } from '../../../modules/shared.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { CampaignsService } from '../../../services/campaigns/campaigns.service';

@Component({
  selector: 'app-new-campaign',
  standalone: true,
  imports: [MaterialModule, SharedModule],
  templateUrl: './new-campaign.component.html',
  styleUrl: './new-campaign.component.scss'
})
export class NewCampaignComponent {
  formFields!: FormGroup;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<NewCampaignComponent>,
    private campSvc: CampaignsService,

  ) {
    this.inputControl();
  }

  inputControl() {
    this.formFields = new FormGroup({
      name: new FormControl("", Validators.required)
    });

  }

  formSubmit() {
    //console.log(this.formFields.valid);

    if (this.formFields.valid) {
      //console.log(this.formFields.value.name);
      const req = {
        name: this.formFields.value.name,
        status: 'TRATTATIVA IN CORSO',
        internal_status: 'DRAFT',
      }
      this.campSvc.newCampaign(req).subscribe((res)=> {
        //console.log(res);
        this.dialogRef.close(res);
      })

    }
  }


}
