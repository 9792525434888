<!--

    <div class="d-flex">
      <mat-form-field>
        <mat-label>Company</mat-label>
        <input (focus)="startSearch()" title="title" matInput [matAutocomplete]="autoCompany" [formControl]="companyControl">
        <mat-autocomplete  #autoCompany="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onCompanySelected($event.option.value)">
          <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
            {{ company.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>




      <mat-form-field>
        <mat-label>Brand</mat-label>
        <input title="title" matInput [matAutocomplete]="autoBrand" [formControl]="brandControl" >
        <mat-autocomplete #autoBrand="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onBrandSelected($event.option.value)">
          <mat-option *ngFor="let brand of filteredBrands | async" [value]="brand">
            {{ brand.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Product</mat-label>
        <input title="title" matInput [matAutocomplete]="autoProduct" [formControl]="productControl" >
        <mat-autocomplete #autoProduct="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onProductSelected($event.option.value)">
          <mat-option *ngFor="let product of filteredProducts | async" [value]="product">
            {{ product.name }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </div> -->

    <!-- <form [formGroup]="form">
      <mat-form-field appearance="fill">
        <mat-label>Company</mat-label>
        <input  type="text" matInput formControlName="company" [matAutocomplete]="autoCompany" >
        <mat-autocomplete #autoCompany="matAutocomplete" (optionSelected)="onCompanyChange()">
          <mat-option *ngFor="let company of companies" [value]="company">
            {{ company }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Brand</mat-label>
        <input type="text" matInput formControlName="brand" [matAutocomplete]="autoBrand" >
        <mat-autocomplete #autoBrand="matAutocomplete" (optionSelected)="onBrandChange()">
          <mat-option *ngFor="let brand of brands" [value]="brand">
            {{ brand }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>Product</mat-label>
        <input type="text" matInput formControlName="product" [matAutocomplete]="autoProduct">
        <mat-autocomplete #autoProduct="matAutocomplete">
          <mat-option *ngFor="let product of products" [value]="product">
            {{ product }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form> -->
    @if (isReady) {
      <form [formGroup]="form" class="campaign-form">
        <mat-form-field appearance="fill" >
          <!-- <mat-label>Company</mat-label> -->
          <input placeholder="{{'GENERAL.company' | translate}}" title="company" type="text" matInput formControlName="company" [matAutocomplete]="autoCompany">
          <mat-autocomplete #autoCompany="matAutocomplete">
            <mat-option *ngFor="let company of filteredCompanies | async" [value]="company">
              {{ company }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        <mat-form-field appearance="fill">
          <!-- <mat-label>Brand</mat-label> -->
          <input placeholder="{{'GENERAL.brand' | translate}}" title="brand" type="text" matInput formControlName="brand" [matAutocomplete]="autoBrand">
          <mat-autocomplete #autoBrand="matAutocomplete">
            <mat-option *ngFor="let brand of filteredBrands | async" [value]="brand">
              {{ brand }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>

        @if(!this.element.isNew || (this.element.isNew && availableProducts.length == 0)  ) {
          <mat-form-field appearance="fill">
            <!-- <mat-label>Product</mat-label> -->
            <input placeholder="{{'GENERAL.product' | translate}}" title="product" type="text" matInput formControlName="product" [matAutocomplete]="autoProduct">
            <mat-autocomplete #autoProduct="matAutocomplete">
              <mat-option *ngFor="let product of filteredProducts | async" [value]="product.name">
                {{ product.name }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        }
        @if (availableProducts.length != 0 && this.element.isNew ) {
          <mat-form-field [hidden]="availableProducts.length == 0 || !this.element.isNew" class="example-full-width">
            <input type="text"
                   placeholder="{{'GENERAL.product' | translate}}"
                   aria-label="product"
                   matInput
                   [formControl]="myControl"
                   [matAutocomplete]="auto">
            <mat-autocomplete #auto="matAutocomplete">
              @for (option of filteredStaticProducts | async; track option) {
                <mat-option [value]="option.name">{{option.name}}</mat-option>
              }
            </mat-autocomplete>
          </mat-form-field>
        }


      </form>

    }
