import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private http: HttpClient) { }

  insertNewAssociation(param: any) {
    return this.http.post( ENV.api.BASE_URL + ENV.api.endpoints.brandstores, param )
  }
  deleteAssociation(id: any) {
    return this.http.delete( ENV.api.BASE_URL + ENV.api.endpoints.brandstores+ "/" +  id )
  }
}
