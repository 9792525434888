<div class="content container-fluid">
  <div class="row mb-4">
    <div class="col-md-6 pb-3 col-xl-6 col-xxl-3">
        <div class=" lf-card fixed-height flex-column p-0">
          <div class="band-light lf-card-header d-flex pb-1">
            <h3 class="col-4">{{params?.name}}</h3>
            <h3 class="col-4">{{'STORES.site' | translate}} : {{params?.store.name}}</h3>


            <button (click)="editFields('details')" class="col-auto ms-auto link float-end">{{'STORES.edit' | translate}}</button>

          </div>

          <div class="band-primary d-flex">
            <div class="col-4">
              <h3>{{'TABLES.unique_code' | translate}}:</h3>
              <h3>{{params?.unique_code}}</h3>

            </div>
            <div class="col-4">
              <h3>{{'GENERAL.type' | translate}}:</h3>
              <h3>{{params?.type}}</h3>
            </div>
            <div class="col-4">
              <h3>{{'TABLES.dimensions' | translate}}: {{params?.address}}</h3>
              <h3>{{params?.dimensions}}</h3>
            </div>
          </div>
          <div class="band-off d-flex">
            <div class="col-4">
              <p>{{'TABLES.restrictions' | translate}}: {{params?.restrictions}}</p>
            </div>
            <div class="col-4">
              <p>{{'TABLES.warning'| translate}}: {{params?.warning}}</p>
            </div>

          </div>
          <div class="band-light d-flex">
            <p class="col-6">
            <!-- {{'TABLES.piva' | translate}}: {{params?.piva}} -->
          </p>
            <p class="col-6">
              <!-- {{'TABLES.email' | translate}}: {{params?.email}} -->
            </p>
          </div>
        </div>
    </div>
    <div class="ps-0 col-md-6 pb-3 col-xl-6 col-xxl-2">
      <div class="lf-card d-flex flex-wrap fixed-height p-0">
        <div class="band-light col-12 d-flex justify-content-between  lf-card-header pb-1 band-light w-100">
          <h3>{{'STORES.licensed_brands' | translate}}</h3>
          @if (brands.length > 0) {
            <button (click)="editFields('brands')" class="link float-end">{{'STORES.edit' | translate}}</button>
          }
        </div>
        @for (item of brands; track $index) {
          <div class="band-light  col-7 band-light ">
            <p class="company">{{item.company}} </p> <p class="brand">{{item.brand}}</p>
            <!-- @for (product of item.product; track $index) {
              <p class="product">{{product}}</p>
            } -->
          </div>
          <div class="band-light col-5 band-light align-self-center">
            <p>{{item.display_window}}</p>
          </div>
        }
        @if (brands.length == 0) {
          <div class="mx-auto">
            <button (click)="editFields('brands')" class="btn d-flex flex-column align-items-center">
              <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="add_circle_outline"></mat-icon>

              {{'STORES.add_brand' | translate}}

            </button>

          </div>
        }
      </div>
    </div>
    <div class=" ps-xxl-0 col-md-6 pb-3 col-xl-6 col-xxl-4">
      <div class="lf-card images p-0">
        <button title="zoom" class="btn zoom-in" (click)="imageZoom()">
          <mat-icon fontIcon="search"></mat-icon>
        </button>
        <div class="band-light d-flex justify-content-start lf-card-header pb-1 band-light w-100">
          <h3>{{'STORES.images' | translate}}</h3>

          <!-- @if (images.length > 0) {
          <button (click)="editFields('images')" class="link">{{'STORES.edit' | translate}}</button>
          <div class="btn-group ms-auto" role="group">
            <button class="btn btn-switch" (click)="changeData('all')" [ngClass]="{'active': selectedTag === 'all'}">Vedi tutto</button>
            @for(tag of tags; track $index ) {
              <button class="btn btn-switch" (click)="changeData(tag)" [ngClass]="{'active': selectedTag === tag}">{{tag}}</button>
            }

          </div>
          } -->
        </div>

        <div [class]="images.length == 0 ? 'd-none' : 'd-flex'" class="band-light d-flex justify-content-between w-100">
          @for (item of images; track $index) {
            <div class="flex-20">
              <img class="w-100" [src]="item.url || placeImg" alt="">
            </div>
          }

        </div>
       @if (images.length == 0)  {
          <div class="mx-auto h-100 d-flex flex-column align-items-center justify-content-center">
            <button (click)="editFields('images')" class="btn d-flex flex-column align-items-center">
              <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="add_circle_outline"></mat-icon>

              {{'STORES.add_image' | translate}}

            </button>

          </div>
        }
      </div>
    </div>
    <!-- @if(alertsData.length !== 0) { -->
      <div class="col-md-6 col-xl-6 col-xxl-3 pb-3 ps-0" >

          <div class="lf-card fixed-height bg-white">
            <app-alerts-list
            [apiAlerts]="alertsData">
            <button (click)="newStoreAlert()" mat-mini-fab class="fab m-0 btn-new">+</button>
          </app-alerts-list>


          </div>

      </div>

    <!-- } -->
  </div>
  <div class="row">
    <div class="col-12">
      <div class="lf-card">
        @if (isReady) {
          <app-basic-table
            [showPaginator]="true"
            [isMultipleFilter]="true"
            [formField]="filters"
            [tableData]="params.datatable"
            [displayedColumns]="columns"
            (action)="actionReceiver($event)"
            (filters)="newSearch($event)"
          ></app-basic-table>
        }
      </div>
    </div>
  </div>
</div>
