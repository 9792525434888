import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NavigationRoutes } from '../../models/navigation.model';
import { TranslateService } from '@ngx-translate/core';
import { SharedModule } from '../../modules/shared.module';
import { MaterialModule } from '../../modules/material.module';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { CampaignsService } from '../../services/campaigns/campaigns.service';
import { ApiService } from '../../services/api.service';
import { CartDialogComponent } from '../dialogs/cart-dialog/cart-dialog.component';
import { UploadsComponent } from '../dialogs/uploads/uploads.component';

@Component({
  selector: 'app-common-header',
  standalone: true,
  imports: [CommonModule, SharedModule, MaterialModule],
  templateUrl: './common-header.component.html',
  styleUrl: './common-header.component.scss'
})
export class CommonHeaderComponent implements OnInit, OnDestroy {
  sub: any;
  showCart: boolean = false;

  constructor(
    public dialog: MatDialog,
    public translate: TranslateService,
    private router: Router,
    private apiSvc: ApiService,
    private campSvc: CampaignsService ) {


    translate.addLangs(['en', 'it', 'es']);
    const lang = localStorage.getItem("lang");
    if (lang) {
      translate.setDefaultLang(lang);
      translate.use(lang);
    } else {
      // translate.setDefaultLang('it');
      const browserLang = translate.getBrowserLang();
      translate.use(browserLang?.match(/it|en|es/) ? browserLang : 'it');
    }

  }
  nav = NavigationRoutes
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  ngOnInit() {
    this.sub = this.apiSvc.state$.subscribe(state => {
      this.showCart = false
      if (state && state.value === true) {


          this.showCart = true

        const dialogRef = this.dialog.open(CartDialogComponent , {
          backdropClass: 'cdk-overlay-transparent-backdrop',
          hasBackdrop: true,
          position: {
            top: '120',
            right:'250'
          },
          data: state,
          id: "cart-dialog",
          width: "340",
          restoreFocus: false
        })
        dialogRef.afterClosed().subscribe((res)=> {
          if (res == 'cart') {
            this.goToCart()
          }

        })

      }


    });

  }

  changeLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem("lang" , lang);
  }

  goProfile() {
    this.router.navigate([NavigationRoutes.Profile] );
  }

  goToCart() {
    const id = this.campSvc.cartId;
    if (id != null) {
      this.goToItems(id+"")
    } else {
      this.router.navigate([`/${NavigationRoutes.Campaigns}`]);
    }

  }
  goToItems(page: string ) {
    page = page.replaceAll(" " , "-")
    this.router.navigate([`/${NavigationRoutes.Campaigns}/${page}/${NavigationRoutes.Resume}`]);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }
  openUploads() {
    const dialogRef = this.dialog.open(UploadsComponent , {
      width: '500px'
    })
    dialogRef.afterClosed().subscribe((res)=> {
      console.log(res);


    })
  }




}
