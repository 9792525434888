import { Component, OnInit } from '@angular/core';
import { PageLayoutComponent } from '../../components/page-layout/page-layout.component';
import { CommonModule } from '@angular/common';
import { Router, RouterLink } from '@angular/router';
import { NavigationRoutes } from '../../models/navigation.model';
import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { BasicTableComponent } from '../../components/basic-table/basic-table.component';
import {StoresTableColumns} from '../../models/tables.model'
import { ApiService } from '../../services/api.service';
import { StoresService } from '../../services/stores/stores.service';
import { BasicPaginatorComponent } from '../../components/basic-paginator/basic-paginator.component';
import { PaginatorConfig, StoreSearch } from '../../models/store.model';
import { MatDialog } from '@angular/material/dialog';
import { StoreFieldsComponent } from '../../components/dialogs/store-fields/store-fields.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-punti-vendita',
  standalone: true,
  imports: [RouterLink,PageLayoutComponent, BasicPaginatorComponent, CommonModule, BasicTableComponent, SharedModule, MaterialModule],
  templateUrl: './stores.component.html',
  providers: [],
  styleUrl: './stores.component.scss'
})
export class StoresComponent implements OnInit {
  // displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];
  formControl: any;
  dataType =  StoresTableColumns;
  StoresData = [];
  isReady = false;
  filters = {
    name: '',
    city: '',
    unique_code: '',
    ranking: ''
  }
  configData!: PaginatorConfig;
  constructor(
    private translate: TranslateService,
    private apiSvc: ApiService,
    private router: Router,
    private storeSvc: StoresService,
    public dialog: MatDialog,

    ) {

  }

  ngOnInit(): void {
    this.retrieveData({});
  }
  filterData(ev: any) {
    //console.log(ev);
    const data: any = {};
    for (const key in ev) {
        const element = ev[key];
        if (element !== "") {
          data[key] = element
        }
    }
    const req = {
      param: data
    }
    this.filters = {...this.filters, ...ev},

    this.isReady = false;
    this.retrieveData(req);
  }
  goToItems(page: string , extra?: any) {
    page = page.replaceAll(" " , "-")
    this.router.navigate([`/${NavigationRoutes.Stores}/${page}`] , {
      state: extra
    });
  }

  retrieveData(param: StoreSearch | {}) {
    this.isReady = false;
    this.storeSvc.storeDeepSearch(param).subscribe((res: any) => {
      //console.log(res);
      res.data = this.transformToObjectArray(res.data);
      this.configData = {
        prevUrl : res.prev_page_url,
        nextUrl : res.next_page_url,
        activePage: res.current_page,
        totalPage: res.last_page,
        totalResults: res.total,
        links: res.links
      }
      const details = this.translate.instant('TABLES.details');

      this.StoresData = res.data.map((el:any) => {
        el.internal_status = el.status
        el.details = details
        return el
      });
      this.isReady = true;
    })
  }
  actionReceiver(ev: any) {
    //console.log(ev);
    //goToItems('test' , {data: 'test di prova 1'})"
    this.goToItems(ev.value.name.toLowerCase() + "_" + ev.value.store_id, ev.value)
  }
  transformToObjectArray(data: any): any[] {
    if (Array.isArray(data)) {
      // Se è già un array, lo restituiamo così com'è
      return data;
    } else if (typeof data === 'object' && data !== null) {
      // Se è un oggetto, lo trasformiamo in un array dei suoi valori
      return Object.values(data);
    } else {
      // Se non è né un array né un oggetto, restituiamo un array vuoto o possiamo lanciare un errore
      return [];
    }
  }
  newStore(extra?: any) {
    // this.router.navigate([`/${NavigationRoutes.NewStore}`] , {
    //   state: extra
    // });
    const dialogRef = this.dialog.open(StoreFieldsComponent, {
      data:{},

      restoreFocus: false}
    );
    dialogRef.afterClosed().subscribe((data) => {
      console.log(data);

      if (data) {
        data.images = "[]";
        data.country = "IT";
        data.status = "ACTIVE";
        if (data.pdf_file && data.pdf_file != "") {
          this.apiSvc.uploadPdf(data.pdf_file).subscribe((res: any) => {
            // console.log(res , data);

            data.pdf_file = res.file;
            // console.log(data);
            this.saveNewStore(data)

          })
        } else if (data.pdf_file == "") {
          data.pdf_file = 'null';
          this.saveNewStore(data)
        }else {
          // data.pdf_file = "null";
          // console.log(data);
          this.saveNewStore(data)
        }


      }
    })
  }


  pageChanger(ev: any) {
    const req: StoreSearch = {
      url: ev == 'next'?  this.configData.nextUrl : ( ev == 'prev' ? this.configData.prevUrl : ev ),
    }
    this.retrieveData(req)

  }

  saveNewStore(data: any) {
    this.storeSvc.newStore(data).subscribe((res: any) => {
      //console.log(res);
      this.goToItems(res.name.toLowerCase() + "_" + res.store_id);

    })

  }



}

