<header class="dialog-header d-flex justify-content-between p-3">
 <i class="icon-lf-check"></i> {{'CART.added' | translate}}
  <button class="btn border-0 text-white" mat-dialog-close>
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
  </button>

</header>
<mat-dialog-content class="cart-alert">
  <p>{{'CART.window' | translate}} {{data.displaywindow}}</p>
  <p>{{'CART.cu' | translate}} {{data.cu}}</p>
  <p>{{'CART.type' | translate}} {{data.type}}</p>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center px-4 pb-4">


  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button   class="w-100 btn-new px-5 m-0" (click)="goToCart()">{{'CART.show' | translate}}</button>
</mat-dialog-actions>
