<!-- @if (this.isReady) { -->
  <div class="content container-fluid">

    <div class="lf-card p-0">
      <div class="row justify-content-center align-items-center p-3">
        <div class="col-12 col-md-6 col-lg-4 offset-lg-4">
          <div class="row justify-content-center align-items-center">
            <div class="col-auto px-0">
              <button (click)="changeView('prev')" type="button" title="backward" class="px-0 btn icon-lf-chevron-left"></button>
            </div>
            <div class="col-auto month">
              <span class="ex">{{getCurrentMonthName}} </span>
            </div>
            <div class="col-auto px-0">
              <button (click)="changeView('next')" type="button" title="forward" class="px-0 btn icon-lf-chevron-right"></button>
            </div>

          </div>
        </div>
        <div class="col-12 col-md-6 col-lg-4">
          <div class="row justify-content-end">

            <div class="col-auto lines-data">
              <button class="me-4 btn btn-switch" (click)="goToday()" >{{'CALENDAR.today' | translate}}</button>
              <div class="btn-group" role="group">
                @for(data of timeGapOptions ;track $index ) {
                  <button class="btn btn-switch" (click)="changeData(data.value)" [ngClass]="{'active': timeGap === data.value}">{{data.label}}</button>
                }
                <!-- <button class="btn btn-switch" (click)="changeData('vetrofania')" [ngClass]="{'active': timeGap === 'v'}">Vetrofanie</button>
                <button class="btn btn-switch" (click)="changeData('monitor')" [ngClass]="{'active': timeGap === 's'}">Stampe</button> -->

              </div>

            </div>
          </div>
        </div>

      </div>
      <form [formGroup]="formControl" class="calendar-form">
        <div class="row">
          @for(keypar of formField|keyvalue;track keypar.key)
            {
              @if (keypar.key == 'store') {
                <div class="col-auto d-flex align-items-center mb-2">

                  <label for="{{keypar.key}}">{{'TABLES.'+ keypar.key | translate}}</label>
                  <mat-form-field class="multiple-field">

                    <input type="text"
                           aria-label="Number"
                           matInput
                           formControlName="store"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                      @for (option of filteredOptions | async; track option) {
                        <mat-option  [value]="option.name">{{option.name}}</mat-option>
                      }
                    </mat-autocomplete>

                    </mat-form-field>
                </div>

              } @else if (keypar.key == 'city') {
                <div class="col-auto d-flex align-items-center mb-2">
                  <label for="{{keypar.value}}">{{'TABLES.'+ keypar.key | translate}}</label>
                  <mat-form-field class="multiple-field">

                    <input type="text"
                           aria-label="Number"
                           matInput
                           formControlName="city"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                      @for (option of filteredCity | async; track option) {
                        <mat-option  [value]="option.name">{{option.name}}</mat-option>
                      }
                    </mat-autocomplete>

                            </mat-form-field>

                </div>

              } @else {
                <div class="col-auto d-flex align-items-center mb-2">
                  <label for="{{keypar.value}}">{{'TABLES.'+ keypar.key | translate}}</label>
                  <input id="{{keypar.value}}" class="form-control" [title]="keypar.key" matInput [formControlName]="keypar.key">

                </div>

              }
          }
          <div class="col text-end d-flex align-items-center mb-2">
            <button class="btn-new" (click)="sendFilters()" type="submit">{{'ACTIONS.search' | translate}}</button>
          </div>

        </div>


      </form>
      <div class="d-block overfolw-x-auto">
@if (isReady) {
  <table class="mat-elevation-z8 scheduler {{'table-' + timeGap}}" >
    <thead>

      <th  mat-header-cell class="title-head">
        <h3>{{'TABLES.store' | translate}}</h3>
          <span>& {{'TABLES.display_window' | translate}}</span>
        </th>
      <th class="mini" mat-header-cell></th>
      <!-- <th> -->

        @if (isVeryReady && tableData.length > 0 ) {
          @for (item of tableData[0].store.display_window_id[0].period.keys ; track $index ) {
          <th class="fix-w">
            <h3>{{item}}</h3>
          </th>
          }

        }
        @if (isVeryReady && tableData.length === 0 ) {
          {{'GENERAL.no_data' | translate}}
        }

      <!-- </th> -->



    </thead>
    <tbody>
      @for (t of tableData; track $index) {
      <tr>
        <th mat-cell class="site-name ">
          <h2>
            {{t.store.store}}

          </h2>
        </th>

        <th mat-cell>
          <table>
            @for (w of t.store.display_window_id; track $index) {
            <tr>
              <td>
                <p>{{w.id}}</p>
              </td>
            </tr>
            }

          </table>
        </th>

          @for (item of t.store.display_window_id; track $index) {
          <tr class="nested-body">
            @if (isVeryReady) {
              @for (r of item.period.values ; track $index) {

                @if (r.length > 0 ) {
                  <th mat-cell class="fix-w" [style]="'background-color:' + newClassesCss[r[0].product] ">
                    <p>{{r[0].product}}</p>
                  <!-- @for (y of r.value; track $index) {
                    <tr>
                      <th [style]="'background-color:' + newClassesCss[r.value[0].product]">
                        <p>{{y.product}}</p>
                      </th>
                    </tr>

                 } -->

                </th>

                } @else {
                  <th mat-cell class="fix-w" >
                    <p></p>

                  </th>
                }




              }

            }
          </tr>

      }

      </tr>
      }

    </tbody>

  </table>

}
      </div>
    </div>
  </div>

<!-- } -->


<!-- @for (item of tableData; track $index) {
    <div class="row">
      <div class="col-auto"></div>

    </div>
    {{item.store.store}}
  } -->
