<div class="content container-fluid">
  <div class="row">
      @if (isReady) {
        <div class="lf-card col-12 px-0">
          <div class="d-flex justify-content-end button-wrapper">
            <button (click)="newStore()" type="button" title="options" class="btn-new" >
              {{'BUTTONS.new_store' | translate}}
            </button>

          </div>
          <app-basic-table
          [showPaginator]="true"
          [isMultipleFilter]="true"
          [formField]="filters"
          [tableData]="StoresData"
          [displayedColumns]="dataType"
          (action)="actionReceiver($event)"
          (filters)="filterData($event)"
          >
          <div class="mt-4">
            @if (StoresData.length == 0) {
              <div class="w-100 text-center">{{'GENERAL.no_data' | translate}}</div>
            }
            <basic-paginator [config]="configData" (paginator)="pageChanger($event)"></basic-paginator>
          </div>
        </app-basic-table>
        </div>

      }

  </div>
</div>

