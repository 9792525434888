import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { LoaderComponent } from '../../components/loader/loader.component';
import { NavigationRoutes } from '../../models/navigation.model';
import { FormGroup, FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { LoginRequest } from '../../models/api.models';
import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { TranslateService } from '@ngx-translate/core';
import { GlobalSettings as GS } from '../../global.settings';
import { CommonHeaderComponent } from '../../components/common-header/common-header.component';
@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [LoaderComponent, CommonHeaderComponent, SharedModule, MaterialModule],
  templateUrl: './auth.component.html',
  styleUrl: './auth.component.scss'
})
export class AuthComponent implements OnInit {

  constructor(private auth: AuthService,
    private router: Router,
    public api: ApiService,
    private translate: TranslateService
  ) {

  }
  inputType: "text" | "password" = "password";
  hasForgotPsw: boolean = false;

  ngOnInit(): void {

  }
  changeVisibility() {
    this.inputType = this.inputType == "password" ? "text" : "password"
  }
  profileForm = new FormGroup({
    user: new FormControl('' , Validators.compose([
      Validators.required,
      Validators.pattern(GS.email_pattern)    ]) ),
    password: new FormControl('', Validators.required),
  });

  login() {
    if (this.hasForgotPsw === true) {
      const req = {email:this.profileForm.controls.user.value +""};
      this.auth.forgotPassword(req).subscribe((res) => {

      })
    } else {
      const req: LoginRequest = {
        email: this.profileForm.controls.user.value +"",
        password: this.profileForm.controls.password.value+"",
      }

      this.auth.login(req).subscribe((res) => {
        this.api.getAllProducts();
          this.router.navigateByUrl(NavigationRoutes.HomePage);
      });

    }

  }
  forgetPassword() {
    this.hasForgotPsw = true;
    this.profileForm.controls.password.setValue(" ")
  }
}
