import { Component, OnInit } from '@angular/core';
import { CampaignsTableComponent } from '../../../components/campaigns-table/campaigns-table.component';
import { CampaignsService } from '../../../services/campaigns/campaigns.service';
import { BasicPaginatorComponent } from '../../../components/basic-paginator/basic-paginator.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Cart } from '../../../models/api.models';
import { CampaignsResumeTableColumns } from '../../../models/tables.model';
import { concatMap, delay, from, map, of, tap } from 'rxjs';
import { SharedModule } from '../../../modules/shared.module';
import { MaterialModule } from '../../../modules/material.module';
import { StoreSearch } from '../../../models/store.model';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../../../components/dialogs/confirm/confirm.component';
import { ApiService } from '../../../services/api.service';
import { SaveCampaignComponent } from '../../../components/dialogs/save-campaign/save-campaign.component';
import { NavigationRoutes } from '../../../models/navigation.model';
import { MassiveChangeComponent } from '../../../components/dialogs/massive-change/massive-change.component';

@Component({
  selector: 'app-campaing-cart',
  standalone: true,
  imports: [CampaignsTableComponent, BasicPaginatorComponent, SharedModule, MaterialModule],
  templateUrl: './campaing-cart.component.html',
  styleUrl: './campaing-cart.component.scss'
})
export class CampaingCartComponent implements OnInit {
  insertedProducts: any[] = [];
  windowType: {[key: string]: any[]} = {"": []};
  cart!: Cart;
  id: any;
  isReady = false
  detailsData : any[] = []
  columns = CampaignsResumeTableColumns
  campName: string | undefined;
  status: string = "";
  fields: {[key: string ]:any} = {store: "" , city: "", unique_code: ""}
  configData: any;
  activeFilters: any[] = [];
  StaticProducts: any[] = [];
  filterConcession: {
    company: string | null,
    brand_id: string | null,
    product_id: any,
    brand: string | null
  } = {
    company : null,
    brand_id : null,
    product_id: null,
    brand: null
  }
constructor(private dialog: MatDialog,
  private campSvc: CampaignsService,
  private ar: ActivatedRoute,
  private cmpSvc: CampaignsService,
  private apiSvc: ApiService,
private router: Router) {
  this.id = this.ar.snapshot.paramMap.get('id')?.split('_')[1]  ;
  this.campName = this.ar.snapshot.paramMap.get('id')?.split('_')[0]  ;

}
  ngOnInit(): void {
    // this.retrieveData();
    this.getDetails();
    this.campSvc.getCampaignStaus(this.id).subscribe((res: any) => {

      this.status = res.status;

      this.insertedProducts = res.campaign_details.map((el: any) => {
        return {
          id: el.products[0].product_id,
          name: el.products[0].name,
        }
      }).filter((value: any, index: number, self: any) =>
        index === self.findIndex((t: any) => t.id === value.id)
      );
      // this.inputControl(res);
      this.isReady = true;
      if (res.campaign_details.length > 0) {
        this.filterConcession.product_id = {name:res.campaign_details[0].products[0].name, id: res.campaign_details[0].products[0].product_id}
        this.apiSvc.getProductsByBrandID(res.campaign_details[0].products[0].brand_id).subscribe((res: any) => {

          this.StaticProducts = res;

        })

      }

    })
  }
  setChips(ev: any) {

    const hasKey = this.activeFilters.filter(((el: any) => el.type == ev.type));
      if (hasKey.length > 0) {
        this.activeFilters = this.activeFilters.map((el:any) => {

          if (el.type == ev.type && ev.type != 'city' && ev.type != 'store') {
            el.name = ev.name
          }
          return el
        })
        if (ev.type == 'city' || ev.type == 'store') {
          this.activeFilters.push({type: ev.type, name: ev.name});

        }

      } else {
        this.activeFilters.push({type: ev.type, name: ev.name});

      }
    // this.activeFilters = ev;


  }
  setValues(ev: any) {
    const name : "company" | "brand_id" | "product_id" = ev.name;
    this.filterConcession[name] = ev.value == null ? null : ev.value[name]
    if (name == "product_id" &&  ev.value !== null) {
      this.filterConcession[name] = { name: ev.value.name, id: ev.value[name] }
    }
    if (name == "brand_id" &&  ev.value !== null) {
      this.filterConcession.brand = ev.value.name
    }


  }
  getDetails(extra?:any) {
    this.isReady = false
    const req: StoreSearch = {
      param: {
        campaign_id: this.id
      }
    }
    if (extra) {
      req.param = {...req.param, ...extra}
    }
    this.cmpSvc.searchCampaigns(req).subscribe((res: any) => {
      //console.log(res);
      res.data = res.data.filter((el: any) => el.product);
      if (res.data.length > 0) {
        this.detailsData = res.data.map((el:any) => {
          //console.log(el);
          this.configData = {
            prevUrl : res.prev_page_url,
            nextUrl : res.next_page_url,
            activePage: res.current_page,
            totalPage: res.last_page,
            totalResults: res.total,
            links: res.links
          }
          el.availability = JSON.parse(el.availability)?.months;
          el.realAvailability = el.availability
          el.used_at = JSON.parse(el.used_at)?.months
           el.crud = {"icon-edit": "icon-lf-edit","icon-delete": "icon-lf-delete" }
          // el.crud = {"icon-delete": "icon-lf-delete" }
          return el
        })
        this.detailsData.forEach((el: any) => {
          if (!Array.isArray(this.windowType[el.type?.toLowerCase()])) {
            this.windowType[el.type?.toLowerCase()] = [];
          }
          this.windowType[el.type?.toLowerCase()].push(el)
        })

      }
      this.isReady = true;
      // this.detailsData = this.detailsData.filter(el => el.internal_status != 'PUBLISHED')

    })
  }
  refresh() {
    this.isReady = false;
    this.getDetails();
  }
  saveAs(param : 'draft' | 'new') {
    const data = this.detailsData.map((el:any) => {
      //console.log(el);

      el.used_at = el.used_at.map((el: string) => {
        el.replaceAll("-" , "/")
        return el;
      })
      const used_at = JSON.stringify({months: el.used_at})
      return {
        campaign_id : +el.campaign_id,
        product_id : el.product_id,
        display_window_id : el.display_window_id,
        used_at : used_at,
      }
    })
    if(param == 'draft') {
      //console.log(data);
      this.saveDetails()

    } else {
      const dialog = this.dialog.open(SaveCampaignComponent , {
        data: {
          windowType: this.windowType,
          campName: this.campName,
          status: this.status
        }
      });
      dialog.afterClosed().subscribe((res) => {
        if (res === 'confirm') {
          this.saveDetails('PUBLISHED');
          this.router.navigate([NavigationRoutes.Campaigns])

        }
      })
    }
  }
  openMassiveChange() {
    const deltedialogRef = this.dialog.open(MassiveChangeComponent, {
      data: {
        params: this.insertedProducts,
        campaign_id: this.id

      },


      restoreFocus: false}
    );

    deltedialogRef.afterClosed().subscribe((res)=> {
      if (res === "change") {


        this.ngOnInit();

      }

    })
  }
  saveDetails( status: string = 'DRAFT' ) {
    const req = {
      name: this.campName,
      status: this.status,
      internal_status: status,
    }
    this.campSvc.updateCampaignStaus(req, this.id).subscribe((res) => {
      //console.log(res);
      this.searchParam('')
      // this.cart[+this.id] = [];
      // localStorage.setItem('cart' , JSON.stringify(this.cart) )

    })
    // from(params).pipe(
    //   concatMap(param =>
    //     of(param).pipe(
    //       delay(500),
    //       tap((res) => //console.log(res)
    //       ),
    //       concatMap(p => this.campSvc.insertCampaignDetail(p))
    //     )
    //   )
    // ).subscribe({
    //   next:(res) => {
    //   //console.log(res);
    //   },
    //   error: (err: any) => {
    //     //console.log(err);

    //   }

    // }

    // )
  }

  transformToObjectArray(data: any): any[] {
    if (Array.isArray(data)) {
      // Se è già un array, lo restituiamo così com'è
      return data;
    } else if (typeof data === 'object' && data !== null) {
      // Se è un oggetto, lo trasformiamo in un array dei suoi valori
      return Object.values(data);
    } else {
      // Se non è né un array né un oggetto, restituiamo un array vuoto o possiamo lanciare un errore
      return [];
    }
  }

  formatClass(el: string) {
    return el.toLowerCase().replaceAll(" " , "-")
  }

  actionReceiver(ev: any) {
    //console.log(ev);
    if (ev.action == "icon-delete") {
      const deltedialogRef = this.dialog.open(ConfirmComponent, {


        restoreFocus: false}
      );

      deltedialogRef.afterClosed().subscribe((res)=> {
        //console.log(res);
        if (res === "save") {
          this.isReady = false;
          this.campSvc.deleteCampaignDetail(ev.value.campaign_detail_id).subscribe((res) => {
            this.getDetails()

          })

        }

    })


    }
  }
  pageChanger(ev: any) {
    const req: StoreSearch = {
      url: ev == 'next'?  this.configData.nextUrl : ( ev == 'prev' ? this.configData.prevUrl : ev ),
    }
    this.getDetails(req)

  }
  searchParam(ev: any) {


    const data: any = {};
    this.activeFilters.forEach((element: any) => {
      if (element.type == "city") {
        if (!Array.isArray(data['city'])) {
          data['city'] = [];

        }
        data['city'].push(element.name)
      } else if (element.type == "store") {
        if (!Array.isArray(data['store'])) {
          data['store'] = [];
        }
        data['store'].push(element.name)
      } else {
        data[element.type] = element.name
      }
    });
    let param = {
      campaign_id: this.id,
      ...data
    }


    this.getDetails(param)

    // this.fields = {...ev}
    //console.log(ev);
  }
  removeFilters() {
    this.activeFilters = [];
  }
  removeOption(option: any) {

    this.activeFilters = this.activeFilters.filter((el: any) => {
      return el.name !== option.name
    })

    // this.selectedStore = this.selectedStore.filter(item => item.name !== option);
    // this.formControl.get('store').setValue(this.selectedStore);
  }
}
