
<div class="list-content">
  <div class="sticky">
    <h3>{{'PAGES.Avvisi' | translate}}</h3>
    <p>{{'ALERTS.last_warnings' | translate}}</p>

    <ng-content>
    </ng-content>
  </div>

    <div class="container-fluid list-row">

    @for (item of apiAlerts; track $index) {
      <div class="row list">
        <div class="col-auto ps-0">
          <i class="icon-lf-alert"></i>
        </div>
        <div class="col ps-0">
          <h4>{{item.store}}</h4>
          @if (item.display_window) {
            <span class="info">{{item.display_window}}</span> <br>
          }
          <span class="info">{{item.description}}</span>
        </div>
      </div>
    }

      <div class="row mt-auto">
        <div class="col text-center">
          <a class="cta-ul" (click)="goToMainPage()" >{{'MAIN.SEE_ALL'| translate}}</a>
        </div>
      </div>

    </div>

</div>
