<div class="carousel-container">
  <div class="carousel">
    @for (image of imageData; track $index) {
      <div class="carousel-item {{size}}"   [class.active]=" $index === currentIndex">
        <img [src]="image.url || placeImg" alt="Image {{$index + 1}}">
        @if (mode === "editable") {
          <button type="button" class="delete btn border-0 text-white" (click)="emitAction('delete', image)" >
            <mat-icon aria-hidden="false" aria-label="delete" fontIcon="close"></mat-icon>

          </button>

        }
      </div>

    }

  </div>
  <div class="thumbnails">

    @for (image of imageData; track $index) {
      <div class="position-relative">
        <img  [src]="image.url || placeImg" alt="Thumbnail {{$index + 1}}"
              [class.active]="$index === currentIndex" (click)="selectImage($index)">
              @if (mode === "editable") {
                <button type="button" class="delete btn border-0 text-white" (click)="emitAction('delete', image)" >
                  <mat-icon aria-hidden="false" aria-label="delete" fontIcon="close"></mat-icon>

                </button>

              }

      </div>
    }
    @if (mode === "editable") {
      <div class="mx-2 d-flex add-image flex-column align-items-center justify-content-center">
        <button type="button" (click)="emitAction('add')" class=" btn d-flex flex-column align-items-center">
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="add_circle_outline"></mat-icon>

          {{'STORES.add_image' | translate}}

        </button>

      </div>
    }
  </div>
</div>
