import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import { catchError, tap } from 'rxjs';
import { StoreSearch } from '../../models/store.model';

@Injectable({
  providedIn: 'root'
})
export class CampaignsService {

  constructor(private http: HttpClient) { }

  currentCartId: (string | null) = null;

  getHPCampaigns(param: string) {
    return this.http.get(ENV.api.BASE_URL + ENV.api.endpoints.campaigns + ENV.api.endpoints.dashboard + param).pipe(
      tap((res: any) => {
        //console.log(res);

      })
    )
  }
  getHPGraph(param: string) {
    return this.http.get(ENV.api.BASE_URL + ENV.api.endpoints.campaigns + ENV.api.endpoints.dashboard + ENV.api.endpoints.graph + param).pipe(
      tap((res: any) => {
        //console.log(res);

      })
    )
  }

  searchCampaigns(req: StoreSearch) {
    // const headers = new HttpHeaders(req.param);
    return this.http.post(req.url? req.url : ENV.api.BASE_URL + ENV.api.endpoints.campaigns + ENV.api.endpoints.search + (req.page ? req.page : ""), req.param)
  }
  getCalendar(req: string , param = {}) {
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.campaigns + ENV.api.endpoints.calendar + req  , param)
  }

  getAllWindows(req: StoreSearch) {
    return this.http.post(req.url? req.url : ENV.api.BASE_URL + ENV.api.endpoints.windows + ENV.api.endpoints.search + (req.page ? req.page : ""), req.param)
  }

  newCampaign(req:any ) {
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.campaigns, req)
  }

  getCampaignDetail(id: string) {
    return this.http.get(ENV.api.BASE_URL + ENV.api.endpoints.campaign_detail + "/" + id  )
  }
  deleteCampaignDetail(id: string) {
    return this.http.delete(ENV.api.BASE_URL + ENV.api.endpoints.campaign_detail + "/" + id  )
  }
  putCampaignDetail(params: any) {
    return this.http.put(ENV.api.BASE_URL + ENV.api.endpoints.campaign_detail , params  )
  }
  insertCampaignDetail(params: any) {
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.campaign_detail , params  )
  }
  getCampaignStaus(id: string) {
    return this.http.get(ENV.api.BASE_URL + ENV.api.endpoints.campaigns + "/" + id  )
  }
  updateCampaignStaus(params: any, id: string) {
    return this.http.put(ENV.api.BASE_URL + ENV.api.endpoints.campaigns + "/" + id, params  )
  }

  editCampaign(req: StoreSearch ) {
    return this.http.post(req.url? req.url : ENV.api.BASE_URL + ENV.api.endpoints.campaigns + "/" + req.param +  ENV.api.endpoints.edit + (req.page ? req.page : ""), req.search)
  }

  bulkUpdate(req: any) {
    return this.http.post( ENV.api.BASE_URL + ENV.api.endpoints.bulk_update , req)
  }

  get cartId() {
    return this.currentCartId
  }

  set cartId(val: string | null) {
    this.currentCartId = val;
  }




}
