import { Component } from '@angular/core';
import { PageLayoutComponent } from '../../components/page-layout/page-layout.component';
import { InsertDataTableComponent } from '../../components/insert-data-table/insert-data-table.component';
import { BasicTableComponent } from '../../components/basic-table/basic-table.component';
import { AlertsDetailTableColumns } from '../../models/tables.model';
import { MatDialog } from '@angular/material/dialog';
import { AlertsService } from '../../services/alerts/alerts.service';
import { SharedModule } from '../../modules/shared.module';
import { NewAlertComponent } from '../../components/dialogs/new-alert/new-alert.component';
import { ImageCarouselComponent } from '../../components/dialogs/image-carousel/image-carousel.component';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { NavigationRoutes } from '../../models/navigation.model';
import { BasicPaginatorComponent } from '../../components/basic-paginator/basic-paginator.component';
import { PaginatorConfig, StoreSearch } from '../../models/store.model';
import { ConfirmComponent } from '../../components/dialogs/confirm/confirm.component';

@Component({
  selector: 'app-avvisi',
  standalone: true,
  imports: [PageLayoutComponent, BasicTableComponent, SharedModule,BasicPaginatorComponent],
  templateUrl: './alerts.component.html',
  styleUrl: './alerts.component.scss'
})
export class AlertsComponent {
  isReady = false;
  configData!: PaginatorConfig;
  alertsData = [];
  columns = AlertsDetailTableColumns;
  filters = {
    store: '',
    description: '',
    person_in_charge: '',
    // campaign: '',
    status: ''
  }
  constructor(
    public dialog: MatDialog,
    private translate: TranslateService,
    private alertSvc: AlertsService,
    private router: Router
  ) {}

  ngOnInit(): void {
      this.retrieveData({});
  }

  actionReceiver(ev: any) {
    //console.log(ev);
    switch (ev.action) {
      case "icon-image":
        const images = JSON.parse(ev.value.images);
        //console.log(images);

        const dialogRef = this.dialog.open(ImageCarouselComponent, {
          data: images,
          width: '80%',

          restoreFocus: false}
        );

        break;
      case "detail":
        this.goToItems(ev.value.alert.toLowerCase() +"_" + ev.value.alert_id );
        break
      case "icon-edit":
        this.goToItems(ev.value.alert.toLowerCase() +"_" + ev.value.alert_id , 'edit');
        break;
        case "icon-delete":
          const deltedialogRef = this.dialog.open(ConfirmComponent, {


            restoreFocus: false}
          );

          deltedialogRef.afterClosed().subscribe((res)=> {
            //console.log(res);
            if (res === "save") {
              this.alertSvc.deleteAlert(ev.value.alert_id).subscribe(() => {
                this.retrieveData({});
              })

            }

          })
        break;
      default:
        break;
    }

  }
  goToItems(page: string , extra?: any) {
    page = page.replaceAll(" ", "-");
    this.router.navigate([`/${NavigationRoutes.Alert}/${page}`] , {
      state: extra
    });
  }
  newAlert() {

    const dialogRef = this.dialog.open(NewAlertComponent, {
      data: {
        city: "",
        store: "",
        display_window_id : ""
      },
      width: '80%',

      restoreFocus: false}
    );
  }
  filterData(ev: any) {
    //console.log(ev);

    const data: any = {};
    for (const key in ev) {
        const element = ev[key];
        if (element !== "") {
          data[key] = element
        }
    }
    const req = {
      param: data
    }
    this.filters = {...this.filters, ...ev},
    this.retrieveData(req);
  }


  retrieveData(param: any) {
    this.isReady = false;

    this.alertSvc.searchAlert(param).subscribe((res: any) => {
      this.configData = {
        prevUrl : res.prev_page_url,
        nextUrl : res.next_page_url,
        activePage: res.current_page,
        totalPage: res.last_page,
        totalResults: res.total,
        links: res.links
      }
      const details = this.translate.instant('TABLES.details');

      this.alertsData = res.data.map((el:any) => {
        el.details = details;
        el.crud ={"icon-delete": "icon-lf-delete", "icon-edit": "icon-lf-edit icon-disabled", "icon-image": "icon-lf-image icon-disabled" }
        return el
      })
      this.isReady = true;
    })
  }
  pageChanger(ev: any) {
    const req: StoreSearch = {
      url: ev == 'next'?  this.configData.nextUrl : ( ev == 'prev' ? this.configData.prevUrl : ev ),
    }
    this.retrieveData(req)
  }
}
