import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PageLayoutComponent } from './components/page-layout/page-layout.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from './modules/shared.module';
import { ApiService } from './services/api.service';
import { AuthService } from './services/auth.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, CommonModule, PageLayoutComponent, SharedModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'LaFarmacia';
  constructor(private apiSvc: ApiService, private auth: AuthService) {

  }
  ngOnInit(): void {
    if (this.auth.getAuth) {
      this.apiSvc.getAllProducts();

    }
      //console.log(ENV.mode);
  }

}
