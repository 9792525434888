import { Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { InsertDataTableComponent } from '../../insert-data-table/insert-data-table.component';
import { CampaignsService } from '../../../services/campaigns/campaigns.service';

@Component({
  selector: 'app-massive-change',
  standalone: true,
  imports: [MaterialModule, SharedModule, InsertDataTableComponent],
  templateUrl: './massive-change.component.html',
  styleUrl: './massive-change.component.scss'
})
export class MassiveChangeComponent implements OnInit {

  added: any[] = [];
  data: any[] = [];
  constructor(
    private campSvc: CampaignsService,
    @Inject(MAT_DIALOG_DATA) public inputData: { campaign_id: string , params:{id: number , name: string , ischecked: boolean}[] } = {campaign_id: "" , params: []},
    public dialogRef: MatDialogRef<MassiveChangeComponent>,
  ) {

  }

  ngOnInit(): void {
    console.log(this.inputData);
    this.data = this.inputData.params;
  }
  idSelected(ev: any, ischecked: boolean, item: any) {
    console.log(ev, ischecked );
    // if (ischecked) {
      ev.old_id = item.id;
      ev.old_name = item.name;

      const hasSameId = this.added.filter(el => el.old_id == ev.old_id)
      hasSameId.length == 0 && this.added.push(ev)
      this.data = this.data.filter(el => el.id != item.id)
    // }

  }
  startChange() {

    const oldIds = this.added.map(el => +el.old_id)
    const newIds = this.added.map(el => +el.product_id)
    const req = {
      campaign_id: this.inputData.campaign_id,
      products_old: oldIds,
      products_new: newIds
    }

    this.campSvc.bulkUpdate(req).subscribe({
      next: (value) => {
        console.log(value);
        this.dialogRef.close('change')
      },
      error: (err) => {
        console.log(err);
      },
      complete: () => {
        console.log('completed');
        this.dialogRef.close('change')
      }

    })
  }
}
