import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Observable, from, of } from 'rxjs';
import { map, startWith, tap } from 'rxjs/operators';

import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { StoresService } from '../../../services/stores/stores.service';

@Component({
  selector: 'app-new-campaign-details',
  standalone: true,
  imports: [MaterialModule, SharedModule],
  templateUrl: './new-campaign-details.component.html',
  styleUrl: './new-campaign-details.component.scss'
})
export class NewCampaignDetailsComponent implements OnInit {
  selectedProductId: any;
  dataSaved: boolean = false;

  constructor(private storeSvc: StoresService) {

  }

  @Input() item: string = ""
  @Input() availableProducts: any[] = [];
  @Input() selectedProduct: any | null = "";
  @Input() element: any  = {};
  @Output() values = new EventEmitter<any>()
  @Input()data: any[] = [];

  companySelected: boolean = false;
  brandSelected: boolean = false;
  productSelected: boolean = false;

  formValid = false;
  form!: FormGroup

  filteredCompanies!: Observable<string[]>;
  filteredBrands!: Observable<string[]>;
  filteredProducts!: Observable<any[]>;
  myControl!: FormControl;
  filteredStaticProducts!: Observable<any[]>;
  isReady = false
  ngOnInit() {
    // console.log(this.selectedProduct);
    if (this.selectedProduct) {

      const val =  {
        valid: true,
        product_id: this.selectedProduct.id,
        display_window_id : this.element.display_window_id,
      }
      this.values.emit(val)
    }
    this.myControl = new FormControl(this.selectedProduct?.name);
    this.form = new FormGroup({
      company: new FormControl(this.element.company),
      brand: new FormControl(this.element.brand),
      product: new FormControl(this.element.product)
    });
    this.form.get('brand')?.disable();
    // this.form.get('product')?.disable();
    if (!this.element.isNew) {
      this.form.get('company')?.disable();
    }


    this.myControl.valueChanges.subscribe(value => {
      this.filteredStaticProducts.subscribe((res) => {
        //console.log(res, value);
        const f = res.filter(el => el.name === value)
        if (f.length > 0) {
          const id = res.filter(({name}) => name === value ).map(el => el.id)
          const val =  {
            valid: true,
            product_id:id[0],
            display_window_id : this.element.display_window_id,
          }
          this.values.emit(val)

        } else {
          const val =  {
            valid: false,

            display_window_id : this.element.display_window_id,
          }
          this.values.emit(val)
        }

      })
      //console.log(value , this.selectedProduct, this.filteredStaticProducts);


    });
    // else {
    //   this.form.get('product')?.disable();

    // }
    if (this.element.company) {
      this.form.get('company')?.disable();
    }
    if (this.element.product) {
      this.form.get('product')?.disable();
    }

    if (this.availableProducts.length > 0) {
      this.availableProducts = this.availableProducts.map((el: any) => {
        return {
          name: el.product,
          id: el.product_id
        }
      })

      this.filteredStaticProducts = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value)),
      );
    }
    // this.sta rtSearch();
    this.isReady = true;
  }

  private _filterCompanies(value: string): string[] {
    value += "";
    const filterValue = value.toLowerCase();
    return [...new Set(this.data.map(item => item.company))]
      .filter(option => option.toLowerCase().includes(filterValue));
  }
  private _filter(value: any): string[] {

    return this.availableProducts.filter(option => option.name.toLowerCase().includes(value));
  }

  private _filterBrands(value: string): string[] {
    value += "";
    const selectedCompany = this.form.get('company')!.value;
    if (!selectedCompany) {
      return [];
    }
    const filterValue = value.toLowerCase();
    return [...new Set(this.data
      .filter(item => item.company === selectedCompany)
      .map(item => item.brand))]
      .filter(option => option.toLowerCase().includes(filterValue));
  }

  private _filterProducts(value: string): {id: number, name: string}[] {
    value += "";
    const selectedCompany = this.form.get('company')!.value;
    const selectedBrand = this.form.get('brand')!.value;
    if (!selectedCompany || !selectedBrand) {
      return [];
    }
    const filterValue = value.toLowerCase();
    return [...new Set(this.data
      .filter(item => item.company === selectedCompany && item.brand === selectedBrand)
      .map(item => ({id: item.product_id, name: item.product})))]
      .filter(option => option.name.toLowerCase().includes(filterValue));
  }

  startSearch() {
    if (this.dataSaved || !this.element.isNew || (this.element.isNew && this.element.internal_status == 'DRAFT') ) return
    this.storeSvc.getCompaniesPerDisplayWindow(this.element.display_window_id).subscribe((res: any) => {
      this.data = res ;//as any[];
      this.filteredCompanies = this.form.get('company')!.valueChanges.pipe(
      startWith(''),
      tap((i) => {

            if (i) {
              this.form.get('brand')?.enable()
            } else {
              this.form.get('company')?.setErrors({ 'invalid': true });
              this.form.get('brand')?.disable()
            }

          }),
      map(value => this._filterCompanies(value))
      );
      this.filteredCompanies.subscribe(() => {

        this.form.get('brand')?.reset();
        this.form.get('product')?.reset();
      });

      this.form.get('company')!.valueChanges.subscribe(() => {
        this.form.get('brand')!.setValue('');
        this.form.get('product')!.setValue('');
        this.filteredBrands = this.form.get('brand')!.valueChanges.pipe(
          startWith(''),
          tap((i) => {

                if (i) {
                  this.form.get('product')?.enable()
                } else {
                  this.form.get('brand')?.setErrors({ 'invalid': true });
                  this.form.get('product')?.disable()

                }

              }),
          map(value => this._filterBrands(value))
        );
        this.filteredBrands.subscribe(() => {
          this.form.get('product')!.reset();
        });
      });

      this.form.get('brand')!.valueChanges.subscribe(() => {
        this.form.get('product')!.setValue('');
        this.filteredProducts = this.form.get('product')!.valueChanges.pipe(
          startWith(''),
          map(value => this._filterProducts(value))
        );


      });
      this.form.get('company')!.setValidators(this.validateCompany());
      this.form.get('brand')!.setValidators(this.validateBrand());

      this.form.get('product')!.valueChanges.subscribe(value => {
        this.validateProduct(value);
      });
      this.dataSaved = true;
    })
  }
  private validateProduct(value: string) {
    const selectedCompany = this.form.get('company')!.value;
    const selectedBrand = this.form.get('brand')!.value;
    const product = this.data.find(item =>
      item.company === selectedCompany &&
      item.brand === selectedBrand &&
      item.product === value
    );
    if (product) {
      this.selectedProductId = product.product_id;
      this.form.get('product')!.setErrors(null); // No errors
      //console.log(this.selectedProductId , 'this.selectedProductId' );

      const val =  {
        valid: true,
        product_id: this.selectedProductId,
        display_window_id : this.element.display_window_id,
        company: selectedCompany,
        brand: selectedBrand,
        product: value,
      }
      this.values.emit(val)
    } else {
      this.selectedProductId = null;
      this.form.get('product')!.setErrors({ invalid: true }); // Set error
      const val =  {
        valid: false,

        display_window_id : this.element.display_window_id
      }
      this.values.emit(val)
    }
  }
  private validateCompany()  {
    return (control: any) => {
      const value = control.value;
      const companyExists = this.data.some(item => item.company === value);
      return companyExists ? null : { invalid: true };
    };
  }

  private validateBrand() {
    return (control: any) => {
      const selectedCompany = this.form.get('company')!.value;
      const value = control.value;
      if (!selectedCompany || !value) {
        return null;
      }
      const brandExists = this.data.some(item =>
        item.company === selectedCompany &&
        item.brand === value
      );
      return brandExists ? null : { invalid: true };
    };
  }

  submit() {


  }

  close() {
    this.values.emit('discard')
  }
}
