<div class="content container-fluid campaign-manager">
  <div class="lf-card p-0">

    @if (isReady) {


      <form [formGroup]="formFields" class="container-fluid ps-3 py-3 bg-white" (ngSubmit)="formSubmit()">
        <div class="row">
          <div class="col-auto ps-0 align-self-center">
            <div class="d-flex align-items-center ps-2">
              <mat-label class="ps-3">{{'GENERAL.name' | translate}}:</mat-label>
              <mat-form-field >

                <input id="alert-name" placeholder="{{'GENERAL.name' | translate}}" formControlName="name"  title="input" matInput >
                @if (formFields.get('name')?.hasError('required')) {
                  <mat-error>{{'STORES.required_field' | translate}}</mat-error>
                }
              </mat-form-field>
              <div class="btn-ph" style="width: 64px;">
                <button [hidden]="mode == 'editable'" type="button" title="edit" mat-button (click)="editMode()" >
                  <i class="icon-lf-edit icon-small"></i>
                </button>

              </div>

            </div>

          </div>
          <div class="col-auto d-flex align-items-center align-self-center" style="height: 48px;">
            <mat-label>{{'CAMPAIGNS.status' | translate}}</mat-label>
            <mat-form-field class="status" [class]="formatClass()">
              <mat-select  name="status" id="" class="status" formControlName="status"  matInput title="status" >
                <!-- <mat-option [value]="'STATUS.ok' | translate">{{'STATUS.ok' | translate | titlecase}}</mat-option> -->
                <!-- <mat-option [value]="'STATUS.to_confirm' | translate">{{'STATUS.to_confirm' | translate | titlecase}}</mat-option> -->
                <mat-option [value]="'STATUS.pending' | translate">{{'STATUS.pending' | translate | titlecase}}</mat-option>
                <mat-option [value]="'STATUS.closed' | translate">{{'STATUS.closed' | translate | titlecase}}</mat-option>
                <mat-option [value]="'STATUS.in_progress' | translate">{{'STATUS.in_progress' | translate | titlecase}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>
          <div class="col text-end">
            <button [hidden]="mode !== 'editable'" type="button" class="btn-new my-0" title="edit" (click)="saveData()" >
              {{'ACTIONS.save' | translate}}
            </button>
          </div>


          <!-- <div class="col-auto d-flex align-items-center">
            <mat-label>Internal Status:</mat-label>
            <mat-form-field class="status" [class]="formFields.get('internal_status')?.value ">
              <mat-select  name="internal_status" id="" class="status" formControlName="internal_status"  matInput title="internal_status" >
                <mat-option [value]="'OK'">Ok</mat-option>
                <mat-option [value]="'DRAFT'">DRAFT</mat-option>
                <mat-option [value]="'IDLE'">Pending</mat-option>
                <mat-option [value]="'STANDARD'">Standard</mat-option>
              </mat-select>
            </mat-form-field>

          </div> -->
        </div>

      </form>
    }
    <mat-chip-set class="ps-4 pt-2" [hidden]="!isReady">
      @if (activeFilters.length > 0 || filterConcession.brand) {
        <mat-label class="ps-3 d-flex w-auto align-items-center">Filtri:</mat-label>
        <button class="btn link" (click)="removeFilters()">clear</button>
      }
      @if (filterConcession.company) {
      <mat-chip >
        {{filterConcession.company}}

        <button mat-button class="btn cancel-btn p-0 d-inline-flex" (click)="resetCompany()">

          <mat-icon matChipRemove>cancel</mat-icon>
        </button>
      </mat-chip>
    }
      @if (filterConcession.brand) {
      <mat-chip >
        {{filterConcession.brand}}

        <button mat-button class="btn cancel-btn p-0 d-inline-flex" (click)="forceResetBrand()">

          <mat-icon matChipRemove>cancel</mat-icon>
        </button>
      </mat-chip>
    }
      @if (filterConcession.product) {
      <mat-chip >
        {{filterConcession.product}}

        <button mat-button class="btn cancel-btn p-0 d-inline-flex" (click)="resetProduct()">

          <mat-icon matChipRemove>cancel</mat-icon>
        </button>
      </mat-chip>
    }

      @for (item of activeFilters; track $index) {
        @if (item.name != "") {
          <mat-chip >
            {{item.name}}
            <button mat-button class="btn cancel-btn p-0 d-inline-flex" (click)="removeOption(item)">

              <mat-icon matChipRemove>cancel</mat-icon>
            </button>
          </mat-chip>

        }

      }
    </mat-chip-set>
    @if (brandReady) {

      <app-insert-data-table [hidden]="!isReady" [savedData]="savedData" [isBrand]="false" (dataFilter)="setValues($event)"></app-insert-data-table>
    }
    @if (isReady) {
      <app-campaigns-table (chip)="setChips($event)" [selectedProduct]="filterConcession.product_id" [availableProducts]="StaticProducts" [formField]="fields" (filters)="searchParam($event)" [campaigns]="detailsData">
      </app-campaigns-table>
      <div class="mt-4">
        @if (detailsData.length == 0) {
          <div class="p-4 w-100 text-center">{{'GENERAL.nothing' | translate}}</div>
        }
        @if (configData && detailsData.length > 0) {
          <basic-paginator [config]="configData" (paginator)="pageChanger($event)"></basic-paginator>

        }

      </div>
    }
  </div>
</div>

