<header class="dialog-header d-flex justify-content-between">
  {{'PAGE_CONTENT.new_campaign' | translate}}
  <button class="btn border-0 text-white" mat-dialog-close>
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
  </button>

</header>
<mat-dialog-content class="new-campaign">
  <form [formGroup]="formFields">
    <mat-form-field >
      <input placeholder="{{'CAMPAIGNS.new_ph' | translate}}" formControlName="name"  title="input" matInput >
      @if (formFields.get('name')?.hasError('required')) {
        <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
      }
    </mat-form-field>
  </form>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center px-4 pb-4">


  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button  [disabled]="!formFields.valid" class="w-100 btn-new px-5 m-0" (click)="formSubmit()">{{'BUTTONS.create' | translate}}</button>
</mat-dialog-actions>
