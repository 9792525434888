import { Component, OnInit } from '@angular/core';
import { PageLayoutComponent } from '../../components/page-layout/page-layout.component';
import { ChartComponent } from '../../components/chart/chart.component';
import { BasicTableComponent } from '../../components/basic-table/basic-table.component';
import { AuthService } from '../../services/auth.service';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { ActiveBrandComponent } from '../../components/active-brand/active-brand.component';
import { AlertsListComponent } from '../../components/alerts-list/alerts-list.component';
import { BasicTableColumns } from '../../models/tables.model';
import { CampaignsService } from '../../services/campaigns/campaigns.service';
import { SharedModule } from '../../modules/shared.module';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from '../../services/utils/utils.service';
import { ApiService } from '../../services/api.service';
import { NavigationRoutes } from '../../models/navigation.model';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    PageLayoutComponent,
    ChartComponent,
    BasicTableComponent,
    ActiveBrandComponent,
    AlertsListComponent,
    SharedModule
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit {
  isReady = false
  dataTable = [];
  columns = BasicTableColumns;
  graphData: any;
  chartReady: boolean = false;
  alertsData: any;
  activeBrandsData: any;
  currentMonth:  number = 0;
  currentYear:  number = 0;
  constructor(
    private cmpSvc: CampaignsService,
    private auth : AuthService,
    private apiSvc: ApiService,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private utils: UtilsService,
    private router: Router
  ) {}

  ngOnInit(): void {


    const param = this.getCurrentDateFormatted();
    this.retrieveData(param);
  }
   getCurrentMonthName() {
    const month: any[] = this.translate.instant('MAIN.MONTHS');
    if(month.length == 12 ) month.unshift("");

    return month[+this.currentMonth]
  }

  retrieveData(param: string) {
    this.cmpSvc.getHPCampaigns(param).subscribe((res) => {
      const details = this.translate.instant('TABLES.details');

      this.dataTable = res.data.map((el: any) => {

        el.details = details;
        const used = JSON.parse(el.used_at);

        el.start = this.utils.getMinDate(used.months)
        return el
      });

      this.isReady = true;
    })
    this.cmpSvc.getHPGraph(param).subscribe((res) => {
      this.graphData = res.datagraph1;
      this.alertsData = res.datagraph3;
      this.activeBrandsData = res.datagraph2;
      this.chartReady = true;

    })
  }


  changeView(param: "next" | "prev") {
    // Calculate the increment or decrement based on the param
    const step = param === "next" ? 1 : -1;
    // Update the currentMonth and currentYear
    this.currentMonth += step;

    if (this.currentMonth == 13) {
      this.currentMonth = 1;
      this.currentYear += 1;
    } else if (this.currentMonth == 0) {
      this.currentMonth = 12;
      this.currentYear -= 1;
    }
    //console.log(`${this.currentMonth}-${this.currentYear}`);
    this.isReady = false;
    this.chartReady = false;
    this.retrieveData(`${this.currentMonth}-${this.currentYear}`);
    // return `${this.currentMonth}-${this.currentYear}`;
  }
  getCurrentDateFormatted(): string {
    const today = new Date();
    this.currentMonth = +(today.getMonth() + 1).toString().padStart(2, '0');
    this.currentYear = +today.getFullYear().toString();

    return ((this.currentMonth+ "").length == 1 ? "0"+ this.currentMonth : this.currentMonth) + '-' + this.currentYear;
  }

  actionReceiver(event: any) {
    if (event.action == "detail") {
       this.router.navigate([`/${NavigationRoutes.Campaigns}/${event.value.campaign.replaceAll(" " , "-").toLowerCase()}_${event.value.campaign_id}/${NavigationRoutes.Resume}`] , {})

    }


  }



}
