import { MatSortModule} from '@angular/material/sort';
import { MatTableModule} from '@angular/material/table';
import { NgModule } from '@angular/core';
import { MatPaginatorModule} from '@angular/material/paginator';
import {MatAccordion, MatExpansionModule} from '@angular/material/expansion';
import { MatInputModule } from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {MatAutocompleteModule} from '@angular/material/autocomplete'
import { MatChipsModule} from '@angular/material/chips';
import { CdkColumnDef } from '@angular/cdk/table';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
} from '@angular/material/dialog';
import {MatCheckboxModule} from '@angular/material/checkbox';import {MatMenuTrigger, MatMenuModule} from '@angular/material/menu';
import {MatButtonModule, MatFabButton} from '@angular/material/button';
import { MatError, MatFormField, MatFormFieldControl, MatFormFieldModule, MatLabel } from '@angular/material/form-field';
import { MatOption, MatSelect, MatSelectTrigger } from '@angular/material/select';
@NgModule({
 imports:       [MatCheckboxModule,MatFabButton, MatChipsModule, MatSelectTrigger, MatAutocompleteModule, MatError,MatIconModule,MatPaginatorModule, MatInputModule,MatFormFieldModule,MatLabel, MatSelect, MatOption, MatFormField,MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule, MatSortModule,MatTableModule,MatExpansionModule , MatAccordion, MatMenuModule],
 declarations: [ ],
 exports:       [MatCheckboxModule,MatFabButton,MatChipsModule,MatSelectTrigger, MatAutocompleteModule,MatError,MatIconModule,MatPaginatorModule,MatInputModule,MatFormFieldModule,MatLabel, MatSelect, MatOption, MatFormField,MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule, MatSortModule,MatTableModule,MatExpansionModule , MatAccordion, MatMenuModule],
 providers: [CdkColumnDef,MatAccordion, MatDialog ]
})
export class MaterialModule { }
