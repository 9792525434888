<header>
  <div class="top-bar">
    <h1>{{ "MAIN.TITLE"| translate}}</h1>


      <div class="end-side">
          <!-- <div class="input-group search-field flex-nowrap">
              <span class="input-group-text" id="addon-wrapping">
                  <i class="icon-lg icon-lf-search"></i>
              </span>
              <input type="text" class="form-control" placeholder="Search" aria-label="Search" aria-describedby="Search">
          </div> -->
          <div class="options-fields">

            <button type="button" title="options" mat-button (click)="openUploads()" openUploads>
              <i class="icon-lg icon-lf-options "></i>
            </button>
              <!-- <mat-menu #menu="matMenu">
                <mat-dialog-content>
                  <div title="select" #langSelect >
                    Cambia Lingua:
                    @for (lang of translate.getLangs(); track $index) {
                      <p class="cp text-center" (click)="changeLang(lang)" >{{ lang }}</p>
                    }
                    <button class="btn" (click)="openUploads()">
                      Carica excel
                    </button>
                  </div>

                </mat-dialog-content>


              </mat-menu> -->
                            <!-- <a title="options" href="#">
                  <i class="icon-lg icon-lf-options "></i>
              </a> -->
              <!-- @if (showCart) { -->
                <a (click)="goToCart()" title="cart">
                    <i class="icon-lg icon-lf-cart"></i>
                </a>

              <!-- } -->

              <a (click)="goProfile()" class="profile" title="profile" >
                  <img src="./assets/images/logo-mini.png" alt="">
              </a>
          </div>

      </div>

  </div>

</header>
