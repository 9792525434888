import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../../modules/shared.module';
import { Router } from '@angular/router';
import { NavigationRoutes } from '../../../models/navigation.model';
import { Cart } from '../../../models/api.models';


@Component({
  selector: 'app-cart',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './cart.component.html',
  styleUrl: './cart.component.scss'
})

export class CartComponent implements OnInit {

  constructor(private router: Router) {

  }
  cart!: Cart;
  ngOnInit(): void {
    const cart = localStorage.getItem('cart');
    if (cart) {
      this.cart = JSON.parse(cart);
      //console.log(this.cart);


    }


  }
  goToItems(page: any ) {
    //console.log(page);

    // page.campaigns = page.campaigns
    this.router.navigate([`/${NavigationRoutes.Campaigns}/${page.campaign.replaceAll(" " , "-").toLowerCase()}_${page.campaign_id}/${NavigationRoutes.Resume}`]);
  }
}
