import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import { StoreSearch } from '../../models/store.model';

@Injectable({
  providedIn: 'root'
})
export class AlertsService {

  constructor(private http: HttpClient) { }

  searchAlert(req: StoreSearch) {
    // const headers = new HttpHeaders(req.param);
    return this.http.post(req.url? req.url : ENV.api.BASE_URL + ENV.api.endpoints.alerts + ENV.api.endpoints.search + (req.page ? req.page : ""), req.param)
   }
  insertAlert(req: any) {
    // const headers = new HttpHeaders(req.param);
    return this.http.post( ENV.api.BASE_URL + ENV.api.endpoints.alerts , req)
   }

   getAlert(id: string) {
    return this.http.get( ENV.api.BASE_URL + ENV.api.endpoints.alerts + "/" + id)
   }

   deleteAlert(id: string) {
    return this.http.delete( ENV.api.BASE_URL + ENV.api.endpoints.alerts + "/" + id)
   }
   updateAlert(req: any, id: string) {
    return this.http.put( ENV.api.BASE_URL + ENV.api.endpoints.alerts + "/" + id, req)
   }
}
