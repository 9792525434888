import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { CampaignsService } from '../../services/campaigns/campaigns.service';
import { CampaignsDetailTableColumns, TableOptions } from '../../models/tables.model';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Observable, debounceTime, from, map, startWith, tap } from 'rxjs';
import { SharedModule } from '../../modules/shared.module';
import { MaterialModule } from '../../modules/material.module';
import { TranslateService } from '@ngx-translate/core';
import { NewCampaignDetailsComponent } from '../insert-data-table/new-campaign-details/new-campaign-details.component';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { isArray } from 'chart.js/dist/helpers/helpers.core';
import { Cart } from '../../models/api.models';
import { ApiService } from '../../services/api.service';

import { ViewEncapsulation} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {provideMomentDateAdapter} from '@angular/material-moment-adapter';
import {MatDatepicker, MatDatepickerModule} from '@angular/material/datepicker';

// Depending on whether rollup is used, moment needs to be imported differently.
// Since Moment.js doesn't have a default export, we normally need to import using the `* as`
// syntax. However, rollup creates a synthetic default module and we thus need to import it using
// the `default as` syntax.
import * as _moment from 'moment';
// tslint:disable-next-line:no-duplicate-imports
import {default as _rollupMoment, Moment} from 'moment';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmComponent } from '../dialogs/confirm/confirm.component';
import { StoreSearch } from '../../models/store.model';
import { StoresService } from '../../services/stores/stores.service';
import { DateAdapter } from '@angular/material/core';

const moment = _rollupMoment || _moment;

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@Component({
  selector: 'app-campaigns-table',
  providers: [
    // Moment can be provided globally to your app by adding `provideMomentDateAdapter`
    // to your app config. We provide it at the component level here, due to limitations
    // of our example generation script.
    provideMomentDateAdapter(MY_FORMATS),
  ],
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule,SharedModule, MaterialModule, NewCampaignDetailsComponent],
  templateUrl: './campaigns-table.component.html',
  styleUrl: './campaigns-table.component.scss'
})
export class CampaignsTableComponent implements OnInit,AfterViewInit {
  months: any[] = [];
  currentMonth!: number;
  currentYear!: number;
  productsData: any[] = [];
  id: any;
  campName: any;
  productValid: boolean = false;
  productId: any;
  brandReady: boolean = true;
  constructor(private apiSvc: ApiService,
    private fb: FormBuilder,
    private translate: TranslateService,
    private _liveAnnouncer: LiveAnnouncer,
    private ar: ActivatedRoute,
    private campSvc: CampaignsService,
    public dialog: MatDialog,
    private storeSvc: StoresService
  ) {
    this.id = this.ar.snapshot.paramMap.get('id')?.split('_')[1];
    this.campName = this.ar.snapshot.paramMap.get('id')?.split('_')[0];
  }
  @Input() campaigns: any[] = [];
  @Input() availableProducts: any[] = [];
  @Input() selectedProduct: any;
  form!: FormGroup;
  companies: any[] = [];
  brands: any[] = [];
  products: any[] = [];
  datep: any = new FormControl(moment());;
  filteredCompanies: Observable<any[]>[] = [];
  filteredBrands: Observable<any[]>[] = [];
  filteredProducts: Observable<any[]>[] = [];
  filteredDisplayWindows: Observable<any[]>[] = [];
  isMultiple: boolean = true;
  outputData: any = {};
  @Input() displayedColumns = CampaignsDetailTableColumns;
  @Input() isMultipleFilter = true;
  tableData: TableOptions[] = [];
  formControl: any;
  dataSource: any[] | any = [];
  isReady = false;
  @Input() formField! : {[key:string]: any};
  @Output() filters = new EventEmitter<any>()
  @Output() actions = new EventEmitter<any>()
  @Output() chip = new EventEmitter<any>()
  date: string = ""
  companyControl = new FormControl();
  brandControl = new FormControl();
  productControl = new FormControl();
  internalControl = new FormControl("in");

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  companySelected: boolean = false;
  brandSelected: boolean = false;
  productSelected: boolean = false;
  windowsData: {[key:string]: any} = {};
  formValid = false;
  optionSelected = false;
  citySelected = false;
  stores: any[] = [];
  cities: any[] = [];
  filteredOptions!: Observable<any[]>;
  filteredCity!: Observable<any[]>;
  selectedStore: any[] = [];
  ngOnInit(): void {

    this.date = this.formField['availability']
    this.campaigns = this.campaigns.map((el: any) => {


      el.isNew = el.campaign_id === null ? true : false;
      el.disp = el.isNew;
      if (el.isNew) {


        this.windowsData[el.display_window_id] =  {
          product_id : "",
          product : "",
          used_at: el.used_at,
          brand: "",
          company: ""
        }

      } else {
        // this.windowsData[el.display_window_id] =  {
        //   product_id : "",
        //   product : "",
        //   used_at: [],
        //   brand: "",
        //   company: ""
        // }
      }



      return el
    })


    this.dataSource = this.campaigns;
    this.dataSource = new MatTableDataSource(this.dataSource);
    // this.retrieveData();
    this.isReady = true;
    this.init();
    this.formControl.get('store')?.valueChanges.pipe(
      debounceTime(500)
    )
    .subscribe((value: any) => {
      if (this.optionSelected) {
        const selectedStore = this.stores.filter(({name}) => name == value);
        //console.log(selectedStore);
        if (Array.isArray(selectedStore)) {
          if (selectedStore.length > 0) {
            // console.log(selectedStore);



          }
        }
        // Resetta il flag e ritorna
        this.optionSelected = false;
        return;
      }
      //console.log(value);
      const param: StoreSearch = {
        param: {
          name: value
        }
      }
      this.storeSvc.storeSearch(param).subscribe((res:any) => {
        //console.log(res);
        this.stores = res.data.map((el:any) => {
          return {
            name: el.name,
            type: 'store'
          }
        })
        //console.log(this.stores);
        this.optionSelected = true;
        this.filteredOptions = this.formControl.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filter(value || '')),
          tap((i: any) => {
            //console.log(i);

          })
        )

      })

      // const filter = {...value} as string;
      // this.filters.emit(filter);
    });


      this.storeSvc.getCities().subscribe((res:any) => {
        //console.log(res);
        this.cities = res.map((el: any) => {
          return {
            type : 'city',
            name : el.city,
          }
        })

        //console.log(this.stores);

        this.filteredCity = this.formControl.get('city')?.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterCity(value || '')),

        )

      })

      // const filter = {...value} as string;
      // this.filters.emit(filter);

  }
  private _filter(value: string): string[] {
    value += ""
    const filterValue = value.toLowerCase();

    return this.stores.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  private _filterCity(value: string): string[] {
    value += ""
    const filterValue = value.toLowerCase();


    return this.cities.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  addOption(option: any, type : string) {
    console.log(option, type );

    if (type == 'unique_code') {
      // this.selectedStore.push({type: 'unique_code', name: this.formControl.value.unique_code});
      this.chip.emit({type: 'unique_code', name: this.formControl.value.unique_code})
    } else if (type == 'display_window_type') {
      // this.selectedStore.push({type: 'unique_code', name: this.formControl.value.unique_code});
      this.chip.emit({type: 'display_window_type', name: this.formControl.value.display_window_type})
    } else {
      this.chip.emit(option)
      // this.selectedStore.push(option);
    }


    this.formControl.get(type).setValue("");
  }

  removeOption(option: string) {
    // console.log(option);

    this.selectedStore = this.selectedStore.filter(item => item.name !== option);
    const c = this.formControl.value.availability
    if (c) {
      this.formControl.get('availability').setValue(this.date)
    }
    const data =  this.selectedStore
    this.chip.emit(data);
    if (c) {
      this.formControl.get('availability').setValue(c);
    }
    // this.formControl.get('store').setValue(this.selectedStore);
  }
  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const m = normalizedMonthAndYear as any;
    m._i.year += ""
    m._i.month += 1
    m._i.month += ""
    m._i.month = m._i.month.length == 1 ? m._i.month = '0'+ m._i.month : m._i.month
    this.date = m._i.month + "-" + m._i.year

    const ctrlValue = this.datep.value ?? moment();    // const ctrlValue = this.formControl.get('availability') ?? moment();
    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());

    this.formControl.get('availability')?.setValue(this.date);

    this.selectedStore.push({type: 'availability', name: this.date});


    // this.selectedStore.push({type: 'availability', name: this.date});
    this.chip.emit({type: 'availability', name: this.date});
    this.formControl.get('availability')?.setValue(ctrlValue);

    datepicker.close();
  }

  resetDate() {
    this.formField['availability'] = "";
    this.date = ""
  }
  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }
  announceSortChange(sortState: Sort) {

    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  sendFilters() {
    const c = this.formControl.value.availability
    if (c) {
      this.formControl.get('availability').setValue(this.date)
    }
    this.filters.emit(this.selectedStore);
    if (c) {
      this.formControl.get('availability').setValue(c);
    }
    // this.isReady = false
  }

  getDispClass(el:any) {

    if(el.isNew == true && !!el.internal_status) {
      return 'yellow'
    }else if(el.isNew == true && !!!el.internal_status)  {
      return 'green'}
    else  {
      return 'red'
    }
  }

  updateDetail(el:any) {
    const used_at = JSON.stringify({months: this.windowsData[el.display_window_id].used_at[0] })
    const req = {
      product_id: el.product_id,
      campaign_id: el.campaign_id,
      display_window_id: el.display_window_id,
      used_at: used_at
    }
    this.campSvc.insertCampaignDetail(req).subscribe((res: any) => {
      el.isNew = false
      this.sendFilters()

    })

  }
  enableForm(el: any) {
     this.isReady =  false;
    el.highLight = true;
    this.windowsData[el.display_window_id] =  {
      product_id : el.product_id,
      product : el.product,
      used_at: [el.used_at],
      brand: el.brand,
      company: el.company
    }

    this.productValid = true;
    setTimeout(() => {
      this.isReady = true;

    }, 1);
    setTimeout(() => {
      el.isNew = true;
    }, 100);
  }
  init() {



    this.formControl = this.fb.group(this.formField)


    this.currentMonth = new Date().getMonth(); // Ottiene il mese corrente (0-11)
    this.currentYear = new Date().getFullYear(); // Ottiene l'anno corrente

    this.translate.get('MAIN.MONTHS').subscribe((res) => {
      this.months = res


      if(this.months.length > 12 )this.months.shift();
      this.months = this.months.map((el: string, index: number) => {
        const monthShort = el.substring(0, 3); // Prendi i primi 3 caratteri del mese
        const year = index < this.currentMonth ? this.currentYear + 1 : this.currentYear; // Se il mese è minore del mese corrente, usa l'anno prossimo
        const month = index< 9 ? "0" + (index + 1) : (index + 1);
        return {
          label: `${monthShort}-${year}`,
          value: month + "-" + year
        }
      });


      this.displayedColumns.forEach((el) => {

        this.outputData[el] = "";
        if (el === "availability") {
          this.outputData[el] = [];

        }

      })
      this.outputData.isNew = true;
      const {crud, actions, details, ...data} = this.outputData;
      this.outputData = data;
    }
    );

  }


  transformDate(dateStr: string) {
    if (!dateStr)  return from("");

    const [month, year] = dateStr.split('-');

    let months = this.translate.instant('MAIN.MONTHS')

       if (Array.isArray(months)) {
         months = months.map((el: any) => el.substring(0, 3));


         const monthIndex = parseInt(month, 10);
         const monthName = months[monthIndex -1];
         const yearShort = year.slice(-2);

         return `${monthName} ${yearShort}`;

       } else {
        return ""
       }
    ;

  }

  emitAction(value: any, action: any) {
    const data = {
      action: action,
      value: value
    };
    if (action == 'icon-edit') {
      this.enableForm(value);
    }
    this.actions.emit(data)
  }

  deleteRowFromCart(element: any) {

    const deltedialogRef = this.dialog.open(ConfirmComponent, {


      restoreFocus: false}
    );

    deltedialogRef.afterClosed().subscribe((res)=> {
      if (res === "save") {


        this.campSvc.deleteCampaignDetail(element.campaign_detail_id).subscribe((res) => {
          this.sendFilters()

        })

      }

    })



  }
  // save(element: any) {
  //   //console.log(this.windowsData[element['display_window_id']]);
  //   // element.used_at = this.windowsData[element['display_window_id']].used_at;
  //   const used_at = JSON.stringify({months:this.windowsData[element['display_window_id']].used_at})
  //   const req = {
  //     used_at: this.windowsData[element['display_window_id']].used_at,
  //     // used_at: used_at,
  //     campaign_id: this.id,
  //     campaign: this.campName,
  //     display_window_id: element.display_window_id,
  //     product_id: this.windowsData[element['display_window_id']].product_id,
  //     product: this.windowsData[element['display_window_id']].product,
  //     brand: this.windowsData[element['display_window_id']].brand,
  //     company: this.windowsData[element['display_window_id']].company,
  //   }

  //   const newDetail = {...element, ...req  };



  //   let cart: any = localStorage.getItem('cart');

  //   if (cart) {
  //     cart = JSON.parse(cart) as Cart;
  //     if (Array.isArray(cart[req.campaign_id+""])) {
  //       const uAT = cart[req.campaign_id+""].map((el: any) => el.used_at +"")
  //       const wID = cart[req.campaign_id+""].map((el: any) => el.display_window_id +"2507")
  //       //console.log(wID , newDetail.used_at +"");

  //       if (!uAT.includes(newDetail.used_at +"" ) ) {
  //         if (wID.includes(newDetail.display_window_id+"2507")) {
  //           const x = wID.indexOf(newDetail.display_window_id+"2507")
  //           cart[req.campaign_id+""][x].used_at = [...new Set(cart[req.campaign_id+""][x].used_at.concat(newDetail.used_at))]
  //         } else {

  //           cart[req.campaign_id+""].push(newDetail);
  //         }
  //         element.isInCart = true;
  //         //console.log(newDetail);

  //         const param = {
  //           value: true,
  //           displaywindow: newDetail.display_window,
  //           cu: newDetail.unique_code,
  //           type:newDetail.type
  //         }
  //         this.apiSvc.updateState(param);
  //       }

  //     } else {
  //       cart[req.campaign_id+""] = [];
  //       cart[req.campaign_id+""].push(newDetail);
  //       const param = {
  //         value: true,
  //         displaywindow: newDetail.display_window,
  //         cu: newDetail.unique_code,
  //         type:newDetail.type
  //       }
  //       this.apiSvc.updateState(param);
  //     }
  //     //console.log(cart);


  //   } else {
  //     cart = {[req.campaign_id+""]:[newDetail]} as Cart;
  //     //console.log(cart);
  //   }
  //   localStorage.setItem('cart', JSON.stringify(cart))





  // }

  saveDetailToCart(element: any) {

    const used_at = JSON.stringify ({months: this.windowsData[element['display_window_id']].used_at[0]})
    const req = {
      used_at: used_at,
      campaign_id: this.id,
      display_window_id: element.display_window_id,
      product_id: this.windowsData[element['display_window_id']].product_id,
    }
    this.campSvc.insertCampaignDetail(req).subscribe((res: any) => {
      const param = {
        value: true,
        displaywindow: element.display_window,
        cu: element.unique_code,
        type:element.type
      }
      this.apiSvc.updateState(param);
      this.sendFilters();
    })



  }

  formatDateString(dateString: string): string {

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // I mesi sono indicizzati a partire da 0
    const year = date.getFullYear();

    const dayString = day < 10 ? `0${day}` : `${day}`;
    const monthString = month < 10 ? `0${month}` : `${month}`;

    return `${dayString}/${monthString}/${year}`;
  }

  checkRequiredField(element: any) {

    return  this.windowsData[element['display_window_id']].used_at.length > 0 && !!this.windowsData[element['display_window_id']].product_id


  }
  productValidity(ev: any) {

    this.productValid = ev.valid;

    if(ev.valid === true) {
      if (!this.windowsData[ev.display_window_id]) {
        this.windowsData[ev.display_window_id] = {
          product_id : ""
        }
      }
      this.windowsData[ev.display_window_id].product_id = ev?.product_id ;

      // this.windowsData[ev.display_window_id].brand = ev.brand
      // this.windowsData[ev.display_window_id].company = ev.company
      // this.windowsData[ev.display_window_id].product = ev.product
    } else {
       this.windowsData[ev.display_window_id].product_id = null
    }
  }





}




