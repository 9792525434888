import { Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalSettings as GS } from '../../../global.settings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { concatMap, debounceTime, delay, from, map, Observable, of, startWith, tap } from 'rxjs';
import { environment as ENV } from '../../../../environments/environment.staging';
import { ApiService } from '../../../services/api.service';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { StoresService } from '../../../services/stores/stores.service';
import { StoreSearch } from '../../../models/store.model';

@Component({
  selector: 'app-new-alert',
  standalone: true,
  imports: [MaterialModule, SharedModule],
  templateUrl: './new-alert.component.html',
  styleUrl: './new-alert.component.scss'
})
export class NewAlertComponent implements OnInit {
  constructor(private alertSvc : AlertsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apiSvc: ApiService,
    public dialogRef: MatDialogRef<NewAlertComponent>,
  private storeSvc: StoresService) {
    }
  formFields!: FormGroup;
  localImages: any;
  isReady = false;
  stores: any[] = []
  filesToUpload: any[] = [];
  displayWindows: any[] = ['null'];
  optionSelected = false;
  filteredOptions!: Observable<any[]>;
  ngOnInit(): void {
    this.displayWindows = this.data.display_windows;
    this.inputControl();
  }
  onFileSelected(event: any): void {

    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('file', file);
    this.filesToUpload.push({file:formData , tag: 'alert'});
    // var reader = new FileReader();
    this.saveImage();
    // reader.readAsDataURL(event.target.files[0]); // read file as data url

    // reader.onload = (ev) => {
    //       // if (el.tag === tag) {
    //         this.localImages = ev.target?.result
    //       // }
    //     }
    //     // called once readAsDataURL is completed



  }
  inputControl() {
    // console.log(this.data);

    this.formFields = new FormGroup({
      name: new FormControl("", Validators.required),
      store_id: new FormControl(this.data.store_id, Validators.required),
      store: new FormControl({value:this.data.store, disabled: this.data.store == "" ? false: true}, Validators.required),
      description: new FormControl("", Validators.compose([
        Validators.required,
      ])),
      city: new FormControl({value:this.data.city, disabled: false}, Validators.required),
      status: new FormControl("", Validators.compose([
        Validators.required,
      ])),
      person_in_charge: new FormControl("", Validators.compose([
        Validators.required,
      ])),
      display_window_id: new FormControl(this.data.display_window_id),

    });
    this.isReady = true;
    this.formFields.get('store')?.valueChanges.pipe(
      debounceTime(500)
    )
    .subscribe((value: any) => {
      if (this.optionSelected) {
        const selectedStore = this.stores.filter(({name}) => name == value);
        //console.log(selectedStore);
        if (Array.isArray(selectedStore)) {
          if (selectedStore.length > 0) {
            // console.log(selectedStore);

            this.formFields.get('city')?.setValue(selectedStore[0].city)
            this.formFields.get('store_id')?.setValue(selectedStore[0].id)
            this.storeSvc.displayWindows({},selectedStore[0].id ).subscribe((res: any) => {
              //console.log(res);
              this.displayWindows = res.displaywindows.map((res: any) => {
                return {
                  id: res.display_window_id,
                  name:res.name
                }

              });
              //console.log(this.displayWindows);


            })

          }
        }
        // Resetta il flag e ritorna
        this.optionSelected = false;
        return;
      }
      //console.log(value);
      const param: StoreSearch = {
        param: {
          name: value
        }
      }
      this.storeSvc.storeSearch(param).subscribe((res:any) => {
        //console.log(res);
        this.stores = res.data.map((el:any) => {
          return {
            name: el.name,
            id: el.store_id,
            city: el.city
          }
        })
        //console.log(this.stores);
        this.optionSelected = true;
        this.filteredOptions = this.formFields.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filter(value || '')),
          tap((i: any) => {
            //console.log(i);

          })
        )

      })

      // const filter = {...value} as string;
      // this.filters.emit(filter);
    });


  }
  private _filter(value: string): string[] {
    value += ""
    const filterValue = value.toLowerCase();

    return this.stores.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  saveImage() {
    this.data.images = []
    from(this.filesToUpload).pipe(
      concatMap(value =>
        of(value).pipe(
          delay(500),
          concatMap(val => this.apiSvc.uploadFile(val.file).pipe(
            map((response: any) => ({ value: val, response }))
          ))
        )
      )
    ).subscribe({
      next: ({ value, response }) => {
        const url = ENV.api.BASE_URL + "/uploads/images/" + (response.file);
        this.data.images.push(
          {
            url: url,
            tags: [value.tag]
          }
        )
        //console.log(this.data);

      },

    })

  }
  formSubmit() {
    //console.log(this.formFields.valid);

    if (this.formFields.valid) {
      if (!this.data.images) {
        this.data.images = []
      }
      const {
        created_at,
        updated_at, display_window_id, displaywindows, ...req } = this.data;
        let d;
        const data = {...req, ...this.formFields.value}
        if (data.display_window_id ==  'null' || data.display_window_id ==  "" || data.display_window_id ==  null) {
          const {display_window_id, ...dataClean } = data;
          d = dataClean
        } else {
          d = data
        }
        d.images = JSON.stringify(data.images);
        console.log(data);
        // (this.data.display_window_id ==  'null' || this.data.display_window_id ==  "" || this.data.display_window_id ==  null)? null:  data.display_window_id = this.data.display_window_id
        this.alertSvc.insertAlert(d).subscribe((el: any)=> {
          this.dialogRef.close();
        })
        // req.display_windows = JSON.stringify(req.display_windows);
        // this.dialogRef.close(data);
    }
  }
}
