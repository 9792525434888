import { Component, Input } from '@angular/core';
import { SharedModule } from '../../modules/shared.module';

@Component({
  selector: 'app-active-brand',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './active-brand.component.html',
  styleUrl: './active-brand.component.scss'
})
export class ActiveBrandComponent {

  @Input() apiData: any[] = []
  overData: any;
  sideOpener: any;
  timer = false
  makeOver(ev: any) {

      this.sideOpener = 'open'
      this.overData = ev


  }
  stop(item: any) {
    // setTimeout(() => {
      item.class= "close"

      setTimeout(() => {
        item.class= ""
      }, 400);

    // }, 600);

  }
}
