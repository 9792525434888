<header class="dialog-header d-flex justify-content-between">
    Sostituisci prodotti in campagna
  <button class="btn border-0 text-white" mat-dialog-close>
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
  </button>

</header>
<mat-dialog-content class="massive-change">
  <div class="row">
    <div class="col-6">

    </div>
  </div>
  <div style="min-height: 150px;">
    @for (item of data; track $index) {
      <!-- <mat-checkbox [(ngModel)]="item.ischecked">
      </mat-checkbox> -->
      Sostituisci : <h6>{{item.name}} con</h6>
      <app-insert-data-table [isBrand]="false" [onMassiveChange]="true" (values)="idSelected($event, item.ischecked, item )"></app-insert-data-table>
    }

  </div>

  <hr>
  <div class="row">
    @for (a of added; track $index) {
      <div class="col-12">
        old name {{a.old_name}}
       <i class="icon-lf-chevron-right"></i>
       {{a.product_new_name}}

      </div>


    }




  </div>



</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center px-4 pb-4">
  <div class="col-12 text-center">
    <button [disabled]="added.length == 0" class="btn-new" (click)="startChange()">
      cambia selezionati
    </button>
  </div>


  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <!-- <button  [disabled]="!formFields.valid" class="w-100 btn-new px-5 m-0" (click)="formSubmit()">{{'BUTTONS.create' | translate}}</button> -->
</mat-dialog-actions>
