

<div class="host">
  @if (isReady) {
    @if (isMultipleFilter) {
      <form [formGroup]="formControl" class="search-form">
        <div class="row">
          <!-- <mat-chip-set>
            <mat-chip *ngFor="let option of selectedStore" >
              {{option.name}}
              <button mat-button (click)="removeOption(option.name)">

                <mat-icon matChipRemove>cancel</mat-icon>
              </button>
            </mat-chip>
          </mat-chip-set> -->
          @for(keypar of formField|keyvalue;track keypar.key)
            {
              <div class="col-auto d-flex align-items-center mb-2">
                @if (keypar.key == 'availability') {
                  <label for="mese">{{'GENERAL.date' | translate}}</label>
                  <mat-form-field class="datepicker">
                    <label class="position-absolute" for="">{{"Mese"}}</label>
                    <input class="d-none" matInput [matDatepicker]="dp" title="date" [formControlName]="keypar.key">
                    <mat-hint>MM/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="dp"></mat-datepicker-toggle>
                    <mat-datepicker #dp
                                    startView="multi-year"
                                    (monthSelected)="setMonthAndYear($event, dp)"
                                    panelClass="example-month-picker">
                    </mat-datepicker>
                    @if (date !== "") {
                      <!-- <button type="button" mat-button (click)="resetDate()" class="clear">
                        {{'GENERAL.reset' | translate}}
                      </button> -->

                    }
                  </mat-form-field>
                } @else if (keypar.key == 'store') {
                  <label for="{{keypar.value}}">{{'TABLES.'+ keypar.key | translate}}</label>
                  <mat-form-field class="multiple-field">

                    <input type="text"
                    placeholder="{{'PH.store' | translate}}"
                           aria-label="Number"
                           matInput
                           formControlName="store"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                      @for (option of filteredOptions | async; track option) {
                        <mat-option (click)="addOption(option, 'store')" [value]="option.name">{{option.name}}</mat-option>
                      }
                    </mat-autocomplete>
                              <!-- <input formControlName="store" title="input" matInput >
                              @if (formFields.get('store')?.hasError('required')) {
                                <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
                              } -->
                            </mat-form-field>

                } @else if (keypar.key == 'city') {
                  <label for="{{keypar.value}}">{{'TABLES.'+ keypar.key | translate}}</label>
                  <mat-form-field class="multiple-field ">

                    <input type="text"
                           aria-label="Number"
                           matInput
                           formControlName="city"
                           [matAutocomplete]="auto">
                    <mat-autocomplete #auto="matAutocomplete">
                      @for (option of filteredCity | async; track option) {
                        <mat-option (click)="addOption(option , 'city')" [value]="option.name">{{option.name}}</mat-option>
                      }
                    </mat-autocomplete>
                              <!-- <input formControlName="store" title="input" matInput >
                              @if (formFields.get('store')?.hasError('required')) {
                                <mat-error class="mt-2">{{'STORES.required_field' | translate}}</mat-error>
                              } -->
                            </mat-form-field>

                }  @else if (keypar.key == 'unique_code')  {
                  <label for="{{keypar.key}}">{{'TABLES.'+ keypar.key | translate}}</label>
                  <input id="{{keypar.key}}" (change)="addOption(keypar , 'unique_code')" class="form-control" [title]="keypar.key" matInput [formControlName]="keypar.key">

                }  @else if (keypar.key == 'display_window_type')  {
                  <label for="{{keypar.key}}">{{'TABLES.type' | translate}}</label>
                  <mat-form-field class="select-field">
                    <mat-select (selectionChange)="addOption(keypar , 'display_window_type')" [attr.name]="keypar.key" id="{{keypar.key}}" class="status"  matInput title="{{keypar.key}}" [formControlName]="keypar.key">

                      <mat-option  [value]="'STORES.cat_stampa' | translate">{{'STORES.cat_stampa' | translate}}</mat-option>
                      <mat-option  [value]="'STORES.cat_vetrofania' | translate">{{'STORES.cat_vetrofania' | translate}}</mat-option>
                      <mat-option  [value]="'STORES.cat_empty' | translate">{{'STORES.cat_empty' | translate}}</mat-option>


                    </mat-select>
                  </mat-form-field>
                  <!-- <input id="{{keypar.value}}"  class="form-control" [title]="keypar.key" matInput [formControlName]="keypar.key"> -->

                }
              </div>
          }

          <div class="col text-end d-flex align-items-center mb-2">
            <button class="me-0 ms-auto btn-new" (click)="sendFilters()" type="button">{{'ACTIONS.search' | translate}}</button>
          </div>
        </div>

      </form>
    }
        <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)" class="mat-elevation-z8 campaign-table">

          @for (item of displayedColumns; track $index) {

            <ng-container [matColumnDef]="item">
              @if (item == 'status') {
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                  {{'TABLES.'+  item | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <ng-container  *ngIf="element.isNew; else display">
                    <mat-form-field>
                      <mat-select placeholder="{{'TABLES.'+  item | translate}}" name="s" id="" class="status"  matInput title="title" [(ngModel)]="outputData[item]">

                        <mat-option  [value]="'STATUS.ok' | translate">{{'STATUS.ok' | translate}}</mat-option>
                        <mat-option  [value]="'STATUS.ko' | translate">{{'STATUS.ko' | translate}}</mat-option>
                        <mat-option  [value]="'STATUS.draft' | translate">{{'STATUS.draft' | translate}}</mat-option>


                      </mat-select>
                    </mat-form-field>
                    </ng-container>
                    <ng-template #display><div [ngClass]="{alert: element.internal_status== 'OK', pending: element.internal_status== 'DRAFT', done: element.internal_status== 'KO' }" class="btn btn-lf">{{element.status}}</div></ng-template>


                </td>
              }
              @else if (item == 'disp') {
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                  {{'TABLES.'+  item | translate}}
                </th>
                <td mat-cell *matCellDef="let element" >
                  <i [title]="element.isNew" class="circle {{getDispClass(element)}}"></i>
                </td>

              }
              @else if (item == 'created_at') {
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                  {{'TABLES.'+  item | translate}}
                </th>
                <td mat-cell *matCellDef="let element"> {{formatDateString(element[item])}} </td>
              }
              @else if ( item == 'company' ) {
                <th style="width: 360px;" class="p-0"  mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                  <!-- {{'TABLES.'+  item | translate}} {{'TABLES.brand' | translate}} {{'TABLES.product' | translate}} -->
                    <table class="cbp">
                      <tr>
                        <td>
                         {{'TABLES.'+  item | translate}}
                        </td>

                        <td>
                         {{'TABLES.brand' | translate}}
                        </td>

                        <td>
                         {{'TABLES.product' | translate}}
                        </td>
                      </tr>
                  </table>
                </th>
                <td [ngClass]="{'blink' : element.highLight}" style="width: 360px;"  class="px-0" mat-cell *matCellDef="let element">
                  <ng-container >
                    @if (brandReady) {
                      <app-new-campaign-details [selectedProduct]="selectedProduct" [availableProducts]="availableProducts"  (values)="productValidity($event)" [element]="element" [item]="item" [data]="productsData"></app-new-campaign-details>

                    }

                  </ng-container>
                  <!-- <ng-template  #display>
                    {{ element['company'] }}
                    {{ element['brand'] }}
                    {{ element['product'] }}

                  </ng-template> -->
                </td>
              }


              @else if (item == 'realAvailability') {
                <th  style="min-width: 150px; width: 150px; max-width: 150px;" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                  {{'TABLES.'+  item | translate}}
                </th>

                  <td style="min-width: 150px; width: 150px; max-width: 150px;" [ngClass]="{'blink' : element.highLight}" mat-cell *matCellDef="let element; let i = index" >
                    <ng-container *ngIf="element.isNew; else display">
                      <mat-form-field class="realAvailability">
                        <mat-select  multiple placeholder="{{'TABLES.'+  item | translate}}" name="s" id="" title="title" matInput title="title" [(ngModel)]="windowsData[element['display_window_id']].used_at[0]" >
                          @for(month of element[item] ; track $index) {
                            <mat-option [disabled]="!element.isNew"  [value]="month">{{transformDate(month)}}</mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                      </ng-container>
                      <ng-template #display>
                        <mat-form-field class="realAvailability">
                          <mat-select  multiple placeholder="{{'TABLES.'+  item | translate}}" name="s" id="" title="title" matInput title="title" [(ngModel)]="element.used_at" >
                            @for(month of element[item] ; track $index) {
                              <mat-option [disabled]="!element.isNew"  [value]="month">{{transformDate(month)}}</mat-option>
                            }
                          </mat-select>
                        </mat-form-field>
                      </ng-template>

                  </td>

              }
              @else if (item == 'details') {
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                </th>
                <td mat-cell *matCellDef="let element" [class]="element.isNew? 'p-0 d-none' : null" [attr.colspan]="element.isNew? 0 : 1">
                  <a class="detail" (click)="emitAction(element ,'detail')" >{{element[item]}}</a>
                </td>
              }
              @else if (item == 'export') {
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                </th>
                <td mat-cell *matCellDef="let element"> <a class="export" (click)="emitAction(element , 'export')" >{{element[item]}}</a>  </td>
              }
              @else if (item == 'crud') {
                <th class="px-1" style="min-width: 100px;" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                </th>
                <td class=" px-1" mat-cell *matCellDef="let element" [class]="element.isNew? 'p-0' : null" [attr.colspan]="element.isNew? 0 : 1">
                  <!-- @for(crud of element[item] | keyvalue; track crud.key) {

                    <button class="btn ps-0" [class]="crud.key" (click)="emitAction(element , crud.key)" >
                      @if ((crud.key + "").includes("icon")) {
                        <i [class]="crud.value"></i>
                      } @else {
                        {{crud.value}}
                      }
                    </button>
                  } -->
                  <ng-container *ngIf="element.isNew; else display">
                    <button [disabled]="windowsData[element.display_window_id].used_at.length == 0" class="ms-0 btn" (click)="updateDetail(element)" >
                      <i aria-hidden="false" aria-label="Example home icon" class="icon-lf-add icon-lg"></i>
                      <span class="ps-2">Add</span>

                    </button>
                    <!-- <button [hidden]="!element.isInCart" class="mt-2 btn btn-circle bg-discard ms-0" (click)="deleteRowFromCart(element)" >
                      <i aria-hidden="false" aria-label="Example home icon" class="icon-lf-delete icon-lg"></i>

                    </button> -->


                  </ng-container>
                  <ng-template #display>
                    <!-- <button
                    [disabled]="!checkRequiredField(element) "
                    (click)="saveDetailToCart(element)"
                     class="ms-0 btn btn-circle bg-confirm" >
                      <i aria-hidden="false" aria-label="Example home icon" class="icon-lf-add icon-lg"></i>

                    </button> -->
                    <!-- <button  [disabled]="!checkRequiredField(element)" (click)="saveDetailToCart(element)" class="ms-0 btn btn-circle bg-confirm" >
                      <i aria-hidden="false" aria-label="Example home icon" class="icon-lf-add icon-lg"></i>

                    </button> -->
                    <button title="edit"[disabled]="element.internal_status != 'DRAFT'" class="btn px-1" (click)="enableForm(element)">
                      <i class="icon-lf-edit"></i>
                    </button>
                    <button title="delete" [disabled]="element.internal_status != 'DRAFT'" class="px-1 btn" (click)="deleteRowFromCart(element)" >
                      <i aria-hidden="false" aria-label="Example home icon" class="icon-lf-delete"></i>
                    </button>
                  </ng-template>
                  <!-- <a class="edit" (click)="emitAction(element , 'edit')" >{{crud.value}}</a>
                  <a class="delete" (click)="emitAction(element , 'delete')" >{{element[item].delete}}</a> -->
                </td>
              }
              @else if (item == 'actions') {
                <th style="min-width: 100px;" class="px-1" mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by symbol">
                </th>
                <td mat-cell *matCellDef="let element" class="action px-1" [attr.colspan]="element.isNew? 3 : 1">

                  <ng-container *ngIf="element.isNew; else display">
                    <button  [disabled]="!checkRequiredField(element)" (click)="saveDetailToCart(element)" class="ms-0 btn" >
                      <i aria-hidden="false" aria-label="Example home icon" class="icon-lf-add icon-lg"></i>
                      <span class="ps-2">Add</span>

                    </button>
                    <!-- <button [hidden]="!element.isInCart" class="mt-2 btn btn-circle bg-discard ms-0" (click)="deleteRowFromCart(element)" >
                      <i aria-hidden="false" aria-label="Example home icon" class="icon-lf-delete icon-lg"></i>

                    </button> -->


                  </ng-container>
                  <ng-template #display>
                    <!-- <button
                    [disabled]="!checkRequiredField(element) "
                    (click)="saveDetailToCart(element)"
                     class="ms-0 btn btn-circle bg-confirm" >
                      <i aria-hidden="false" aria-label="Example home icon" class="icon-lf-add icon-lg"></i>

                    </button> -->
                    <!-- <button  [disabled]="!checkRequiredField(element)" (click)="saveDetailToCart(element)" class="ms-0 btn btn-circle bg-confirm" >
                      <i aria-hidden="false" aria-label="Example home icon" class="icon-lf-add icon-lg"></i>

                    </button> -->
                    <button title="edit" [disabled]="element.internal_status != 'DRAFT'" class="btn px-1" (click)="enableForm(element)">
                      <i class="icon-lf-edit"></i>
                    </button>
                    <button title="delete" [disabled]="element.internal_status != 'DRAFT'" class="px-1 btn" (click)="deleteRowFromCart(element)" >
                      <i aria-hidden="false" aria-label="Example home icon" class="icon-lf-delete"></i>
                    </button>
                    <!-- @for(actions of element[item] | keyvalue; track actions.key) {

                      <button class="btn" [class]="actions.key" (click)="emitAction(element , actions.key)" >
                        @if (actions.key == "icon") {
                          <i [class]="actions.value"></i>
                        } @else {
                          {{actions.value}}
                        }
                      </button>
                    } -->
                  </ng-template>

                  <!-- <a class="edit" (click)="emitAction(element , 'edit')" >{{crud.value}}</a>
                  <a class="delete" (click)="emitAction(element , 'delete')" >{{element[item].delete}}</a> -->
                </td>
              }
              @else {
                <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by {{item}}">
                  {{'TABLES.'+  item | translate}}
                </th>
                <td mat-cell *matCellDef="let element">
                  <!-- <ng-container *ngIf="element.isNew; else display">
                    <mat-form-field>
                      <input placeholder="{{'TABLES.'+  item | translate}}" matInput title="title" [(ngModel)]="outputData[item]">
                    </mat-form-field>
                  </ng-container> -->
                  {{ element[item] }}
                  <!-- <ng-template #display>
                  </ng-template> -->
                </td>
              }
            </ng-container>
          }

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        </table>

        <!-- <mat-paginator [pageSizeOptions]="[5, 10, 20]"
        showFirstLastButtons
        [length]="dataSource.length"
        [pageSize]="10"
        aria-label="Select page of periodic elements">
      </mat-paginator> -->

    <!-- @if (showPaginator) {

    } -->
  }

</div>

