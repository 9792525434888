<header class="dialog-header d-flex justify-content-between">
  {{'STORES.brands_manager' | translate}}
  <button class="btn border-0 text-white" mat-dialog-close>
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
  </button>
</header>
<mat-dialog-content>
  @if (isReady) {
    <div class="brand-list">
      <div class="row pt-3">
        <div class="col-3">
          <h2>Società</h2>

        </div>
        <div class="col-3">
          <h2>Brand</h2>

        </div>
        <!-- <div class="col-3">
          <h2>Vetrina</h2>

        </div> -->

      </div>
    @for (brand of params.brands; track $index) {
      <div class="row pt-3">
        <div class="col-3">
          <h2>{{brand.company}}</h2>

        </div>
        <div class="col-3">

          <h3>{{brand.brand}}</h3>
        </div>
        <!-- <div class="col-3">
          <h4>{{brand.display_window}}</h4>

        </div> -->
        <div class="col-auto intern">
          <span >{{brand.materials_provided == 0 ?  'Esterno' : 'Interno' }}</span>

        </div>
        <div class="col text-end">
          <button class="circle btn bg-discard btn-circle" (click)="deleteRow(brand)">
            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
          </button>

        </div>
      </div>
      <!-- <div class="row products pb-3">
          <div class="col-12">
            <span>{{brand.product}}</span>
          </div>
      </div> -->

    }
  </div>
  }
  @if (!addrowVisible) {
    <button (click)="addData()" class="mt-3 btn link" >{{'BUTTONS.add_brands' | translate}}</button>

  }
  @else {
    <div class="mt-4">
      <app-insert-data-table [availableDisplayWindows]="params.availableWindows" [ids]="params.ids" (values)="matchPW($event)"></app-insert-data-table>

    </div>
    <!-- <app-insert-data-table></app-insert-data-table> -->
  }
  <div class="col-auto" draggable="true">
    <button (click)="upload.click()" class="mt-3 btn xls d-flex flex-column align-items-center" type="button">
      <span>
        <i class="icon-lf-uploadxls icon-lg pe-1"></i>
        {{'BUTTONS.upload_excel' | translate}}
      </span>
    </button>
    <input title="input"  #upload class="d-none" type="file" accept=".xls"
    (change)="onFileSelected($event)"
    >

  </div>
</mat-dialog-content>
