import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StoresService } from '../../../services/stores/stores.service';
import { StoreWindowDetail } from '../../../models/tables.model';
import { SharedModule } from '../../../modules/shared.module';
import { BasicTableComponent } from '../../../components/basic-table/basic-table.component';
import { MaterialModule } from '../../../modules/material.module';
import { StoreBrandsComponent } from '../../../components/dialogs/store-brands/store-brands.component';
import { MatDialog } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';
import { StoreEditFields } from '../../../models/store.model';
import { StoreFieldsComponent } from '../../../components/dialogs/store-fields/store-fields.component';
import { StoreImagesComponent } from '../../../components/dialogs/store-images/store-images.component';
import { NavigationRoutes } from '../../../models/navigation.model';
import { ImageCarouselComponent } from '../../../components/dialogs/image-carousel/image-carousel.component';
import { AlertsListComponent } from '../../../components/alerts-list/alerts-list.component';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { WindowFieldsComponent } from '../../../components/dialogs/window-fields/window-fields.component';
import { NewAlertComponent } from '../../../components/dialogs/new-alert/new-alert.component';

@Component({
  selector: 'app-vetrine-details',
  standalone: true,
  imports: [AlertsListComponent, SharedModule, BasicTableComponent, MaterialModule],
  templateUrl: './window-details.component.html',
  styleUrl: './window-details.component.scss'
})
export class WindowDetailsComponent implements OnInit {
  id: any;
  params: any;
  columns = StoreWindowDetail;
  selectedTag = "all";
  isReady = false;
  storeId : any;
  images: any[] = [];
  filters = {
    company: '',
    brand: '',
    product: '',
  }
  placeImg = '/assets/images/logo.png'
  brands: any[] = [];
  alertsData: any[] = [];
  name: string | undefined;
  constructor(private ar : ActivatedRoute,
    private router: Router,
    private alertSvc: AlertsService,
    private storeSvc: StoresService,public dialog: MatDialog,) {
    this.id = this.ar.snapshot.paramMap.get('uuid')?.split('_')[1];
    this.storeId = this.ar.snapshot.paramMap.get('id')?.split('_')[1];
    this.name = this.ar.snapshot.paramMap.get('uuid')?.split('_')[0];


  }

  ngOnInit(): void {
    this.getAlerts();
    this.storeSvc.windowDetail(this.id).subscribe((res) => {

      this.params = res;
      this.params.images = JSON.parse(this.params.store.images);
      this.getCompanies()
      this.storeSvc.getWindowTable(this.id).subscribe((res: any) => {
        this.params.datatable = res.data.map((el: any) => {

          const used = JSON.parse(el.used_at);
          el.availability = used.months;
          return el
        })


        // this.params.datatable = res.data;
        this.isReady = true;
      } )

    });
    this.storeSvc.getSingleStore(this.storeId).subscribe((res: any) => {

      this.images = JSON.parse(res.images)
      //console.log(res);


    });
  }
  changeData(tag: (any | string) = 'all') {
    this.selectedTag = tag;

    if (tag == 'all') {
      this.images = this.params.images
    } else {
      this.images = this.params.images.filter((f: any) => f.tags[0] === tag );
    }
  }
  newStoreAlert() {
   const dw = [{
     id: this.params.display_window_id,
     name:this.params.name

   }]
    const data = {
      city: this.params.store.city,
      store: this.params.store.name,
      store_id: this.params.store_id,
      display_window_id: this.params.display_window_id,
      display_windows: dw

    }
    const dialogRef = this.dialog.open(NewAlertComponent, {
      data:data,
      width: '80%',

      restoreFocus: false}
    );
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.isReady = false;
        this.alertsData = [];
        this.getAlerts();

      }
    })
  }
  newSearch(ev: any) {
    //console.log(ev);


    const data: any = {};
    for (const key in ev) {
        const element = ev[key];
        if (element !== "") {
          data[key] = element
        }
    }
    data.display_window_id = this.id

    this.filters = {...this.filters, ...ev},
    this.isReady = false;
    this.storeSvc.getWindowTable(this.id, data).subscribe((res: any) => {
      //console.log(res);
      this.params.datatable = res.data.map((el: any) => {

        const used = JSON.parse(el.used_at);
        el.availability = used.months;
        return el
      })

      this.isReady = true;
    })
  }
  getAlerts() {
    const req = {
      param: {display_window: this.name }
    }
    this.alertSvc.searchAlert(req).subscribe((res: any) => {

      this.alertsData = res.data;
      //console.log(this.alertsData);


    })

  }
  editFields(action: (any | string )) {
    // const brandsWindows = {brands: this.brands, ids: [this.params.display_window_id]}
    // const data =  brandsWindows
    // const dialogRef = this.dialog.open(StoreBrandsComponent, {
    //   data:data,
    //   width: '80%',

    //   restoreFocus: false}
    // );
    // dialogRef.afterClosed().subscribe((resp) => {
    //   //console.log(resp);

    //   if (resp == 'refresh') {


    //     this.isReady = false;
    //     this.ngOnInit();






    //   }
    // });

    const component: ComponentType<any> = action == StoreEditFields.details ? WindowFieldsComponent : (action == StoreEditFields.images ? StoreImagesComponent : StoreBrandsComponent)
    const brandsWindows = {brands: this.brands, ids: this.storeId}
    const data = action == StoreEditFields.details? this.params : (action == StoreEditFields.images ? this.params.images : brandsWindows)
    const dialogRef = this.dialog.open(component, {
      data:data,
      width: '80%',

      restoreFocus: false}
    );
    dialogRef.afterClosed().subscribe((resp) => {
      //console.log(resp);

      if (resp) {
        if (action == StoreEditFields.images) {
          this.params.images = JSON.stringify(resp);
          resp = this.params
        }
        if (action !=  StoreEditFields.brands) {
          console.log(resp);

          this.storeSvc.updateWindowDetails(resp , this.id ).subscribe((res) => {
            // this.router.navigate([`/${NavigationRoutes.Stores}`])
            switch (action) {
              case StoreEditFields.details:
                if (data.name === this.params.name) {
                  this.ngOnInit();
                } else {
                  this.router.navigate([`/${NavigationRoutes.Stores}/${data.name.replaceAll(" " , "-")}_${data.store_id}`] , { })
                }

                break;

              default:
                this.ngOnInit();
                break;
            }


          });

        } else {
          this.getCompanies()
        }




      }
    });

  }

  actionReceiver(event: any) {

  }
  getCompanies() {
    this.storeSvc.getCompanies(this.storeId).subscribe((res) => {
      const mergedData = Object.values((res as any[]).reduce((acc, item) => {
        const key = `${item.company}-${item.brand}`;
        if (!acc[key]) {
          acc[key] = { company: item.company, brand: item.brand, product: [] , display_window: item.display_window, display_window_id: item.display_window_id , product_display_window_id: item.product_display_window_id,unique_code: item.unique_code};
        }
        acc[key].product.push(item.product);
        return acc;
      }, {}));
      this.brands = mergedData.filter((el:any) => el.display_window_id === this.params.display_window_id);


    })
  }

  imageZoom() {
    const dialog = this.dialog.open(ImageCarouselComponent,  {
      data:this.params.images,
      width: '80%',
      restoreFocus: false}
    )
  }
}
