<!-- <app-page-layout  class="dashboard">
</app-page-layout> -->
<div class="container-fluid dashboard content overflow-hidden">
  <div class="lf-card mt-3">
    <div class="row justify-content-center align-items-center">
      <div class="col-auto px-0">
        <button (click)="changeView('prev')" type="button" title="backward" class="px-0 btn icon-lf-chevron-left"></button>
      </div>
      <div class="col-auto month">
        <span class="ex">{{getCurrentMonthName()}}  {{currentYear}}</span>
      </div>
      <div class="col-auto px-0">
        <button (click)="changeView('next')" type="button" title="forward" class="px-0 btn icon-lf-chevron-right"></button>
      </div>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-12 col-lg-auto pb-4">
      @if (chartReady) {
        <app-chart
        [apiData]="graphData"
        ></app-chart>
        @if (graphData?.length == 0) {
          <div class="text-center">{{'GENERAL.no_data' | translate}}</div>
        }

      }
    </div>
    <div class="col-lg pb-4">
      <div class="lf-card">
        <app-active-brand
        [apiData]="activeBrandsData"></app-active-brand>
        @if (activeBrandsData?.length == 0) {
          <div class="position-absolute top-50 w-100 text-center">{{'GENERAL.no_data' | translate}}</div>
        }

      </div>
    </div>
    <div class="col-xxl-3 pb-4">
      <div class="lf-card overflow-auto">
        <app-alerts-list
        [apiAlerts]="alertsData">
        @if (alertsData?.length == 0) {
          <div class="position-relative top-25">
            <div class="position-absolute top-50 w-100 text-center">{{'GENERAL.no_data' | translate}}</div>

          </div>
        }
      </app-alerts-list>

      </div>
    </div>

  </div>
  <div class="row">
    <div class="col-12 pb-2">
      @if (isReady) {
        <div class="lf-card mt-4">
          <h3 class="mb-2">{{'DASHBOARD.CAMPAIGNS_SCHEDULER' | translate}}</h3>
        <app-basic-table
        [tableData]="dataTable"
        [displayedColumns]="columns"
        (action)="actionReceiver($event)"></app-basic-table>
        @if (dataTable.length == 0) {
          <div class="text-center p-5">{{'GENERAL.no_data' | translate}}</div>
        }
        </div>

      }
    </div>
  </div>
</div>
