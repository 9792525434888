import { Component, Inject } from '@angular/core';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-cart-dialog',
  standalone: true,
  imports: [MaterialModule,SharedModule],
  templateUrl: './cart-dialog.component.html',
  styleUrl: './cart-dialog.component.scss'
})
export class CartDialogComponent {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
  public dialogRef: MatDialogRef<CartDialogComponent>) {}


  goToCart() {
    this.dialogRef.close('cart')
  }
}
