import { Component, OnInit } from '@angular/core';
import { PageLayoutComponent } from '../../../components/page-layout/page-layout.component';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationRoutes } from '../../../models/navigation.model';
import { SharedModule } from '../../../modules/shared.module';
import { StoresService } from '../../../services/stores/stores.service';
import { BasicTableComponent } from '../../../components/basic-table/basic-table.component';
import { StoresDetailTableColumns } from '../../../models/tables.model';
import { MatDialog } from '@angular/material/dialog';
import { StoreImagesComponent } from '../../../components/dialogs/store-images/store-images.component';
import { StoreBrandsComponent } from '../../../components/dialogs/store-brands/store-brands.component';
import { StoreFieldsComponent } from '../../../components/dialogs/store-fields/store-fields.component';
import { StoreEditFields } from '../../../models/store.model';
import { MaterialModule } from '../../../modules/material.module';
import { ComponentType } from '@angular/cdk/portal';
import { InsertDataTableComponent } from '../../../components/insert-data-table/insert-data-table.component';
import { NewAlertComponent } from '../../../components/dialogs/new-alert/new-alert.component';
import { TranslateService } from '@ngx-translate/core';
import { ImageCarouselComponent } from '../../../components/dialogs/image-carousel/image-carousel.component';
import { AlertsService } from '../../../services/alerts/alerts.service';
import { AlertsListComponent } from '../../../components/alerts-list/alerts-list.component';
import { ApiService } from '../../../services/api.service';
import { environment as ENV } from '../../../../environments/environment';
import { ConfirmComponent } from '../../../components/dialogs/confirm/confirm.component';

@Component({
  selector: 'app-pv-details',
  standalone: true,
  imports: [PageLayoutComponent, AlertsListComponent, InsertDataTableComponent, SharedModule, BasicTableComponent, MaterialModule],
  templateUrl: './store-details.component.html',
  styleUrl: './store-details.component.scss'
})
export class StoreDetailsComponent implements OnInit{
  constructor(
    private ar: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private apiSvc: ApiService,
    private storeSvc: StoresService,
    public dialog: MatDialog,
    private alertSvc: AlertsService,
  ) {
    this.id = this.ar.snapshot.paramMap.get('id')?.split('_')[1] ;
    this.name = this.ar.snapshot.paramMap.get('id')?.split('_')[0] ;
  }
  filters = {
    brand: '',
    product: '',
    months: '',
  }
  alertsData: any[] = [];
  name: string | undefined;
  brands: any;
  selectedTag = "all";
  params: any;
  id:any;
  images: any[] = []
  columns = StoresDetailTableColumns;
  isReady = false;
  placeImg = '/assets/images/logo.png'
  tags: any[] = [];
  ngOnInit(): void {
    this.retrieveData();
    this.getAlerts()
  }
  goToItems(page: string , extra?: any) {
    page = page.replaceAll(" " , "-")
    this.router.navigate([`/${NavigationRoutes.Stores}/${page}`] , {
      state: extra
    });
  }

  newStoreAlert() {
    const dw = this.params.displaywindows.map((el:any) => {
      return {
        id: el.display_window_id,
        name: el.name
      }
    })
    const data = {
      city: this.params.city,
      store: this.params.name,
      store_id: this.params.store_id,
      display_window_id: 'null',
      display_windows: dw

    }
    const dialogRef = this.dialog.open(NewAlertComponent, {
      data:data,
      width: '80%',

      restoreFocus: false}
    );
    dialogRef.afterClosed().subscribe((resp) => {
      if (resp) {
        this.isReady = false;
        this.alertsData = [];
        this.getAlerts();

      }
    })
  }
  retrieveData() {

    this.storeSvc.displayWindows({},this.id ).subscribe((res) => {
      this.params = res;
      console.log(this.params.pdf_file);

      if(!!this.params.pdf_file) {
        this.params.pdf_file = this.params?.pdf_file.includes('uploads/pdf') ? this.params?.pdf_file : ENV.api.BASE_URL + '/uploads/pdf/'+ this.params?.pdf_file;

      }
      this.params.images = JSON.parse(this.params.images);
      this.images = this.params.images;
      //TODO - rimuovere if dopo sistemato
      console.log(this.images);

      if (this.images.length > 1 ) {
        if (this.images[0].length == 0) {
          this.images.shift();
        }
        const allTags = this.images.map((el) => el.tags[0])
        this.tags = [...new Set(allTags)];

      }
      const details = this.translate.instant('TABLES.details');

      this.params.displaywindows = this.params.displaywindows.map((el: any) => {
        el.availability = JSON.parse(el.availability)?.months;
        el.name_w = el.name;
        // el.availability.map((el: any) => el.replace("/" , "-"))
        el.details = details
        el.crud = {"icon-delete": "icon-lf-delete " }
        el.actions = {"newWarning": "+Crea Avviso"}
        return el
      })
      this.isReady = true;
      this.getCompanies();
    })

  }

  getCompanies() {
    this.brands = [];
    this.storeSvc.getCompanies(this.id).subscribe((res) => {
      const mergedData = Object.values((res as any[]).reduce((acc, item) => {
        const key = `${item.company}-${item.brand}`;
        if (!acc[key]) {
          acc[key] = { company: item.company, brand: item.brand, product: [] , display_window: item.display_window, display_window_id: item.display_window_id ,unique_code: item.unique_code , brand_store_id: item.brand_store_id};
        }
        // acc[key].product.push(item.product);
        return acc;
      }, {}));
      this.brands = mergedData;

    })
  }
  actionReceiver(event: any) {
    // console.log(event);

    //console.log(event);
    switch (event.action) {
      case 'detail':
        this.router.navigate([`/${NavigationRoutes.Stores}/${this.ar.snapshot.paramMap.get('id')}/${event.value.name}_${event.value.display_window_id}`] , {
        });
        break;
      case 'icon-edit':

        console.log('todo');

        break;
      case 'icon-delete':
        const deltedialogRef = this.dialog.open(ConfirmComponent, {


          restoreFocus: false}
        );

        deltedialogRef.afterClosed().subscribe((res)=> {
          //console.log(res);
          if (res === "save") {

            this.isReady = false;
            this.storeSvc.windowDelete(event.value.display_window_id).subscribe((res) => {
              this.retrieveData();
              this.getAlerts()
            })

          }

        })

        break;
      case 'newWarning':
        //console.log(event);
        const dw = this.params.displaywindows.map((el:any) => {
          return {
            id: +el.display_window_id,
            name: el.name
          }
        })

        const data = {
          city: this.params.city,
          store: this.params.name,
          store_id: this.params.store_id,
          display_window_id: event.value.display_window_id,
          display_windows: dw

        }
        const dialogRef = this.dialog.open(NewAlertComponent, {
          data:data,
          width: '80%',

          restoreFocus: false}
        );
        dialogRef.afterClosed().subscribe((resp) => {})


        break;

      default:
        break;
    }
    // this.router.navigate([`/${NavigationRoutes.Stores}/${this.ar.snapshot.paramMap.get('id')}/${event}`] , );

  }
  editFields(action: (any | string )) {
    const component: ComponentType<any> = action == StoreEditFields.details ? StoreFieldsComponent : (action == StoreEditFields.images ? StoreImagesComponent : StoreBrandsComponent)
    const brandsWindows = {brands:this.brands, availableWindows: this.params.displaywindows, ids: this.id}
    const data = action == StoreEditFields.details? this.params : (action == StoreEditFields.images ? this.params.images : brandsWindows)
    const dialogRef = this.dialog.open(component, {
      data:data,
      width: '80%',

      restoreFocus: false}
    );
    dialogRef.afterClosed().subscribe((resp) => {
      // console.log(resp);

      if (resp) {
        if (action == StoreEditFields.images) {
          this.params.images = JSON.stringify(resp);
          resp = this.params
          resp.region += "";
          resp.am += "";
        }
        if (action != StoreEditFields.brands) {
          // console.log(data);
          switch (action) {
            case StoreEditFields.details:
              if (data.name === this.params.name) {
                console.log(resp,this.params );
                if (resp.pdf_file !== this.params.pdf_file && resp.pdf_file != "") {

                  this.apiSvc.uploadPdf(resp.pdf_file).subscribe((res: any) => {
                    // console.log(res , data);

                    resp.pdf_file = res.file;
                    // console.log(data);

                     this.updateData(action , resp, data );

                  })
                } else if (resp.pdf_file == "") {
                  resp.pdf_file = 'null';
                  this.updateData(action , resp, data );
                } else {
                  console.log(2);

                  resp.pdf_file = this.params.pdf_file;
                  // console.log(data);
                  console.log(action , resp, data);

                   this.updateData(action , resp, data );
                }
                // this.retrieveData();
              } else {
                this.router.navigate([`/${NavigationRoutes.Stores}/${data.name.replaceAll(" " , "-")}_${data.store_id}`] , { })
              }

              break;
              case StoreEditFields.images:
                this.updateData(action , resp, data );
              break;
            default:
              this.retrieveData();
              break;
          }



        } else {
          this.getCompanies()
        }




      }
    });
  }
  updateData(action: (any | string ) , resp: any, data: any ) {
    this.storeSvc.updateDetails(resp , this.id ).subscribe((res) => {
      // this.router.navigate([`/${NavigationRoutes.Stores}`])
      // console.log(resp);

      switch (action) {
        case StoreEditFields.details:
          if (data.name === this.params.name) {
            this.retrieveData();
          } else {
            this.router.navigate([`/${NavigationRoutes.Stores}/${data.name}_${data.store_id}`] , { })
          }

          break;

        default:
          this.retrieveData();
          break;
      }


    });
  }
  addBrand() {

  }
  newRow(ev: any) {
    ev.name = ev.name_w;
    const  {isNew,display_window_id,name_w, ...data  } = ev;
    data.availability = JSON.stringify({months:ev.availability });

    data.store_id = this.params.store_id;
    this.isReady = false;
    this.storeSvc.insertNewWindow(data).subscribe((res: any) => {
      this.retrieveData();
    })
  }
  changeData(tag: (any | string) = 'all') {
    this.selectedTag = tag;

    if (tag == 'all') {
      this.images = this.params.images
    } else {
      this.images = this.params.images.filter((f: any) => f.tags[0] === tag );
    }
  }

  imageZoom() {
    const dialog = this.dialog.open(ImageCarouselComponent,  {
      data:this.params.images,
      width: '80%',
      restoreFocus: false}
    )
  }
  getAlerts() {
    const req = {
      param: {store: this.name?.replaceAll("-", " ") }
    }
    this.alertSvc.searchAlert(req).subscribe((res: any) => {

      this.alertsData = res.data

    })

  }

}
