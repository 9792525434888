import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as ENV } from '../../environments/environment';
import { BehaviorSubject, Observable, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient) { }

  isLoading: boolean = false;
  header = new HttpHeaders({});
  products: any[] = [];
  private stateSubject = new BehaviorSubject<any>(null); // Imposta il valore iniziale

  // Observable che i componenti possono sottoscrivere
  state$: Observable<any> = this.stateSubject.asObservable();


  get loading() {
    return this.isLoading
  }
  set loading(value: boolean) {
    this.isLoading = value
  }

  getAllCampaigns() {
    return this.http.get(ENV.api.BASE_URL + ENV.api.endpoints.campaigns).pipe(
      tap((res: any) => {
        //console.log(res);

      })
    )
  }
  getMainCampaigns() {
    return this.http.get(ENV.api.BASE_URL + ENV.api.endpoints.mainCampaign).pipe(
      tap((res: any) => {
        //console.log(res);

      })
    )
  }
  getAllStores() {
    return this.http.get(ENV.api.BASE_URL + ENV.api.endpoints.stores).pipe(
      tap((res: any) => {
        //console.log(res);

      })
    )
  }
  uploadFile( files: any) {
    this.header.append('Content-Type', 'multipart/form-data');
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.upload_image, files, { headers: this.header})
  }
  uploadXls( files: any) {
    this.header.append('Content-Type', 'multipart/form-data');
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.upload_xls, files, { headers: this.header})
  }
  uploadPdf( files: any) {
    this.header.append('Content-Type', 'multipart/form-data');
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.upload_pdf, files, { headers: this.header})
  }

  getAllProducts() {
    // const products = JSON.parse(localStorage.getItem('lf-products') as string);
    // if (products && products.length > 0) {
    //   this.products = products;
    // } else {

    this.http.get(ENV.api.BASE_URL + ENV.api.endpoints.products).subscribe((res:any) => {
      //console.log(res);
      const prod = JSON.stringify(res)
      localStorage.setItem('lf-products' , prod )
      this.products = res;

    });
    // }
  }
  // Metodo per aggiornare lo stato
  updateState(newState: any): void {
    this.stateSubject.next(newState);
  }

  getProductsByBrandID(id: string) {
    return this.http.post(ENV.api.BASE_URL + ENV.api.endpoints.companies + ENV.api.endpoints.search, {brand_id: id})
  }


}
