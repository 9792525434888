import { Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalSettings as GS } from '../../../global.settings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../services/api.service';
import { StoresService } from '../../../services/stores/stores.service';

@Component({
  selector: 'app-store-details',
  standalone: true,
  imports: [MaterialModule, SharedModule],
  templateUrl: './store-fields.component.html',
  styleUrl: './store-fields.component.scss'
})
export class StoreFieldsComponent implements OnInit{

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<StoreFieldsComponent>,
    private apiSvc: ApiService,
    private storeSvc: StoresService
  ) {
    this.inputControl();
  }
  formFields!: FormGroup;
  file: any;
  toUpload: any;
  ngOnInit(): void {
    // console.log(this.data);

  }
  inputControl() {
    this.formFields = new FormGroup({
      name: new FormControl(this.data.name, Validators.required),
      store_id: new FormControl({value:this.data.store_id, disabled: true}),
      // store: new FormControl(this.data.name, Validators.required),
      city: new FormControl(this.data.city, Validators.compose([
        Validators.required,
      ])),
      address: new FormControl(this.data.address, Validators.compose([
        Validators.required,
      ])),
      unique_code: new FormControl(this.data.unique_code, Validators.compose([
        Validators.required,
      ])),
      postal_code: new FormControl(this.data.postal_code, Validators.compose([
        Validators.required,
      ])),
      province: new FormControl(this.data.province, Validators.compose([
        Validators.required,
      ])),
      piva: new FormControl(this.data.piva, Validators.compose([
        Validators.required,
      ])),
      pdf_file: new FormControl((this.data.pdf_file || "")),
      status: new FormControl(this.data.status, Validators.compose([
        Validators.required,
      ])),
      ranking: new FormControl(this.data.ranking, Validators.compose([
        Validators.required, Validators.max(99), Validators.min(1)
      ])),
      region: new FormControl(this.data.region, Validators.compose([
        Validators.required
      ])),
      email: new FormControl(this.data.email, Validators.compose([
        Validators.required,
        Validators.pattern(GS.email_pattern)
      ]))
    });

  }

  formSubmit() {
    if (this.formFields.valid) {
      const {
        created_at,
        updated_at, am,displaywindows, ...req } = this.data;
        const data = {...req, ...this.formFields.value}
        data.images = JSON.stringify(data.images);
        this.file ? data.pdf_file = this.file : null
        // req.display_windows = JSON.stringify(req.display_windows);
        this.dialogRef.close(data);
    }
  }
  onFileSelected(event: any): void {

    this.toUpload = event.target.files[0];
    // console.log(this.file);

    //console.log(file);

    const formData = new FormData();
    formData.append('file', this.toUpload);
    this.file = formData;
    // this.filesToUpload.push({file:formData});
    //console.log(formData);
    // this.apiSvc.uploadPdf(formData).subscribe((res) => {
    //   console.log(res);
    //   const req = {

    //   }
    //   // this.storeSvc.updateDetails(req, )

    // })
  }

}
