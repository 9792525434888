
<div class="pe-3 ps-1">

  <button class="btn" [disabled]="!!!config.prevUrl" (click)="changePage('prev')"> < </button>
  @for (l of config.links; track $index;let first = $first, last = $last) {
    @if (!first && !last) {
      <button class="btn" [disabled]="l.active || l.url == null" (click)="changePageLink(l.url)"> {{l.label}} </button>

    }
  }
  <button class="btn"  [disabled]="!!!config.nextUrl" (click)="changePage('next')"> > </button>
  <span class="float-end">{{'GENERAL.page' | translate}} {{config.activePage}} {{'GENERAL.of' | translate}} {{config.totalPage}}</span>

</div>
