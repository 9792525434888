
  <h3>{{'MAIN.ACTIVE_BRANDS'| translate}}</h3>
  <p>{{apiData && apiData.length}} {{'MAIN.BRANDS'| translate}}</p>
  <div class="parent">
    <!-- <div class="brand"> </div> -->

    @for (item of apiData; track $index) {
      <div class="brand">
        <div class="side-over">
          <h3>{{item.name}}</h3>

          <p>{{'TABLES.brands' | translate}}: {{item.tot_brand}}</p>
          <p>

            {{'TABLES.products' | translate}}: {{item.tot_prod}}
          </p>
          <p>
           {{'TABLES.windows_active' | translate}} {{item.tot_display_window}}

          </p>
        </div>

      </div>
      <!-- <div class="brand"(mouseout)="stop()" (mouseover)="makeOver(item)"> </div> -->
    }
  </div>

