import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PaginatorConfig } from '../../models/store.model';
import { SharedModule } from '../../modules/shared.module';

@Component({
  selector: 'basic-paginator',
  standalone: true,
  imports: [SharedModule],
  templateUrl: './basic-paginator.component.html',
  styleUrl: './basic-paginator.component.scss'
})
export class BasicPaginatorComponent {
  @Output() paginator = new EventEmitter<any>();

  @Input() config: PaginatorConfig = {
    prevUrl: "",
    nextUrl: "",
    activePage: 1,
    totalPage: 1,
    totalResults: 1,
    links: [
      {
        active: true,
        label: "",
        url: ""
      }
    ]
  }


  changePage(param: "next" | "prev") {
    this.paginator.emit(param)
  }
  changePageLink(param: string) {
    this.paginator.emit(param)
  }
}
