<div class="content container-fluid ">
  @if (cart) {
    <div class="row lf-card">
      @for(campaign of cart | keyvalue; track campaign.key) {
        @if (campaign.value.length > 0) {
          <div class="card col-auto p-3" (click)="goToItems(campaign.value[0])">
            Campagna: {{campaign.value[0].campaign}}
          </div>

        }
      }

    </div>
  }
</div>
