import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment as ENV } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { StoresTable } from '../../models/tables.model';
import { StoreSearch } from '../../models/store.model';

@Injectable({
  providedIn: 'root'
})
export class StoresService {

  constructor(private http: HttpClient) { }

  storeSearch(req: StoreSearch) {
    return this.http.post(req.url? req.url : ENV.api.BASE_URL + ENV.api.endpoints.stores + ENV.api.endpoints.search + (req.page ? req.page : ""), req.param)
  }
  storeDeepSearch(req: StoreSearch) {
    return this.http.post(req.url? req.url : ENV.api.BASE_URL + ENV.api.endpoints.stores + ENV.api.endpoints.deep_search + (req.page ? req.page : ""), req.param)
  }
  windowSearch(req: StoreSearch) {
    return this.http.post(req.url? req.url : ENV.api.BASE_URL + ENV.api.endpoints.windows + ENV.api.endpoints.search + (req.page ? req.page : ""), req.param)
  }
  getSingleStore(id: string) {
    return this.http.get( ENV.api.BASE_URL + ENV.api.endpoints.stores + "/"+ id)
  }

  displayWindows(req: StoreSearch, id?: string) {
    const headers = new HttpHeaders(req.param);
    return this.http.get(req.url? req.url : ENV.api.BASE_URL + ENV.api.endpoints.stores + "/"+ id,  req.param)
  }

  windowDetail(id: string) {
    return this.http.get( ENV.api.BASE_URL + ENV.api.endpoints.windows + "/" + id)
  }
  windowDelete(id: string) {
    return this.http.delete( ENV.api.BASE_URL + ENV.api.endpoints.windows + "/" + id)
  }

  getCompanies(id: string) {
    return this.http.get( ENV.api.BASE_URL + ENV.api.endpoints.stores + '/'+ id + ENV.api.endpoints.company )
  }

  updateDetails(req: any, id: string) {
    // const headers = new HttpHeaders(req);
    return this.http.put( ENV.api.BASE_URL + ENV.api.endpoints.stores + "/"+ id, req)
  }
  updateWindowDetails(req: any, id: string) {
    // const headers = new HttpHeaders(req);
    return this.http.put( ENV.api.BASE_URL + ENV.api.endpoints.windows + "/"+ id, req)
  }
  newStore(req: any) {
    return this.http.post( ENV.api.BASE_URL + ENV.api.endpoints.stores , req)
  }

  getWindowTable(id: any, param:any = {}) {
    return this.http.post( ENV.api.BASE_URL + ENV.api.endpoints.windows + "/" + id + ENV.api.endpoints.summary  , param)
  }

  insertNewWindow(param: any) {
    return this.http.post( ENV.api.BASE_URL + ENV.api.endpoints.windows, param )

  }

  getCompaniesPerDisplayWindow(id: any) {
    return this.http.get( ENV.api.BASE_URL + ENV.api.endpoints.windows + "/" + id + ENV.api.endpoints.company  )
  }

  getCities() {
    return this.http.get( ENV.api.BASE_URL + ENV.api.endpoints.cities  )
  }


}
