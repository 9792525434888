
<app-common-header></app-common-header>

<div class="h-100 w-100 bg">
  <div class="row pt-5 justify-content-center auth">

    <!-- <h1 class="my-5 text-center">Asset Manager</h1> -->
    <div class="col-12 col-md-auto lf-card p-5 w-50">

      <form [formGroup]="profileForm" (ngSubmit)="login()">
        <h2 class="text-center">{{(hasForgotPsw ? 'AUTH.retrieve_password': 'AUTH.login') | translate }}</h2>
        <div class="d-flex flex-column">
          <mat-form-field class="">
            <!-- <mat-label>User </mat-label> -->
              <input name="username" placeholder="{{'AUTH.user' | translate}}" formControlName="user" title="input" matInput >
            </mat-form-field>
          <!-- <label for="first-name">First Name: </label>
          <input id="first-name" class="form-control" type="text" formControlName="user"> -->

        </div>
        <div class="d-flex flex-column mt-4 position-relative" [ngClass]="{'d-none': hasForgotPsw}">
          <mat-form-field class="">
            <!-- <mat-label>Password </mat-label> -->
            <input name="password" [type]="inputType" placeholder="{{'AUTH.password' | translate}}" formControlName="password" title="input" matInput >
            <!-- @if (profileForm.get('password')?.hasError('required')) {
            <mat-error>{{'STORES.required_field' | translate}}</mat-error>
            } -->
          </mat-form-field>
          <button title="password" clear class="p-0 password-reveal btn" (click)="changeVisibility()" mat-button type="button">
            <mat-icon class="m-0" [fontIcon]="inputType == 'text' ? 'visibility_off': 'visibility'"></mat-icon>
          </button>
          <!-- <label for="last-name">Last Name: </label>
          <input id="last-name" class="form-control" type="password" formControlName="password"> -->

        </div>



        <div class="col-12 text-center">
          @if (hasForgotPsw) {
            <button type="submit" class="mt-4 w-100 btn btn-new" [disabled]="profileForm.get('user')?.hasError('pattern')">{{'AUTH.confirm' | translate}}</button>

          } @else {
            <a class="btn link" (click)="forgetPassword()">{{'BUTTONS.password_lost' | translate}}</a>
            <button type="submit" class="mt-4 w-100 btn btn-new" [disabled]="!profileForm.valid">login</button>

          }
        </div>
      </form>
    </div>
  </div>

</div>

@if (api.loading) {
  <app-loader></app-loader>
}
