import { Component, OnInit, ViewChild } from '@angular/core';
import { PageLayoutComponent } from '../../components/page-layout/page-layout.component';
import { BasicTableComponent } from '../../components/basic-table/basic-table.component';
import { ApiService } from '../../services/api.service';
import { CampaignsTableColumns } from '../../models/tables.model';
import { SharedModule } from '../../modules/shared.module';
import { MatDialog } from '@angular/material/dialog';
import { MaterialModule } from '../../modules/material.module';
import { MatMenuTrigger } from '@angular/material/menu';
import { NewCampaignComponent } from '../../components/dialogs/new-campaign/new-campaign.component';
import { CampaignsService } from '../../services/campaigns/campaigns.service';
import { UtilsService } from '../../services/utils/utils.service';
import { NavigationRoutes } from '../../models/navigation.model';
import { Router } from '@angular/router';
import { PaginatorConfig, StoreSearch } from '../../models/store.model';
import { BasicPaginatorComponent } from '../../components/basic-paginator/basic-paginator.component';

@Component({
  selector: 'app-campagne',
  standalone: true,
  imports: [PageLayoutComponent, BasicTableComponent,SharedModule, MaterialModule, BasicPaginatorComponent],
  templateUrl: './campaigns.component.html',
  styleUrl: './campaigns.component.scss'
})
export class CampaignsComponent implements OnInit {
  @ViewChild('menuTrigger') menuTrigger!: MatMenuTrigger;
  campaignsData = [];
  columns = CampaignsTableColumns;
  isReady = false;
  configData!: PaginatorConfig;

  filters = {
    store: '',
    brand: '',
    product: '',
    city: '',
    company: ''
  }
  constructor(
    public dialog: MatDialog,
    private cmpSvc: CampaignsService,
    private router: Router,
    private utilSvc: UtilsService
  ) {}

  ngOnInit(): void {
      this.retrieveData({});
  }
  actionReceiver(ev: any) {
    console.log(ev , this.campaignsData);
    if (ev.action == "icon-go") {
      this.goToItems(ev.value.campaign.toLowerCase() + "_" + ev.value.campaign_id)
    } else {
      const id = ev.value.campaign_id;
      const name = ev.value.campaign;
      const campaign_to_download = this.campaignsData.filter((el: any) => el.campaign_id == id)
      console.log(campaign_to_download);

      this.utilSvc.downloadFile(campaign_to_download, name);
    }
  }
  filterData(ev: any) {
    //console.log(ev);

    const data: any = {};
    for (const key in ev) {
        const element = ev[key];
        if (element !== "") {
          data[key] = element
        }
    }
    const req = {
      param: data
    }
    this.filters = {...this.filters, ...ev},
    this.isReady = false;
    this.retrieveData(req);
  }
  newCampaign() {
    const dialogRef = this.dialog.open(NewCampaignComponent, {
       data: {
        title: 'New Campaign',
      },
      width:"320px",
      restoreFocus: false});

    dialogRef.afterClosed().subscribe((data) => {
      //console.log(data);

      if (data) {
         this.goToItems(data.name.toLowerCase() + "_" + data.campaign_id )
      }
    });
  }

  retrieveData(param: any) {
    this.isReady = false;
    this.cmpSvc.searchCampaigns(param).subscribe((res: any) => {
      if (res.data.length == 0) return
      this.campaignsData = res.data.map((el: any) => {
        this.configData = {
          prevUrl : res.prev_page_url,
          nextUrl : res.next_page_url,
          activePage: res.current_page,
          totalPage: res.last_page,
          totalResults: res.total,
          links: res.links
        }
        el.crud = {"icon-excel": "icon-lf-excel", "icon-go" : "icon-lf-chevron-right" }
        if (el.used_at !== "") {
          const used = JSON.parse(el.used_at);
          el.availability = used?.months ? used.months : [];
        }

        return el
      });
      this.isReady = true;
    })
  }

  goToItems(page: string , extra?: any) {
    page = page.replaceAll(" " , "-")
    this.router.navigate([`/${NavigationRoutes.Campaigns}/${page}`] , {
      state: extra
    });
  }
  pageChanger(ev: any) {
    const req: StoreSearch = {
      url: ev == 'next'?  this.configData.nextUrl : ( ev == 'prev' ? this.configData.prevUrl : ev ),
    }
    this.retrieveData(req)
  }
}
