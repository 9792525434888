<div class="content container-fluid">
    <div class="row mb-4">
      <div class=" col-md-6 pb-3 col-xl-6 col-xxl-3">
          <div class=" lf-card fixed-height flex-column p-0">
            <div class="band-light lf-card-header d-flex pb-1">
              <div class="col-6">
                <h3 class="">{{'TABLES.status' | translate}}: {{params?.status}}</h3>

              </div>
              <div class="col-6">
                <span class="">{{'TABLES.ranking' | translate}}: {{params?.ranking}}</span>
                <button (click)="editFields('details')" class="link float-end">{{'STORES.edit' | translate}}</button>
              </div>

            </div>
            <div class="band-primary d-flex">
              <div class="col-6">
                <h3>{{'TABLES.pharmacy' | translate}}:</h3>
                <h3>{{params?.name}}</h3>

              </div>
              <div class="col">
                <h3>{{'TABLES.store_id' | translate}}:</h3>
                <h3>{{params?.store_id}}</h3>
              </div>
              @if (params?.pdf_file) {
                <div class="col-auto">

                  <a target="_blank" href="{{params.pdf_file}}">

                    <i class="icon-lf-pdf icon-lg"></i>
                   </a>
                </div>

              }
            </div>
            <div class="band-off d-flex">
              <div class="col-6">
                <p>{{'TABLES.address' | translate}}: {{params?.address}}</p>
                <p>{{'TABLES.postal_code'| translate}}: {{params?.postal_code}}</p>
              </div>
              <div class="col-6">
                <p>{{'TABLES.city' | translate}}: {{params?.city}}</p>
                <p>{{'TABLES.province'| translate}}: {{params?.province}}</p>
              </div>
            </div>
            <div class="band-light d-flex">
              <p class="col-6">{{'TABLES.piva' | translate}}: {{params?.piva}}</p>
              <p class="col-6">{{'TABLES.email' | translate}}: {{params?.email}}</p>
            </div>
          </div>
      </div>
      <div class=" col-md-6 pb-3  col-xl-6 col-xxl-3">
        <div class="lf-card d-flex flex-wrap fixed-height p-0">
          <div class="band-light col-12 d-flex justify-content-between  lf-card-header pb-1 band-light w-100">
            <h3>{{'STORES.licensed_brands' | translate}}</h3>
            @if (brands?.length > 0) {
              <button (click)="editFields('brands')" class="link float-end">{{'STORES.edit' | translate}}</button>
            }
          </div>
          @for (item of brands; track $index) {
            <div class="band-light  col-7 band-light ">
              <p class="company">{{item.company}} </p> <p class="brand">{{item.brand}}</p>
              <!-- @for (product of item.product; track $index) {
                <p class="product">{{product}}</p>
              } -->
            </div>
            <div class="band-light col-5 band-light align-self-center">
              <p>{{item.display_window}}</p>
            </div>
          }
          @if (brands?.length == 0) {
            <div class="mx-auto">
              <button (click)="editFields('brands')" class="btn d-flex flex-column align-items-center">
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="add_circle_outline"></mat-icon>

                {{'STORES.add_brand' | translate}}

              </button>

            </div>
          }
        </div>
      </div>
      <div class="col-md-6  pb-3 ps-0 col-xl-6 col-xxl-4">
        <div class="lf-card images p-0">
          <button title="zoom" class="btn zoom-in" (click)="imageZoom()">
            <mat-icon fontIcon="search"></mat-icon>
          </button>
          <div class="band-light d-flex justify-content-start lf-card-header pb-1 band-light w-100">
            <h3>{{'STORES.images' | translate}}</h3>
            @if (images.length > 0) {
            <button (click)="editFields('images')" class="link">{{'STORES.edit' | translate}}</button>
            <div class="btn-group ms-auto" role="group">
              @if (tags.length > 0) {
                <button class="btn btn-switch" (click)="changeData('all')" [ngClass]="{'active': selectedTag === 'all'}">{{'ACTIONS.see_all' | translate}}</button>

              }
              @for(tag of tags; track $index ) {
                <button class="btn btn-switch" (click)="changeData(tag)" [ngClass]="{'active': selectedTag === tag}">{{tag}}</button>
              }

            </div>
            }
          </div>

          <div [class]="images.length == 0 ? 'd-none' : 'd-flex'" class="band-light d-flex justify-content-between w-100">
            @for (item of images; track $index) {
              <div class="flex-20">
                <img class="w-100" [src]="item.url || placeImg" alt="">
              </div>
            }
          </div>
         @if (images.length == 0)  {
            <div class="mx-auto h-100 d-flex flex-column align-items-center justify-content-center">
              <button (click)="editFields('images')" class="btn d-flex flex-column align-items-center">
                <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="add_circle_outline"></mat-icon>

                {{'STORES.add_image' | translate}}

              </button>

            </div>
          }
        </div>
      </div>
      <div class="col-md-6 col-xl-6 col-xxl-2 pb-3 ps-0" >

        <div class="lf-card fixed-height">




            <!-- @if(alertsData.length !== 0) { -->

                <app-alerts-list
                [apiAlerts]="alertsData">
                <button (click)="newStoreAlert()" mat-mini-fab class="fab m-0 btn-new">+</button>
              </app-alerts-list>


              <!-- } -->

            </div>
          </div>


    <div class="row">
      <div class="col-12 my-3 ps-3">
        <h6>
          {{'TABLES.display_window' | translate}}
        </h6>

      </div>
      <div class="col-12">
        <div class="lf-card">
          @if (isReady) {
            <app-basic-table
              [showPaginator]="true"
              [isMultipleFilter]="true"
              [formField]="filters"
              [tableData]="params?.displaywindows"
              [displayedColumns]="columns"
              (action)="actionReceiver($event)"
              [addRow]="true"
              (newRow)="newRow($event)"
            ></app-basic-table>
          }
        </div>
      </div>
    </div>
</div>




