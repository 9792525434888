import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';
import { NavigationRoutes } from '../models/navigation.model';

export const authGuard: CanActivateFn = (route, state) => {

  const au = inject(AuthService) ;
  const router = inject(Router) ;

  if (!au.getAuth) {
    router.navigateByUrl(NavigationRoutes.Login);
    return false
  }

  return au.getAuth ? true : false;
};
