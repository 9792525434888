import { state } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { NavigationPagesName, NavigationRoutes } from '../../models/navigation.model';
import { SharedModule } from '../../modules/shared.module';
import { ApiService } from '../../services/api.service';
import { AuthService } from '../../services/auth.service';
import { MaterialModule } from '../../modules/material.module';
@Component({
  selector: 'app-side-menu',
  standalone: true,
  imports: [RouterLink, CommonModule, SharedModule, MaterialModule],
  templateUrl: './side-menu.component.html',
  styleUrl: './side-menu.component.scss'
})
export class SideMenuComponent implements OnInit{

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authSvc: AuthService ) {
  }
  path = this.router.url//snapshot.url[0].path;
  isMini = false;

  ngOnInit(): void {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.path = event.url;
      }
    })
  }

  menuItems = [
    {  name: NavigationPagesName.HomePage , url : NavigationRoutes.HomePage , icon: "home" },
    {  name: NavigationPagesName.Stores , url :NavigationRoutes.Stores , icon: "pv" },
    {  name: NavigationPagesName.Scheduler , url :NavigationRoutes.Scheduler , icon: "calendario" },
    {  name: NavigationPagesName.Campaigns , url :NavigationRoutes.Campaigns , icon: "campagne" },
    {  name: NavigationPagesName.Alert , url :NavigationRoutes.Alert , icon: "alert" },
  ]
  profileUrl = NavigationRoutes.Profile
  goToItems(page: string , extra?: any) {
    this.router.navigate([page] , {
      state: extra
    });
  }

  logout() {
    this.authSvc.logout().subscribe((res: any) => {
      //console.log(res);
      localStorage.removeItem('token-lf');
      this.authSvc.setToken = ""

      this.goToItems(NavigationRoutes.Login)
    })
  }

  expand() {
    this.isMini = !this.isMini
  }

}
