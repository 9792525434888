import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginRequest, RecoverPasswordReq } from '../../models/api.models';
import { AuthService } from '../../services/auth.service';
import { NavigationRoutes } from '../../models/navigation.model';
import { Router } from '@angular/router';
import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-password-recover',
  standalone: true,
  imports: [MaterialModule, SharedModule],
  templateUrl: './password-recover.component.html',
  styleUrl: './password-recover.component.scss'
})
export class PasswordRecoverComponent implements OnInit {
  inputType: "text" | "password" = "password"
  email: string = "";
  token: string = "";
  constructor(private authSvc: AuthService,
    private router: Router,
    private snack: MatSnackBar,
    private ar: ActivatedRoute) {}
  ngOnInit(): void {
    this.ar.queryParams
    .subscribe((params: any) => {
      console.log(params);
      this.email = params.email// { orderby: "price" }
      this.token = params.token// { orderby: "price" }
    }
  );
  }
  changeVisibility() {
    this.inputType = this.inputType == "password" ? "text" : "password"
  }
  recoverForm = new FormGroup({
    password: new FormControl('' , Validators.required),
    password_confirmation: new FormControl('', Validators.required),
  });

  submit() {
    if (this.recoverForm.value.password === this.recoverForm.value.password_confirmation) {

      const req: RecoverPasswordReq = {
        email: this.email,
        password: this.recoverForm.controls.password.value+"",
        password_confirmation: this.recoverForm.controls.password_confirmation.value+"",
        token: this.token
      }

      this.authSvc.recoverPassword(req).subscribe((res) => {
        console.log(res);

        this.snack.open("Operazione riuscita con successo")
          this.router.navigateByUrl(NavigationRoutes.Login);
      });
    } else if (this.email == "" || this.token == "") {
      this.snack.open("Link non valido")
    } else {
      this.recoverForm.get('password_confirmation')!.setErrors({invalid: true})
    }

  }
}
