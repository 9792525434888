import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { startWith, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ApiService } from '../../../services/api.service';
import { InsertDataTableComponent } from '../../insert-data-table/insert-data-table.component';
import { ProductsService } from '../../../services/products/products.service';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'app-store-brands',
  standalone: true,
  imports: [MaterialModule, SharedModule, InsertDataTableComponent],
  templateUrl: './store-brands.component.html',
  styleUrl: './store-brands.component.scss'
})
export class StoreBrandsComponent {

  isReady = false;
  filteredCompanies: any;
  filteredBrands: any;
  filteredProducts: any;
  // form: FormGroup;
  companies: any[] = [];
  brands: any[] = [];
  products: any[] = [];
  data: any;
  addrowVisible: boolean = false;
  filesToUpload: any[] = [];
  constructor(private fb: FormBuilder,
    private productsSvc: ProductsService,
    @Inject(MAT_DIALOG_DATA) public params: any,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<StoreBrandsComponent>,
    private apiSvc: ApiService) {


  }





  ngOnInit(): void {
    //console.log(this.params);


    this.isReady = true
  }



  addData() {
    this.addrowVisible = true
  }
  matchPW(ev: any) {
    //console.log(ev);
    if (ev === 'discard') {
      this.addrowVisible = false;
    } else {
      this.productsSvc.insertNewAssociation(ev).subscribe(() => {
        this.dialogRef.close('refresh')
      })
    }


  }
  deleteRow(ev: any) {
    const deltedialogRef = this.dialog.open(ConfirmComponent, {


      restoreFocus: false}
    );

    deltedialogRef.afterClosed().subscribe((res: any)=> {
      //console.log(res);
      if (res === "save") {

        this.productsSvc.deleteAssociation(ev.brand_store_id).subscribe((res) => {
          //console.log(res);

          this.dialogRef.close('refresh');
        })


      }

    })



  }
  onFileSelected(event: any): void {

    const file = event.target.files[0];
    //console.log(file);
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', 'R');
    // this.filesToUpload.push({file:formData , param: "concessione"});
    //console.log(formData);
    var reader = new FileReader();

    reader.readAsDataURL(event.target.files[0]); // read file as data url

    this.apiSvc.uploadXls(formData).subscribe((res) => {
      console.log(res);

    })
  }

}
