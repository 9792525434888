import { Component, Inject, OnInit } from '@angular/core';
import { MaterialModule } from '../../../modules/material.module';
import { SharedModule } from '../../../modules/shared.module';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GlobalSettings as GS } from '../../../global.settings';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from '../../../services/api.service';
import { StoresService } from '../../../services/stores/stores.service';

@Component({
  selector: 'app-window-fields',
  standalone: true,
  imports: [MaterialModule, SharedModule],
  templateUrl: './window-fields.component.html',
  styleUrl: './window-fields.component.scss'
})
export class WindowFieldsComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<WindowFieldsComponent>,
    private apiSvc: ApiService,
    private storeSvc: StoresService
  ) {
    this.inputControl();
  }
  formFields!: FormGroup;
  file: any;
  toUpload: any;
  ngOnInit(): void {
    // console.log(this.data);

  }
  inputControl() {
    this.formFields = new FormGroup({
      name: new FormControl({value:this.data.name, disabled: true}, Validators.required),
      unique_code: new FormControl({value:this.data.unique_code, disabled: true}),
      // store: new FormControl(this.data.name, Validators.required),
      type: new FormControl(this.data.type, Validators.compose([
        Validators.required,
      ])),
      dimensions: new FormControl(this.data.dimensions, Validators.compose([
        Validators.required,
      ])),
      restrictions: new FormControl(this.data.restrictions, Validators.compose([
        Validators.required,
      ])),
      warning: new FormControl(this.data.warning, Validators.compose([
        Validators.required,
      ])),

    });
  }

  formSubmit() {
    if (this.formFields.valid) {
      const {
        created_at,
        updated_at, displaywindows, store, datatable, ...req } = this.data;
        let data = {...req, ...this.formFields.value}
        data.images = JSON.stringify(data.images);
        this.file ? data.pdf_file = this.file : null
        // req.display_windows = JSON.stringify(req.display_windows);
        this.dialogRef.close(data);
    }
  }
  onFileSelected(event: any): void {

    this.toUpload = event.target.files[0];
    // console.log(this.file);

    //console.log(file);

    const formData = new FormData();
    formData.append('file', this.toUpload);
    this.file = formData;
    // this.filesToUpload.push({file:formData});
    //console.log(formData);
    // this.apiSvc.uploadPdf(formData).subscribe((res) => {
    //   console.log(res);
    //   const req = {

    //   }
    //   // this.storeSvc.updateDetails(req, )

    // })
  }
}
