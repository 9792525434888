<header class="dialog-header d-flex justify-content-between">
  {{'STORES.images' | translate}}
  <button class="btn border-0 text-white" mat-dialog-close>
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>

  </button>

</header>
<mat-dialog-content class="new-campaign">
  <app-carousel [imageData]="data"></app-carousel>
  <!-- <div class="carousel-container">
    <div class="carousel">
      <div class="carousel-item" *ngFor="let image of data; let i = index" [class.active]="i === currentIndex">
        <img [src]="image.url" alt="Image {{i + 1}}">
      </div>
    </div>
    <div class="thumbnails">
      <img *ngFor="let image of data; let i = index" [src]="image.url" alt="Thumbnail {{i + 1}}"
           [class.active]="i === currentIndex" (click)="selectImage(i)">
    </div>
  </div> -->
  <!-- @for (item of data; track $index) {
    <img  [src]="item.url" alt="">
  } -->
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-center px-4 pb-4">


  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
</mat-dialog-actions>
