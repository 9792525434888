import { Component, OnInit } from '@angular/core';
import { SharedModule } from '../../modules/shared.module';
import { MaterialModule } from '../../modules/material.module';
import { AuthService } from '../../services/auth.service';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RegisterFields } from '../../models/api.models';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [SharedModule,MaterialModule],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {
  constructor(private auth: AuthService, private snack: MatSnackBar, private router: Router, public api: ApiService) {
  }
  isReady = false
  profileForm! : FormGroup;
  newUserForm! : FormGroup;
  ngOnInit(): void {
    this.newUserForm = new FormGroup({
      user: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      password: new FormControl("" , Validators.required),
      roles: new FormControl( 2 , Validators.required),
    });
    this.auth.getProfile().subscribe((res: any) => {
      this.profileForm = new FormGroup({
        user: new FormControl({value:res.data.name, disabled: true} , Validators.required),
        email: new FormControl({value:res.data.email, disabled: true} , Validators.required),
      });
      this.isReady = true
    })
  }

  createNewUser() {
    const req: RegisterFields = {
      name: this.newUserForm.value.user,
      email: this.newUserForm.value.email,
      password: this.newUserForm.value.password,
      roles: [this.newUserForm.value.roles]
    }
    this.auth.register(req).subscribe((res) => {
      console.log(res);

      // this.snack.open( res , "ok", {
      //   duration: 3000
      // })
    });


  }
}
