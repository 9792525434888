<div class="content container-fluid profile">

  <div class="lf-card p-4">
    <div class="row">
      <div class="col-12 col-lg-6">
        <h4>{{'PAGES.profile' | translate}}</h4>
        @if (isReady) {
          <form [formGroup]="profileForm" >
            <div class="d-flex flex-column">
              <mat-form-field class="">
                <!-- <mat-label>User </mat-label> -->
                  <input placeholder="{{'AUTH.user' | translate}}" formControlName="user" title="input" matInput >
                  <!-- @if (profileForm.get('user')?.hasError('required')) {
                    <mat-error>{{'STORES.required_field' | translate}}</mat-error>
                  } -->
                </mat-form-field>
              <!-- <label for="first-name">First Name: </label>
              <input id="first-name" class="form-control" type="text" formControlName="user"> -->

            </div>
            <div class="d-flex flex-column mt-4">
              <mat-form-field class="">
                <!-- <mat-label>Password </mat-label> -->
                <input type="email" placeholder="{{'AUTH.email' | translate}}" formControlName="email" title="input" matInput >
                <!-- @if (profileForm.get('password')?.hasError('required')) {
                <mat-error>{{'STORES.required_field' | translate}}</mat-error>
              } -->
              </mat-form-field>
              <!-- <label for="last-name">Last Name: </label>
              <input id="last-name" class="form-control" type="password" formControlName="password"> -->

            </div>


            <!-- <div class="col-12 text-center">
              <button type="submit" class="mt-4 w-100 btn btn-new" [disabled]="!profileForm.valid">login</button>
            </div> -->
          </form>

        }

      </div>
      <div class="col-12 mt-5 mt-lg-0 col-lg-6">
        <h4>{{'AUTH.new_user' | translate}}</h4>

        <form [formGroup]="newUserForm" (ngSubmit)="createNewUser()">
          <div class="d-flex flex-column">
            <mat-form-field class="">
              <!-- <mat-label>User </mat-label> -->
                <input placeholder="{{'AUTH.user' | translate}}" formControlName="user" title="input" matInput >
                <!-- @if (profileForm.get('user')?.hasError('required')) {
                  <mat-error>{{'STORES.required_field' | translate}}</mat-error>
                } -->
              </mat-form-field>
            <!-- <label for="first-name">First Name: </label>
            <input id="first-name" class="form-control" type="text" formControlName="user"> -->

          </div>
          <div class="d-flex flex-column mt-4">
            <mat-form-field class="">
              <!-- <mat-label>User </mat-label> -->
                <input placeholder="{{'AUTH.email' | translate}}" formControlName="email" title="input" matInput >
                <!-- @if (profileForm.get('user')?.hasError('required')) {
                  <mat-error>{{'STORES.required_field' | translate}}</mat-error>
                } -->
              </mat-form-field>
            <!-- <label for="first-name">First Name: </label>
            <input id="first-name" class="form-control" type="text" formControlName="user"> -->

          </div>
          <div class="d-flex flex-column mt-4">
            <mat-form-field class="">
              <!-- <mat-label>Password </mat-label> -->
              <input type="password" formControlName="password" placeholder="{{'AUTH.password' | translate}}" roles title="input" matInput >
              <!-- @if (profileForm.get('password')?.hasError('required')) {
              <mat-error>{{'STORES.required_field' | translate}}</mat-error>
            } -->
            </mat-form-field>
            <!-- <label for="last-name">Last Name: </label>
            <input id="last-name" class="form-control" type="password" formControlName="password"> -->

          </div>
          <div class="d-flex flex-column mt-4">

              <!-- <mat-label>Password </mat-label> -->
            <mat-form-field>
              <mat-select placeholder="ruolo" name="roles" formControlName="roles"  matInput title="roles" >
                  <mat-option  [value]="1">Admin</mat-option>
                  <mat-option  [value]="2">Editor</mat-option>
              </mat-select>
            </mat-form-field>

            <!-- <label for="last-name">Last Name: </label>
            <input id="last-name" class="form-control" type="password" formControlName="password"> -->

          </div>


          <div class="col-12">
            <button type="submit" class="mt-4 btn btn-new" [disabled]="!newUserForm.valid">{{'BUTTONS.create' | translate}}</button>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>
