<header class="dialog-header d-flex justify-content-between">
  <!-- {{data.name ? 'STORES.edit_details' :'STORES.new_details' | translate}} -->
   {{'GENERAL.images' | translate}}
  <button class="btn border-0 text-white" mat-dialog-close>
    <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
  </button>
</header>
<mat-dialog-content>
  @if (isReady) {
    @for (image of localImages; track $index) {
      <div class="row">
        <h3>{{image?.tag}}</h3>
        <div class="col-auto" draggable="true">
          <button (click)="upload.click()" class="btn upload-btn d-flex flex-column align-items-center" type="button">
            <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="add_circle_outline"></mat-icon>
           <span>{{'STORES.add_image' | translate}}</span>

          </button>
          <input title="input"  #upload class="d-none" type="file" accept=".jpg,.jpeg,.png"
          (change)="onFileSelected($event, image.tag )"
          >

        </div>
        @for (url of image.urls; track $index) {
          <figure class="m-0 col-auto position-relative">
            <img [src]="url" alt="">
            <button class="btn delete-image p-0" (click)="deleteImage(image , url)">
              <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="close"></mat-icon>
            </button>

          </figure>
        }
      </div>
    }
      <mat-form-field>
        <mat-label>{{'GENERAL.insert_tag' | translate}}</mat-label>
        <input title="tag" type="text" matInput [(ngModel)]="tag" >
      </mat-form-field>
      <div class="col-auto" draggable="true">
        <button (click)="upload.click()" class="btn upload-btn d-flex flex-column align-items-center" type="button">
          <mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="add_circle_outline"></mat-icon>
         <span>{{'STORES.add_image' | translate}}</span>

        </button>
        <input title="input"  #upload class="d-none" type="file" accept=".jpg,.jpeg,.png"
        (change)="onFileSelected($event, 'new' )"
        >

      </div>
    }


</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">


  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-button class="btn link me-4" mat-dialog-close>{{'ACTIONS.cancel' | translate}}</button>
  <button  class="btn-new px-5 m-0"  (click)="saveImage()">{{'ACTIONS.save' | translate}}</button>
</mat-dialog-actions>
