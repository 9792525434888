import {LiveAnnouncer} from '@angular/cdk/a11y';
import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort, Sort, MatSortModule} from '@angular/material/sort';
import {MatTable, MatTableDataSource, MatTableModule} from '@angular/material/table';
import { BasicTable, TableOptions } from '../../models/tables.model';
import { environment as ENV } from '../../../environments/environment';
import { CommonModule } from '@angular/common';
import { MaterialModule } from '../../modules/material.module';
import { SharedModule } from '../../modules/shared.module';
import { FormBuilder } from '@angular/forms';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, delay, map, Observable, startWith, tap } from 'rxjs';
import { InsertDataTableComponent } from '../insert-data-table/insert-data-table.component';
import { StoresService } from '../../services/stores/stores.service';
import { StoreSearch } from '../../models/store.model';




@Component({
  selector: 'app-basic-table',
  standalone: true,
  imports: [MaterialModule, CommonModule, SharedModule, InsertDataTableComponent],
  templateUrl: './basic-table.component.html',
  styleUrl: './basic-table.component.scss'
})
export class BasicTableComponent implements AfterViewInit, OnInit {
  filteredOptions!: Observable<any[]>;
  filteredCity!: Observable<any[]>;

  @Input() isMultipleFilter: boolean = false;
  @Input() isSorting: boolean = false;
  @Input() addRow: boolean = false;
  @Input() tableData: TableOptions[] = [];
  @Input() displayedColumns: string[] = [];
  @Input() formField = {};
  @Input() cssClasses: string = "border";
  @Input() showPaginator: boolean = false;

  @Output() action = new EventEmitter<any>()
  @Output() filters = new EventEmitter<any>()
  @Output() newRow = new EventEmitter<any>()

  @ViewChild(MatTable) table!: MatTable<any>;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  formControl: any;
  isReady = false;
  dataSource: any[] | any = [];
  outputData: any = {};
  months: any[] = [];
  addrowVisible = true;
  rowClass: string = "";
  currentMonth!: number;
  currentYear!: number;
  isMultiple: boolean = true;
  optionSelected = false;
  citySelected = false;
  stores: any[] = [];
  cities: any[] = [];

  constructor(
    private _liveAnnouncer: LiveAnnouncer,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private storeSvc: StoresService,
  ) {
  }

  ngOnInit(): void {
    this.init();
    this.formControl.get('store')?.valueChanges.pipe(
      debounceTime(500)
    )
    .subscribe((value: any) => {
      if (this.optionSelected) {
        const selectedStore = this.stores.filter(({name}) => name == value);
        //console.log(selectedStore);
        if (Array.isArray(selectedStore)) {
          if (selectedStore.length > 0) {
            // console.log(selectedStore);



          }
        }
        // Resetta il flag e ritorna
        this.optionSelected = false;
        return;
      }
      //console.log(value);
      const param: StoreSearch = {
        param: {
          name: value
        }
      }
      this.storeSvc.storeSearch(param).subscribe((res:any) => {
        //console.log(res);
        this.stores = res.data.map((el:any) => {
          return {
            name: el.name,
            type: 'store'
          }
        })
        //console.log(this.stores);
        this.optionSelected = true;
        this.filteredOptions = this.formControl.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filter(value || '')),
          tap((i: any) => {
            //console.log(i);

          })
        )

      })

      // const filter = {...value} as string;
      // this.filters.emit(filter);
    });
    this.formControl.get('name')?.valueChanges.pipe(
      debounceTime(500)
    )
    .subscribe((value: any) => {
      if (this.optionSelected) {
        const selectedStore = this.stores.filter(({name}) => name == value);
        //console.log(selectedStore);
        if (Array.isArray(selectedStore)) {
          if (selectedStore.length > 0) {
            // console.log(selectedStore);



          }
        }
        // Resetta il flag e ritorna
        this.optionSelected = false;
        return;
      }
      //console.log(value);
      const param: StoreSearch = {
        param: {
          name: value
        }
      }
      this.storeSvc.storeSearch(param).subscribe((res:any) => {
        //console.log(res);
        this.stores = res.data.map((el:any) => {
          return {
            name: el.name,
            type: 'store'
          }
        })
        //console.log(this.stores);
        this.optionSelected = true;
        this.filteredOptions = this.formControl.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filter(value || '')),
          tap((i: any) => {
            //console.log(i);

          })
        )

      })

      // const filter = {...value} as string;
      // this.filters.emit(filter);
    });


      this.storeSvc.getCities().subscribe((res:any) => {
        //console.log(res);
        this.cities = res.map((el: any) => {
          return {
            type : 'city',
            name : el.city,
          }
        })

        //console.log(this.stores);

        this.filteredCity = this.formControl.get('city')?.valueChanges.pipe(
          startWith(''),
          map((value: any) => this._filterCity(value || '')),

        )

      })
    this.currentMonth = new Date().getMonth(); // Ottiene il mese corrente (0-11)
    this.currentYear = new Date().getFullYear(); // Ottiene l'anno corrente

    // Ottieni i mesi tradotti e aggiungi l'anno
    // this.months = this.translate.instant('MAIN.MONTHS');
    this.translate.get('MAIN.MONTHS').subscribe((res: any) => {
      this.months = res;
      if(this.months.length == 12 )this.months.unshift("");


      this.months = this.months.map((el: string, index: number) => {
        const monthShort = el.substring(0, 3); // Prendi i primi 3 caratteri del mese
        const year = index < this.currentMonth +1 ? this.currentYear + 1 : this.currentYear; // Se il mese è minore del mese corrente, usa l'anno prossimo
        return {
          label: `${monthShort}-${year}`,
          value: (index < 10? "0" + index : (index )) + "-" + year
        }
      });

      if(this.months.length > 12 )this.months.shift();

      this.months.sort((a, b) => {
        // Parso le date in formato YYYY-MM per confronto
        const dateA = a.value.split('-').reverse().join('-');
        const dateB = b.value.split('-').reverse().join('-');
        return dateA.localeCompare(dateB);
      });
      // this.months = this.translate.instant('MAIN.MONTHS');
      // this.months = this.months.map((el: any) => el.substring(0, 3));
      // this.months.shift();
      this.displayedColumns.forEach((el) => {
        this.outputData[el] = "";
        // if (el === "availability") {
        //   this.outputData[el] = [];
        // }

      })
      this.outputData.isNew = true;
      const {crud, actions, details, ...data} = this.outputData;
      this.outputData = data;
      this.isReady = true;
    });

  }

  private _filter(value: string): string[] {
    value += ""
    const filterValue = value.toLowerCase();

    return this.stores.filter(option => option.name.toLowerCase().includes(filterValue));
  }
  private _filterCity(value: string): string[] {
    value += ""
    const filterValue = value.toLowerCase();


    return this.cities.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  addOption(option: any, type : string) {
  //


    // this.formControl.get(type).setValue("");
  }
  addData() {

    this.addrowVisible = false;
    this.rowClass = 'new-row';
    const updatedData = [...this.dataSource.data, this.outputData];
    this.dataSource.data = updatedData;

  // Renderizza di nuovo la tabella
  // this.table.renderRows();

    this.table.renderRows();
  }
  removeData() {
    this.rowClass = '';
    const updatedData = this.dataSource.data.slice(0, -1);
    this.dataSource.data = updatedData;
    this.table.renderRows();
    this.addrowVisible = true;
  }
  save() {
    this.isMultiple = false;
    this.newRow.emit(this.outputData);
    this.removeData();
  }

  checkRequiredField() {
    let emptyFields = [];

    for (const key in this.outputData) {
      const element = this.outputData[key];
      emptyFields.push(element);
    }
    return emptyFields.every(el =>  el !== "");
  }


  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    // this.dataSource.paginator = this.paginator;
  }
  sendFilters() {
    this.filters.emit(this.formControl.value);
  }

  init() {

    this.dataSource = new MatTableDataSource(this.tableData);
    // this.dataSource.sort = this.sort;
    this.dataSource.filterPredicate = ((data: any, filter) => {
      let values = [];

      for (const key in this.formField) {
        if (Object.prototype.hasOwnProperty.call(this.formField, key)) {
          const a = !filter[key] || data[key]?.toLowerCase().includes(filter[key].toLowerCase());
          values.push(a);
        }
      }

      return values.every(el => el);
    }) as (data: TableOptions, filter: any) => boolean;
    this.formControl = this.formBuilder.group(this.formField)

    // this.formControl.valueChanges.pipe(
    //   debounceTime(500)
    // )
    // .subscribe((value: any) => {
    //   const filter = {...value} as string;
    //   this.filters.emit(filter);
    // });
  }

  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {

    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }
  emitAction(value: any, action: any) {
    const data = {
      action: action,
      value: value
    };
    this.action.emit(data);
  }

  transformDate(dateStr: string): string {
    if (!dateStr)  return ""

    const [month, year] = dateStr.split('-');

    let months = this.translate.instant('MAIN.MONTHS');
    months = months.map((el: any) => el.substring(0, 3));

    const monthIndex = parseInt(month, 10);
    const monthName = months[monthIndex];
    const yearShort = year.slice(-2);

    return `${monthName} ${yearShort}`;
  }

  formatDateString(dateString: string): string {

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1; // I mesi sono indicizzati a partire da 0
    const year = date.getFullYear();

    const dayString = day < 10 ? `0${day}` : `${day}`;
    const monthString = month < 10 ? `0${month}` : `${month}`;

    return `${dayString}/${monthString}/${year}`;
  }
  formatUsedWindows(elem: string) {
    elem = elem.replace("(" , " / ");
    return elem.replace(")" , "");
  }

  formatStatus(el:string) {
    return el.toLowerCase().replaceAll(" " , "-")

  }
}
