

<app-side-menu></app-side-menu>
<main>
  <app-common-header class="sticky"></app-common-header>
  <div class="page-layout-body">
    @if(urls[0].text != "") {
      <nav class="d-flex align-items-center" aria-label="breadcrumb ">
        <ul class="breadcrumb">
          @for (item of urls; track $index; let last = $last) {
            @if (urls.length > 1 && !last) {
              <li class="breadcrumb-item " [ngClass]="{active : last }">
                <a [routerLink]="item.link.replaceAll(' ' , '-')">{{item.text}}</a>
              </li>
            } @else {
              <li class="breadcrumb-item " [ngClass]="{active : last }" [ariaCurrent]="last ? 'page' : null">
                {{item.text}}
              </li>
            }
          }
        </ul>
    </nav>
    }
    <app-loader></app-loader>
    <router-outlet/>
  </div>
</main>
